/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
* 不動産の種類を表します。
- house: 戸建
- land: 土地
- condo: 区分マンション
- area: エリアレポート
- speed_mode_house: 戸建（スピードモード）
- speed_mode_land: 土地（スピードモード）
- speed_mode_condo: 区分マンション（スピードモード）
- multi_unit_building: 一棟
*/
export enum ReportCategory {
  House = 'house',
  Land = 'land',
  Condo = 'condo',
  Area = 'area',
  SpeedModeHouse = 'speed_mode_house',
  SpeedModeLand = 'speed_mode_land',
  SpeedModeCondo = 'speed_mode_condo',
  MultiUnitBuilding = 'multi_unit_building',
}

export enum LandShape {
  ShapedLand = 'shaped_land',
  UnshapedLand = 'unshaped_land',
  Manual = 'manual',
  Unknown = 'unknown',
  AlleyShapeLand = 'alley_shape_land',
}

export enum Ownership {
  Freehold = 'freehold',
  MostlyFreehold = 'mostly_freehold',
  Leasehold = 'leasehold',
  LimitedLeasehold = 'limited_leasehold',
  OldLeasehold = 'old_leasehold',
  OldSuperficies = 'old_superficies',
  NormalLeasehold = 'normal_leasehold',
  NormalSuperficies = 'normal_superficies',
  LimitedNormalLeasehold = 'limited_normal_leasehold',
  LimitedSuperficies = 'limited_superficies',
  Unknown = 'unknown',
}

export enum BuildingConstructionType {
  Wood = 'wood',
  WoodS = 'wood_s',
  WoodRc = 'wood_rc',
  WoodSrc = 'wood_src',
  Lgs = 'lgs',
  Wgs = 'wgs',
  Rc = 'rc',
  Src = 'src',
  Cb = 'cb',
  Other = 'other',
  Unknown = 'unknown',
}

/**
* 建物の種別を示します。利用可能な値は以下の通りです。
- unknown: 不明
- apartment: アパート
- condominium: マンション
- office_building: 事務所ビル
*/
export enum BuildingType {
  Unknown = 'unknown',
  Apartment = 'apartment',
  Condominium = 'condominium',
  OfficeBuilding = 'office_building',
}

export enum CondoConstructionType {
  Wood = 'wood',
  Rc = 'rc',
  Src = 'src',
  Lgs = 'lgs',
  Wgs = 'wgs',
  Cb = 'cb',
  Other = 'other',
  Unknown = 'unknown',
}

export enum LandCorner {
  Ippou = 'ippou',
  Kado = 'kado',
  Nihou = 'nihou',
  Sanhou = 'sanhou',
  Yonhou = 'yonhou',
  Nashi = 'nashi',
  Unknown = 'unknown',
}

export enum AreaClassification {
  UrbanizationArea = 'urbanization_area',
  UrbanizationControlArea = 'urbanization_control_area',
  SuburbsArea = 'suburbs_area',
  SemiCityPlanningArea = 'semi_city_planning_area',
  OutsideArea = 'outside_area',
  Unknown = 'unknown',
}

export enum FirePreventionLaw {
  FirePreventiveDistrict = 'fire_preventive_district',
  SemiFirePreventiveDistrict = 'semi_fire_preventive_district',
  Hou22JoDistrict = 'hou22jo_district',
  Unspecified = 'unspecified',
  Unknown = 'unknown',
}

export enum Direction {
  South = 'south',
  East = 'east',
  West = 'west',
  North = 'north',
  Southeast = 'southeast',
  Southwest = 'southwest',
  Northeast = 'northeast',
  Northwest = 'northwest',
  Unknown = 'unknown',
}

export enum RoadType {
  PublicRoad = 'public_road',
  PrivateRoad = 'private_road',
  Unknown = 'unknown',
}

export enum CurrentState {
  Living = 'living',
  Vacant = 'vacant',
  Rent = 'rent',
  UnderConstruction = 'under_construction',
  Unknown = 'unknown',
}

export enum CondoManagementForm {
  AllConsignment = 'all_consignment',
  PartialConsignment = 'partial_consignment',
  SelfManagement = 'self_management',
  Unknown = 'unknown',
}

export enum CondoManagementState {
  Nikkin = 'nikkin',
  Jochu = 'jochu',
  Junkai = 'junkai',
  Sumikomi = 'sumikomi',
  Unknown = 'unknown',
}

/**
 * 取引の種別
 */
export enum StoryTransactionType {
  Unknown = 'unknown',
  Traded = 'traded',
  Contracted = 'contracted',
}

/**
 * 事例の発生源
 */
export enum StorySourceType {
  Unknown = 'unknown',
  Db = 'db',
  Posted = 'posted',
}

/**
* 事例の用途。利用可能な値は以下の通りです。
- unknown: 不明
- cost_approach_assessment: 原価法
- market_approach_assessment: 取引事例比較法
- sale_price_approach_assessment: 分譲価格比の査定
- competitor_analysis: 競合分析
- speed_mode_assessment: スピードモード査定
- income_approach_assessment_for_rent: 収益還元法(賃料算出)
- income_approach_assessment_for_investment: 収益還元法(利回算出)
*/
export enum StoryUse {
  Unknown = 'unknown',
  CostApproachAssessment = 'cost_approach_assessment',
  MarketApproachAssessment = 'market_approach_assessment',
  SalePriceApproachAssessment = 'sale_price_approach_assessment',
  CompetitorAnalysis = 'competitor_analysis',
  SpeedModeAssessment = 'speed_mode_assessment',
  IncomeApproachAssessmentForRent = 'income_approach_assessment_for_rent',
  IncomeApproachAssessmentForInvestment = 'income_approach_assessment_for_investment',
}

/**
* '査定方法の種別。利用可能な値は以下の通りです。
  - cost: 原価法
  - market: 取引事例比較法
  - sale_price: 分譲価格比の査定
  - speed_mode: スピードモード査定
  - income: 収益還元法
*/
export enum AssessmentCategory {
  Cost = 'cost',
  Market = 'market',
  SalePrice = 'sale_price',
  SpeedMode = 'speed_mode',
  Income = 'income',
}

/**
* 査定価格の種別。利用可能な値は以下の通りです。
- unknown: 不明
- cost: 原価法
- market: 取引事例比較法
- sale_price: 分譲価格比の査定
- speed_mode: スピードモード査定
- income: 収益還元法
- manual: 任意の査定価格
*/
export enum AssessmentPriceCategory {
  Unknown = 'unknown',
  Cost = 'cost',
  Market = 'market',
  SalePrice = 'sale_price',
  SpeedMode = 'speed_mode',
  Income = 'income',
  Manual = 'manual',
}

export enum RecordType {
  PostedLandPrice = 'posted_land_price',
  PrefecturalLandPrice = 'prefectural_land_price',
}

export enum SpotCategory {
  ShoppingFacilities = 'shopping_facilities',
  PublicFacilities = 'public_facilities',
  MedicalFacilities = 'medical_facilities',
  EducationalFacilities = 'educational_facilities',
  WelfareFacilities = 'welfare_facilities',
  Park = 'park',
  TouristFacilities = 'tourist_facilities',
  RecreationalFacilities = 'recreational_facilities',
  AbhorrentFacilities = 'abhorrent_facilities',
  Shelter = 'shelter',
}

export enum PrintSettingColor {
  Blue = 'blue',
  Navy = 'navy',
  Red = 'red',
  Green = 'green',
  Orange = 'orange',
  Custom = 'custom',
}

/**
* original_design:
  企業が指定したオリジナルの表紙デザインです。
  これはデフォルトで使われている表紙とは全く別物で、ページごと入れ替わります。
  例えば、`東京建物不動産販売株式会社`と`株式会社駅前不動産`が該当します。
custom_design:
  企業が登録した画像をもとに、背景がカスタムされる表紙デザインです。
  これはデフォルトで使われている表紙背景が変わるだけで、ページは同じものです。
  `野村不動産ソリューションズ株式会社`以外の全ての企業が適用されます。
*/
export enum PrintSettingDesignType {
  DesignType1 = 'design_type_1',
  DesignType2 = 'design_type_2',
  DesignType3 = 'design_type_3',
  DesignType4 = 'design_type_4',
  DesignType5 = 'design_type_5',
  DesignType6 = 'design_type_6',
  DesignType7 = 'design_type_7',
  DesignType8 = 'design_type_8',
  DesignType9 = 'design_type_9',
  DesignType10 = 'design_type_10',
  DesignType11 = 'design_type_11',
  DesignType12 = 'design_type_12',
  DesignType13 = 'design_type_13',
  DesignType14 = 'design_type_14',
  DesignType15 = 'design_type_15',
  DesignType16 = 'design_type_16',
  DesignType17 = 'design_type_17',
  DesignType18 = 'design_type_18',
  DesignType19 = 'design_type_19',
  DesignType20 = 'design_type_20',
  DesignType21 = 'design_type_21',
  OriginalDesign = 'original_design',
  CustomDesign = 'custom_design',
}

export enum ExcludedPagesKey {
  Cover = 'cover',
  Greeting = 'greeting',
  Summary = 'summary',
  Feature = 'feature',
  Assessment = 'assessment',
  OfferPrice = 'offer_price',
  CompetitorAnalysis = 'competitor_analysis',
  MarketAnalysis = 'market_analysis',
  Expenses = 'expenses',
  Spot = 'spot',
  AreaDisasterPreventionInformation = 'area_disaster_prevention_information',
  StatisticsInformation = 'statistics_information',
  Fundamentals = 'fundamentals',
  AboutUs = 'about_us',
  SalesStrategy = 'sales_strategy',
  SupportService = 'support_service',
  SalesFlow = 'sales_flow',
  SpeedModeCover = 'speed_mode_cover',
  SpeedModeSummary = 'speed_mode_summary',
  SpeedModeAssessmentSummary = 'speed_mode_assessment_summary',
  SpeedModeAssessmentStories = 'speed_mode_assessment_stories',
  SpeedModeCompetitorAnalysisStories = 'speed_mode_competitor_analysis_stories',
}

export enum ExcludedPageKey {
  CoverPage = 'cover_page',
  GreetingPage = 'greeting_page',
  SummaryPage = 'summary_page',
  SpotCoverPage = 'spot_cover_page',
  AreaDisasterPreventionInformationCoverPage = 'area_disaster_prevention_information_cover_page',
  FeaturePage = 'feature_page',
  StoriesDistributionPage = 'stories_distribution_page',
  ArrangementPage1 = 'arrangement_page1',
  ArrangementPage2 = 'arrangement_page2',
  ConclusionPage = 'conclusion_page',
  HouseCostAndMarketAssessmentPagesAssessmentMethodsPage = 'house_cost_and_market_assessment_pages_assessment_methods_page',
  HouseCostAndMarketAssessmentPagesCostSectionCoverPage = 'house_cost_and_market_assessment_pages_cost_section_cover_page',
  HouseCostAndMarketAssessmentPagesCostApproachAssessmentStoriesPage = 'house_cost_and_market_assessment_pages_cost_approach_assessment_stories_page',
  HouseCostAndMarketAssessmentPagesCostApproachAssessmentScoresPage = 'house_cost_and_market_assessment_pages_cost_approach_assessment_scores_page',
  HouseCostAndMarketAssessmentPagesLandPricePage = 'house_cost_and_market_assessment_pages_land_price_page',
  HouseCostAndMarketAssessmentPagesBuildingPricePage = 'house_cost_and_market_assessment_pages_building_price_page',
  HouseCostAndMarketAssessmentPagesCostApproachAssessmentSummaryPage = 'house_cost_and_market_assessment_pages_cost_approach_assessment_summary_page',
  HouseCostAndMarketAssessmentPagesMarketSectionCoverPage = 'house_cost_and_market_assessment_pages_market_section_cover_page',
  HouseCostAndMarketAssessmentPagesMarketApproachAssessmentStoriesPage = 'house_cost_and_market_assessment_pages_market_approach_assessment_stories_page',
  HouseCostAndMarketAssessmentPagesMarketApproachAssessmentScoresPage = 'house_cost_and_market_assessment_pages_market_approach_assessment_scores_page',
  HouseCostAndMarketAssessmentPagesHouseSummaryPage = 'house_cost_and_market_assessment_pages_house_summary_page',
  HouseCostAndMarketAssessmentPagesAssessmentSummaryPage = 'house_cost_and_market_assessment_pages_assessment_summary_page',
  HouseCostAssessmentPagesCostApproachAssessmentCoverPage = 'house_cost_assessment_pages_cost_approach_assessment_cover_page',
  HouseCostAssessmentPagesCostApproachAssessmentStoriesPage = 'house_cost_assessment_pages_cost_approach_assessment_stories_page',
  HouseCostAssessmentPagesCostApproachAssessmentScoresPage = 'house_cost_assessment_pages_cost_approach_assessment_scores_page',
  HouseCostAssessmentPagesLandPricePage = 'house_cost_assessment_pages_land_price_page',
  HouseCostAssessmentPagesBuildingPricePage = 'house_cost_assessment_pages_building_price_page',
  HouseCostAssessmentPagesCostApproachAssessmentSummaryPage = 'house_cost_assessment_pages_cost_approach_assessment_summary_page',
  HouseMarketAssessmentPagesMarketApproachAssessmentCoverPage = 'house_market_assessment_pages_market_approach_assessment_cover_page',
  HouseMarketAssessmentPagesMarketApproachAssessmentStoriesPage = 'house_market_assessment_pages_market_approach_assessment_stories_page',
  HouseMarketAssessmentPagesScoresPage = 'house_market_assessment_pages_scores_page',
  HouseMarketAssessmentPagesHouseSummaryPage = 'house_market_assessment_pages_house_summary_page',
  LandAssessmentPagesMarketApproachAssessmentCoverPage = 'land_assessment_pages_market_approach_assessment_cover_page',
  LandAssessmentPagesMarketApproachAssessmentStoriesPage = 'land_assessment_pages_market_approach_assessment_stories_page',
  LandAssessmentPagesScoresPage = 'land_assessment_pages_scores_page',
  LandAssessmentPagesLandSummaryPage = 'land_assessment_pages_land_summary_page',
  CondoAssessmentPagesMarketApproachAssessmentCoverPage = 'condo_assessment_pages_market_approach_assessment_cover_page',
  CondoAssessmentPagesMarketApproachAssessmentStoriesPage = 'condo_assessment_pages_market_approach_assessment_stories_page',
  CondoAssessmentPagesScoresPage = 'condo_assessment_pages_scores_page',
  CondoAssessmentPagesCondoSummaryPage = 'condo_assessment_pages_condo_summary_page',
  CondoAssessmentPagesIncomeApproachAssessmentCoverPage = 'condo_assessment_pages_income_approach_assessment_cover_page',
  CondoAssessmentPagesIncomeApproachAssessmentRentStoriesPage = 'condo_assessment_pages_income_approach_assessment_rent_stories_page',
  CondoAssessmentPagesIncomeApproachAssessmentRentScoresPage = 'condo_assessment_pages_income_approach_assessment_rent_scores_page',
  CondoAssessmentPagesIncomeApproachAssessmentRentSummaryPage = 'condo_assessment_pages_income_approach_assessment_rent_summary_page',
  CondoAssessmentPagesIncomeApproachAssessmentInvestmentStoriesPage = 'condo_assessment_pages_income_approach_assessment_investment_stories_page',
  CondoAssessmentPagesIncomeApproachAssessmentInvestmentScoresPage = 'condo_assessment_pages_income_approach_assessment_investment_scores_page',
  CondoAssessmentPagesIncomeApproachAssessmentInvestmentSummaryPage = 'condo_assessment_pages_income_approach_assessment_investment_summary_page',
  CondoAssessmentPagesIncomeApproachAssessmentCondoSummaryPage = 'condo_assessment_pages_income_approach_assessment_condo_summary_page',
  OfferPriceCoverPage = 'offer_price_cover_page',
  OfferPriceStoriesPage = 'offer_price_stories_page',
  OfferPriceScoresPage = 'offer_price_scores_page',
  OfferPriceSummaryPage = 'offer_price_summary_page',
  CompetitorAnalysisStoryStoriesPage = 'competitor_analysis_story_stories_page',
  CompetitorAnalysisStoryScoresPage = 'competitor_analysis_story_scores_page',
  HouseMarketAnalysisPagesAnalysisCoverPage = 'house_market_analysis_pages_analysis_cover_page',
  HouseMarketAnalysisPagesMarketAnalysisStoriesPage = 'house_market_analysis_pages_market_analysis_stories_page',
  HouseMarketAnalysisPagesMarketAnalysisLandPriceVolatilityPage = 'house_market_analysis_pages_market_analysis_land_price_volatility_page',
  HouseMarketAnalysisPagesLandSellStoriesStatisticPage = 'house_market_analysis_pages_land_sell_stories_statistic_page',
  HouseMarketAnalysisPagesHouseSellStoriesStatisticPage = 'house_market_analysis_pages_house_sell_stories_statistic_page',
  LandMarketAnalysisPagesMarketAnalysisCoverPage = 'land_market_analysis_pages_market_analysis_cover_page',
  LandMarketAnalysisPagesMarketAnalysisStoriesPage = 'land_market_analysis_pages_market_analysis_stories_page',
  LandMarketAnalysisPagesMarketAnalysisLandPriceVolatilityPage = 'land_market_analysis_pages_market_analysis_land_price_volatility_page',
  LandMarketAnalysisPagesLandSellStoriesStatisticPage = 'land_market_analysis_pages_land_sell_stories_statistic_page',
  CondoMarketAnalysisPagesMarketAnalysisCoverPage = 'condo_market_analysis_pages_market_analysis_cover_page',
  CondoMarketAnalysisPagesCondoSellStoriesMapPage = 'condo_market_analysis_pages_condo_sell_stories_map_page',
  CondoMarketAnalysisPagesRentSellStoriesMapPage = 'condo_market_analysis_pages_rent_sell_stories_map_page',
  CondoMarketAnalysisPagesCondoSellStoriesStatisticPage = 'condo_market_analysis_pages_condo_sell_stories_statistic_page',
  CondoMarketAnalysisPagesCondoSellStoriesLogPage = 'condo_market_analysis_pages_condo_sell_stories_log_page',
  CondoMarketAnalysisPagesSalesPeriodPage = 'condo_market_analysis_pages_sales_period_page',
  TransportNodePage = 'transport_node_page',
  RouteMapPage1 = 'route_map_page1',
  RouteMapPage2 = 'route_map_page2',
  PublicFacilitiesPage = 'public_facilities_page',
  ShoppingFacilitiesPage = 'shopping_facilities_page',
  MedicalFacilitiesPage = 'medical_facilities_page',
  EducationalFacilitiesPage = 'educational_facilities_page',
  WelfareFacilitiesPage = 'welfare_facilities_page',
  ParksPage = 'parks_page',
  TouristFacilitiesPage = 'tourist_facilities_page',
  RecreationalFacilitiesPage = 'recreational_facilities_page',
  AbhorrentFacilitiesPage = 'abhorrent_facilities_page',
  SheltersPage = 'shelters_page',
  UseDistrictPage = 'use_district_page',
  ElevationAndSlopeAngleFifthMeshPage = 'elevation_and_slope_angle_fifth_mesh_page',
  SeismicHazardFaultPage = 'seismic_hazard_fault_page',
  AmplificationFactorPage = 'amplification_factor_page',
  ProbabilityOfExceedancePage = 'probability_of_exceedance_page',
  CrowdCityPage = 'crowd_city_page',
  ProbabilityOfLiquefactionPage = 'probability_of_liquefaction_page',
  WaterDepthAreaPage1 = 'water_depth_area_page1',
  WaterDepthAreaPage2 = 'water_depth_area_page2',
  WaterDepthAreaPage3 = 'water_depth_area_page3',
  LowPriceAreaPage = 'low_price_area_page',
  SedimentDisasterHazardAreaPage = 'sediment_disaster_hazard_area_page',
  StatisticsInformationCoverPage = 'statistics_information_cover_page',
  IncomePerPersonMapPage = 'income_per_person_map_page',
  FundamentalsCoverPage = 'fundamentals_cover_page',
  FlowOfSalePage = 'flow_of_sale_page',
  ContractTypePage = 'contract_type_page',
  ExpensesPage = 'expenses_page',
  AboutCompanyPage = 'about_company_page',
  Recommendation1Page = 'recommendation1_page',
  Recommendation2Page = 'recommendation2_page',
  NjsAboutPage = 'njs_about_page',
  NjsSponsorshipPage = 'njs_sponsorship_page',
  NjsFiveStrengthsPage = 'njs_five_strengths_page',
  NjsFlow1Page = 'njs_flow1_page',
  NjsFlow2Page = 'njs_flow2_page',
  NjsSupportPage = 'njs_support_page',
  NjsVirtualStagingServicePage = 'njs_virtual_staging_service_page',
  NjsFreeConsultationPage = 'njs_free_consultation_page',
  NjsPolicyPage = 'njs_policy_page',
  GreetingOurThoughtsPage = 'greeting_our_thoughts_page',
  AboutUsCoverPage = 'about_us_cover_page',
  AboutUsAboutUsPage = 'about_us_about_us_page',
  AboutUsAboutGroupPage = 'about_us_about_group_page',
  AboutUsAboutPartnerSalesOrganizationPage = 'about_us_about_partner_sales_organization_page',
  AboutUsAboutPartnerSalesPage = 'about_us_about_partner_sales_page',
  AboutUsAboutOfficesPage = 'about_us_about_offices_page',
  AboutUsAboutPartnerSalesOfficesPage = 'about_us_about_partner_sales_offices_page',
  AssessmentCoverPage = 'assessment_cover_page',
  CondoSummaryPage = 'condo_summary_page',
  LandSummaryPage = 'land_summary_page',
  HouseSummaryPage = 'house_summary_page',
  ReportPhotosPage = 'report_photos_page',
  AreaSummaryPage = 'area_summary_page',
  AssessmentCondoCombinationMainCoverPage = 'assessment_condo_combination_main_cover_page',
  AssessmentCondoCombinationMarketApproachAssessmentCoverPage = 'assessment_condo_combination_market_approach_assessment_cover_page',
  AssessmentCondoMarketApproachAssessmentMainCoverPage = 'assessment_condo_market_approach_assessment_main_cover_page',
  AssessmentCondoMarketApproachAssessmentStoriesCoverPage = 'assessment_condo_market_approach_assessment_stories_cover_page',
  AssessmentCondoMarketApproachAssessmentStoriesPage = 'assessment_condo_market_approach_assessment_stories_page',
  AssessmentCondoMarketApproachAssessmentStoryPage = 'assessment_condo_market_approach_assessment_story_page',
  AssessmentCondoCompetitorAnalysisStoriesCoverPage = 'assessment_condo_competitor_analysis_stories_cover_page',
  AssessmentCondoCompetitorAnalysisStoryPage = 'assessment_condo_competitor_analysis_story_page',
  AssessmentCondoMarketApproachAssessmentSummaryPage = 'assessment_condo_market_approach_assessment_summary_page',
  AssessmentCondoCombinationSalePriceApproachAssessmentCoverPage = 'assessment_condo_combination_sale_price_approach_assessment_cover_page',
  AssessmentCondoSalePriceApproachAssessmentMainCoverPage = 'assessment_condo_sale_price_approach_assessment_main_cover_page',
  AssessmentCondoSalePriceApproachAssessmentStoriesCoverPage = 'assessment_condo_sale_price_approach_assessment_stories_cover_page',
  AssessmentCondoSalePriceApproachAssessmentStoriesPage = 'assessment_condo_sale_price_approach_assessment_stories_page',
  AssessmentCondoSalePriceApproachAssessmentStoryPage = 'assessment_condo_sale_price_approach_assessment_story_page',
  AssessmentCondoCompetitorAnalysisStoriesPage = 'assessment_condo_competitor_analysis_stories_page',
  AssessmentCondoSalePriceApproachAssessmentSummaryPage = 'assessment_condo_sale_price_approach_assessment_summary_page',
  AssessmentCondoCombinationSummaryPage = 'assessment_condo_combination_summary_page',
  AssessmentCondoIncomeApproachAssessmentMainCoverPage = 'assessment_condo_income_approach_assessment_main_cover_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeManualYieldPercentageTypeEstimatedSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_manual_yield_percentage_type_estimated_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeEstimatedYieldPercentageTypeEstimatedSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_estimated_yield_percentage_type_estimated_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeMeanYieldPercentageTypeEstimatedRentStoriesPage = 'assessment_condo_income_approach_assessment_rent_price_type_mean_yield_percentage_type_estimated_rent_stories_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeMeanYieldPercentageTypeEstimatedRentStoryPage = 'assessment_condo_income_approach_assessment_rent_price_type_mean_yield_percentage_type_estimated_rent_story_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeMeanYieldPercentageTypeEstimatedRentSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_mean_yield_percentage_type_estimated_rent_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeMeanYieldPercentageTypeEstimatedSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_mean_yield_percentage_type_estimated_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeManualYieldPercentageTypeMeanRentSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_manual_yield_percentage_type_mean_rent_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeManualYieldPercentageTypeMeanInvestmentStoriesPage = 'assessment_condo_income_approach_assessment_rent_price_type_manual_yield_percentage_type_mean_investment_stories_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeManualYieldPercentageTypeMeanInvestmentStoryPage = 'assessment_condo_income_approach_assessment_rent_price_type_manual_yield_percentage_type_mean_investment_story_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeManualYieldPercentageTypeMeanInvestmentSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_manual_yield_percentage_type_mean_investment_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeManualYieldPercentageTypeMeanSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_manual_yield_percentage_type_mean_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeEstimatedYieldPercentageTypeMeanRentSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_estimated_yield_percentage_type_mean_rent_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeEstimatedYieldPercentageTypeMeanInvestmentStoriesPage = 'assessment_condo_income_approach_assessment_rent_price_type_estimated_yield_percentage_type_mean_investment_stories_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeEstimatedYieldPercentageTypeMeanInvestmentStoryPage = 'assessment_condo_income_approach_assessment_rent_price_type_estimated_yield_percentage_type_mean_investment_story_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeEstimatedYieldPercentageTypeMeanInvestmentSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_estimated_yield_percentage_type_mean_investment_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeEstimatedYieldPercentageTypeMeanSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_estimated_yield_percentage_type_mean_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeMeanYieldPercentageTypeMeanRentStoriesPage = 'assessment_condo_income_approach_assessment_rent_price_type_mean_yield_percentage_type_mean_rent_stories_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeMeanYieldPercentageTypeMeanRentStoryPage = 'assessment_condo_income_approach_assessment_rent_price_type_mean_yield_percentage_type_mean_rent_story_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeMeanYieldPercentageTypeMeanRentSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_mean_yield_percentage_type_mean_rent_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeMeanYieldPercentageTypeMeanInvestmentStoriesPage = 'assessment_condo_income_approach_assessment_rent_price_type_mean_yield_percentage_type_mean_investment_stories_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeMeanYieldPercentageTypeMeanInvestmentStoryPage = 'assessment_condo_income_approach_assessment_rent_price_type_mean_yield_percentage_type_mean_investment_story_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeMeanYieldPercentageTypeMeanInvestmentSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_mean_yield_percentage_type_mean_investment_summary_page',
  AssessmentCondoIncomeApproachAssessmentRentPriceTypeMeanYieldPercentageTypeMeanSummaryPage = 'assessment_condo_income_approach_assessment_rent_price_type_mean_yield_percentage_type_mean_summary_page',
  AssessmentHouseCombinationMainCoverPage = 'assessment_house_combination_main_cover_page',
  AssessmentHouseCombinationCostApproachAssessmentCoverPage = 'assessment_house_combination_cost_approach_assessment_cover_page',
  AssessmentHouseCostApproachAssessmentMainCoverPage = 'assessment_house_cost_approach_assessment_main_cover_page',
  AssessmentHouseCostApproachAssessmentStoriesPage = 'assessment_house_cost_approach_assessment_stories_page',
  AssessmentHouseCostApproachAssessmentStoryPage = 'assessment_house_cost_approach_assessment_story_page',
  AssessmentHouseCostApproachAssessmentLandPricePage = 'assessment_house_cost_approach_assessment_land_price_page',
  AssessmentHouseCostApproachAssessmentBuildingPricePage = 'assessment_house_cost_approach_assessment_building_price_page',
  AssessmentHouseCostApproachAssessmentSummaryPage = 'assessment_house_cost_approach_assessment_summary_page',
  AssessmentHouseCombinationMarketApproachAssessmentCoverPage = 'assessment_house_combination_market_approach_assessment_cover_page',
  AssessmentHouseMarketApproachAssessmentMainCoverPage = 'assessment_house_market_approach_assessment_main_cover_page',
  AssessmentHouseMarketApproachAssessmentStoriesCoverPage = 'assessment_house_market_approach_assessment_stories_cover_page',
  AssessmentHouseMarketApproachAssessmentStoriesPage = 'assessment_house_market_approach_assessment_stories_page',
  AssessmentHouseMarketApproachAssessmentStoryPage = 'assessment_house_market_approach_assessment_story_page',
  AssessmentHouseMarketApproachAssessmentSummaryPage = 'assessment_house_market_approach_assessment_summary_page',
  AssessmentHouseCombinationSummaryPage = 'assessment_house_combination_summary_page',
  AssessmentHouseCompetitorAnalysisStoriesCoverPage = 'assessment_house_competitor_analysis_stories_cover_page',
  AssessmentHouseCompetitorAnalysisStoriesPage = 'assessment_house_competitor_analysis_stories_page',
  AssessmentHouseCompetitorAnalysisStoryPage = 'assessment_house_competitor_analysis_story_page',
  AssessmentLandMarketApproachAssessmentMainCoverPage = 'assessment_land_market_approach_assessment_main_cover_page',
  AssessmentLandMarketApproachAssessmentStoriesPage = 'assessment_land_market_approach_assessment_stories_page',
  AssessmentLandMarketApproachAssessmentStoryPage = 'assessment_land_market_approach_assessment_story_page',
  AssessmentLandMarketApproachAssessmentSummaryPage = 'assessment_land_market_approach_assessment_summary_page',
  AssessmentLandCompetitorAnalysisStoriesCoverPage = 'assessment_land_competitor_analysis_stories_cover_page',
  AssessmentLandCompetitorAnalysisStoriesPage = 'assessment_land_competitor_analysis_stories_page',
  AssessmentLandCompetitorAnalysisStoryPage = 'assessment_land_competitor_analysis_story_page',
  AssessmentLandMarketApproachAssessmentStoriesCoverPage = 'assessment_land_market_approach_assessment_stories_cover_page',
  OfferCoverPage = 'offer_cover_page',
  OfferOfferDataPage = 'offer_offer_data_page',
  OfferOfferPricePage = 'offer_offer_price_page',
  OfferCommentPage = 'offer_comment_page',
  OfferCondoCompetitorAnalysisStoriesPage = 'offer_condo_competitor_analysis_stories_page',
  OfferCondoCompetitorAnalysisStoryPage = 'offer_condo_competitor_analysis_story_page',
  OfferLandCompetitorAnalysisStoriesPage = 'offer_land_competitor_analysis_stories_page',
  OfferLandCompetitorAnalysisStoryPage = 'offer_land_competitor_analysis_story_page',
  OfferHouseCompetitorAnalysisStoriesPage = 'offer_house_competitor_analysis_stories_page',
  OfferHouseCompetitorAnalysisStoryPage = 'offer_house_competitor_analysis_story_page',
  OfferCondoIncomeCompetitorAnalysisStoriesPage = 'offer_condo_income_competitor_analysis_stories_page',
  OfferCondoIncomeCompetitorAnalysisStoryPage = 'offer_condo_income_competitor_analysis_story_page',
  MarketAnalysisCoverPage = 'market_analysis_cover_page',
  MarketAnalysisCondoHeatMapEstimatePriceMapPage = 'market_analysis_condo_heat_map_estimate_price_map_page',
  MarketAnalysisCondoRentHeatMapEstimatePriceMapPage = 'market_analysis_condo_rent_heat_map_estimate_price_map_page',
  MarketAnalysisCondoBuildingStoriesPage = 'market_analysis_condo_building_stories_page',
  MarketAnalysisCondoSellStoriesStatisticPage = 'market_analysis_condo_sell_stories_statistic_page',
  MarketAnalysisCondoSalesPeriodPage = 'market_analysis_condo_sales_period_page',
  MarketAnalysisMarketAnalysisStoriesPage = 'market_analysis_market_analysis_stories_page',
  MarketAnalysisMarketAnalysisStoriesChartPage = 'market_analysis_market_analysis_stories_chart_page',
  MarketAnalysisHouseSellStoriesStatisticPage = 'market_analysis_house_sell_stories_statistic_page',
  MarketAnalysisLandSellStoriesStatisticPage = 'market_analysis_land_sell_stories_statistic_page',
  MarketAnalysisReinsDataDocumentPage = 'market_analysis_reins_data_document_page',
  SalesStrategyCoverPage = 'sales_strategy_cover_page',
  SalesStrategyContractorAnalysisPage = 'sales_strategy_contractor_analysis_page',
  SalesStrategyInternetActivities1Page = 'sales_strategy_internet_activities1_page',
  SalesStrategyInternetActivities2Page = 'sales_strategy_internet_activities2_page',
  SalesStrategyInternetActivities3Page = 'sales_strategy_internet_activities3_page',
  SalesStrategyInternetActivities4Page = 'sales_strategy_internet_activities4_page',
  SalesStrategyInternetActivities5Page = 'sales_strategy_internet_activities5_page',
  SalesStrategyInternetActivities6Page = 'sales_strategy_internet_activities6_page',
  SalesStrategyInternetActivities7Page = 'sales_strategy_internet_activities7_page',
  SalesStrategyInternetActivities8Page = 'sales_strategy_internet_activities8_page',
  SalesStrategyInternetActivities9Page = 'sales_strategy_internet_activities9_page',
  SalesStrategyInternetActivities10Page = 'sales_strategy_internet_activities10_page',
  SalesStrategyEtcActivities1Page = 'sales_strategy_etc_activities1_page',
  SalesStrategyEtcActivities2Page = 'sales_strategy_etc_activities2_page',
  SalesStrategyEtcActivities3Page = 'sales_strategy_etc_activities3_page',
  SalesStrategyEtcActivities4Page = 'sales_strategy_etc_activities4_page',
  SalesStrategyPartnerSalesStrengths1Page = 'sales_strategy_partner_sales_strengths1_page',
  SalesStrategyPartnerSalesStrengths2Page = 'sales_strategy_partner_sales_strengths2_page',
  SalesStrategyPartnerSalesStrengths3Page = 'sales_strategy_partner_sales_strengths3_page',
  SupportServiceCoverPage = 'support_service_cover_page',
  SupportServiceSummaryPage = 'support_service_summary_page',
  SupportService1Page = 'support_service1_page',
  SupportService2Page = 'support_service2_page',
  SupportService3Page = 'support_service3_page',
  SupportService4Page = 'support_service4_page',
  SupportService5Page = 'support_service5_page',
  SupportService6Page = 'support_service6_page',
  SupportService7Page = 'support_service7_page',
  SupportService8Page = 'support_service8_page',
  SupportService9Page = 'support_service9_page',
  SupportService10Page = 'support_service10_page',
  SupportService11Page = 'support_service11_page',
  SupportService12Page = 'support_service12_page',
  SupportService13Page = 'support_service13_page',
  SupportService14Page = 'support_service14_page',
  SupportService15Page = 'support_service15_page',
  SupportService16Page = 'support_service16_page',
  SalesFlowCoverPage = 'sales_flow_cover_page',
  SalesFlowStepsPage = 'sales_flow_steps_page',
  SalesFlowStrategyDetailForOwnerPage = 'sales_flow_strategy_detail_for_owner_page',
  SalesFlowStrategyDetailForBuyerPage = 'sales_flow_strategy_detail_for_buyer_page',
  SalesFlowSettlementStepsPage = 'sales_flow_settlement_steps_page',
  SalesFlowPartnerSalesStepsPage = 'sales_flow_partner_sales_steps_page',
  SalesFlowPartnerSalesExpensesPage = 'sales_flow_partner_sales_expenses_page',
  SalesFlowPartnerSalesBrokerageAgreementPage = 'sales_flow_partner_sales_brokerage_agreement_page',
  SalesFlowPartnerSalesContractProcessPage = 'sales_flow_partner_sales_contract_process_page',
  SpotSummaryPage = 'spot_summary_page',
  SpotTransportNodesPage = 'spot_transport_nodes_page',
  SpotTrainTransportLinksPage = 'spot_train_transport_links_page',
  SpotBusTransportLinksPage = 'spot_bus_transport_links_page',
  SpotPublicFacilitiesPage = 'spot_public_facilities_page',
  SpotShoppingFacilitiesPage = 'spot_shopping_facilities_page',
  SpotMedicalFacilitiesPage = 'spot_medical_facilities_page',
  SpotEducationalFacilitiesPage = 'spot_educational_facilities_page',
  SpotWelfareFacilitiesPage = 'spot_welfare_facilities_page',
  SpotParksPage = 'spot_parks_page',
  SpotTouristFacilitiesPage = 'spot_tourist_facilities_page',
  SpotRecreationalFacilitiesPage = 'spot_recreational_facilities_page',
  SpotAbhorrentFacilitiesPage = 'spot_abhorrent_facilities_page',
  SpotSheltersPage = 'spot_shelters_page',
  AreaDisasterPreventionInformationUseDistrictPage = 'area_disaster_prevention_information_use_district_page',
  AreaDisasterPreventionInformationElevationAndSlopeAngleFifthMeshPage = 'area_disaster_prevention_information_elevation_and_slope_angle_fifth_mesh_page',
  AreaDisasterPreventionInformationSeismicHazardFaultPage = 'area_disaster_prevention_information_seismic_hazard_fault_page',
  AreaDisasterPreventionInformationAmplificationFactorPage = 'area_disaster_prevention_information_amplification_factor_page',
  AreaDisasterPreventionInformationProbabilityOfExceedancePage = 'area_disaster_prevention_information_probability_of_exceedance_page',
  AreaDisasterPreventionInformationCrowdCityPage = 'area_disaster_prevention_information_crowd_city_page',
  AreaDisasterPreventionInformationProbabilityOfLiquefactionPage = 'area_disaster_prevention_information_probability_of_liquefaction_page',
  AreaDisasterPreventionInformationRiverInundationAreasPage = 'area_disaster_prevention_information_river_inundation_areas_page',
  AreaDisasterPreventionInformationTsunamiInundationAreasPage = 'area_disaster_prevention_information_tsunami_inundation_areas_page',
  AreaDisasterPreventionInformationHighTideInundationAreasPage = 'area_disaster_prevention_information_high_tide_inundation_areas_page',
  AreaDisasterPreventionInformationLowPriceAreaPage = 'area_disaster_prevention_information_low_price_area_page',
  AreaDisasterPreventionInformationSedimentDisasterHazardAreaPage = 'area_disaster_prevention_information_sediment_disaster_hazard_area_page',
  AreaDisasterPreventionInformationUseareaPage = 'area_disaster_prevention_information_usearea_page',
  AreaDisasterPreventionInformationMaxSlopeAnglePage = 'area_disaster_prevention_information_max_slope_angle_page',
  AreaDisasterPreventionInformationFaultPage = 'area_disaster_prevention_information_fault_page',
  AreaDisasterPreventionInformationQuakeIntensityPage = 'area_disaster_prevention_information_quake_intensity_page',
  AreaDisasterPreventionInformationQuakeRatePage = 'area_disaster_prevention_information_quake_rate_page',
  AreaDisasterPreventionInformationDensePage = 'area_disaster_prevention_information_dense_page',
  AreaDisasterPreventionInformationEkijoPage = 'area_disaster_prevention_information_ekijo_page',
  AreaDisasterPreventionInformationKasenPage = 'area_disaster_prevention_information_kasen_page',
  AreaDisasterPreventionInformationTsunamiPage = 'area_disaster_prevention_information_tsunami_page',
  AreaDisasterPreventionInformationTakashioPage = 'area_disaster_prevention_information_takashio_page',
  AreaDisasterPreventionInformationTeiichitaiPage = 'area_disaster_prevention_information_teiichitai_page',
  AreaDisasterPreventionInformationDoshaPage = 'area_disaster_prevention_information_dosha_page',
  AssessmentSummaryPage = 'assessment_summary_page',
  AssessmentStoriesPage = 'assessment_stories_page',
  CompetitorAnalysisStoriesPage = 'competitor_analysis_stories_page',
}

export enum CustomName {
  NomuCom = 'nomu.com',
  Nsl = 'nsl',
  Ttf = 'ttf',
  Njs = 'njs',
  Emf = 'emf',
  None = 'none',
}

export enum TransportNodeType {
  Station = 'station',
  Busstop = 'busstop',
}

export enum WaterDepthLevel {
  Level1 = 'level_1',
  Level2 = 'level_2',
  Level3 = 'level_3',
  Level4 = 'level_4',
  Level5 = 'level_5',
  Level6 = 'level_6',
}

/**
* 液状化の可能性
https://docs.google.com/spreadsheets/d/1wpmD0jHFm4yN49g8degmkQyqdPX_wuk_o676O216p4g/edit#gid=1516355427
*/
export enum JapanSeismicHazardMeshProbabilityOfLiquefactionType {
  Low = 'low',
  LowSlightly = 'low_slightly',
  Middle = 'middle',
  HighSlightly = 'high_slightly',
  High = 'high',
  Unknown = 'unknown',
}

export enum UseDistrictCode {
  Itei = 'itei',
  Nitei = 'nitei',
  Ichu = 'ichu',
  Nichu = 'nichu',
  Ijyu = 'ijyu',
  Nijyu = 'nijyu',
  Denjyu = 'denjyu',
  Jyunjyu = 'jyunjyu',
  Kinsho = 'kinsho',
  Shogyo = 'shogyo',
  Jyunko = 'jyunko',
  Kogyo = 'kogyo',
  Kosen = 'kosen',
  Unspecified = 'unspecified',
  Unknown = 'unknown',
}

/**
* 決済ステータス
active: 課金状態
inactive: 非課金状態(紐づくuserはログイン時に認証ができなくなります。)

auth0のDBとの連動はしていません。
手動運用時(~202301)まではauth0をブロックする運用を行っていました。
しかし202302からはアプリケーションのDB上の属性だけで認証を制御するようにしています。
*/
export enum PaymentStatus {
  Active = 'active',
  Inactive = 'inactive',
}

/**
 * 帳票に添付する画像の種類
 */
export enum PhotoCategory {
  Unknown = 'unknown',
  Appearance = 'appearance',
  Other = 'other',
}

/**
* 取り込み用事例データファイルのステータス
unknown: 不明
wait: 取り込み処理が未実行
active: 取り込み処理を実行中
completed: 取り込み処理が完了
failed: 取り込み処理が失敗
*/
export enum ImportFileStatus {
  Unknown = 'unknown',
  Wait = 'wait',
  Active = 'active',
  Completed = 'completed',
  Failed = 'failed',
}

/**
 * 敬称の接尾語
 */
export enum HonorificSuffix {
  Sama = 'sama',
  Onchu = 'onchu',
  None = 'none',
}

/**
 * 路線価の種別
 */
export enum RoadPriceType {
  InheritanceTax = 'inheritance_tax',
  PropertyTax = 'property_tax',
}

/**
 * 借地権割合
 */
export enum LeaseholdRatio {
  None = 'none',
  A90 = 'a_90',
  B80 = 'b_80',
  C70 = 'c_70',
  D60 = 'd_60',
  E50 = 'e_50',
  F40 = 'f_40',
  G30 = 'g_30',
}

/**
 * 部位種別
 */
export enum DepreciationRateCategory {
  FoundationStructure = 'foundation_structure',
  ExternalFinish = 'external_finish',
  InternalFinish = 'internal_finish',
  Facility = 'facility',
}

/**
* objectのキー名は以下の10ページ目に記載してあります。
https://drive.google.com/drive/u/0/folders/1S-U5oRfhy5pOZXxoKoOkHJ2_sJau1LpP
*/
export enum RiskInformationDataType {
  Ekijo = 'ekijo',
  JishinMaxttl5P = 'jishin_maxttl_5P',
  JishinMaxttl6L = 'jishin_maxttl_6L',
  JishinMaxttl6P = 'jishin_maxttl_6P',
  JishinAmpvs400 = 'jishin_ampvs400',
  TeiichitaiRaster = 'teiichitai_raster',
  DoshaKeikaiKyukeisha = 'dosha_keikai_kyukeisha',
  DoshaKeikaiDosekiryu = 'dosha_keikai_dosekiryu',
  DoshaKeikaiJisuberi = 'dosha_keikai_jisuberi',
  DoshaKikenDosekiryu = 'dosha_kiken_dosekiryu',
  DoshaKikenKyukeisha = 'dosha_kiken_kyukeisha',
  DoshaKikenJisuberi = 'dosha_kiken_jisuberi',
  FloodL26 = 'flood_l2_6',
  FloodL2Keizoku = 'flood_l2_keizoku',
  FloodL1 = 'flood_l1',
  Fault = 'fault',
  Dense = 'dense',
  Usearea = 'usearea',
  MaxSlopeAngle = 'maxSlopeAngle',
  TakashioSinsuisinRaster = 'takashio_sinsuisin_raster',
  TsunamishinsuiRaster = 'tsunamishinsui_raster',
  DoshaKikenNadare = 'dosha_kiken_nadare',
}

/**
* 査定回数をカウントする期間
indefinite: 期間が定められておらず、回数がリセットされない。累計○回プランなどで選択されます。
monthly: 月ごとに回数がリセットされます。通常のプランはこちらが選択されます
*/
export enum ReportsCountPeriodType {
  Indefinite = 'indefinite',
  Monthly = 'monthly',
}

/**
* PDF出力ログのステータス
unknown: 不明
wait: 出力処理が未実行
active: 出力処理を実行中
completed: 出力処理が完了（ダウンロードが可能）
failed: 出力処理が失敗
*/
export enum PdfExportLogStatus {
  Unknown = 'unknown',
  Wait = 'wait',
  Active = 'active',
  Completed = 'completed',
  Failed = 'failed',
}

/**
* ㎡単位を坪単位に変換する際の数字の丸め方について設定することを目的としています。
デフォルトでは四捨五入が適用されます。
round: 四捨五入
truncate: 切り捨て
*/
export enum TsuboConversionType {
  Round = 'round',
  Truncate = 'truncate',
}

export enum JobDispatcherCategory {
  Spot = 'spot',
  AreaDisasterPreventionInformation = 'area_disaster_prevention_information',
}

/**
* 外部サービス名
- howma: HowMa
*/
export enum ExternalServiceName {
  Howma = 'howma',
}

export interface Company {
  id: number

  /**
   * 外部サービス用企業ID
   * 企業を一意に識別するためのコード
   *
   * 2024-08
   * HowMa連携案件をきっかけに追加した項目です。
   * ほかサービス連携にあたり連番のプライマリIDとは別に追加しました。
   * 連番だとレコード数が推測できてしまうため、セキュリティ上の観点から追加しました。
   * @example 550e8400-e29b-41d4-a716-446655440000
   */
  uuid?: string

  /** @example コラビット不動産 */
  name: string

  /** @example 東京都港区 芝浦1丁目13−10 第3東運ビル 8F */
  address: string

  /** @example 03-1234-5678 */
  tel: string

  /** @example 03-1234-5678 */
  fax: string

  /** @example 104-0052 */
  zip: string

  /** カスタム帳票の名称 */
  custom_name: CustomName

  /**
   * (非推奨)部門名 -> Officeモデルで管理することに変更(2023.01)
   * @example 田町本店
   */
  department_name?: string

  /**
   * 宅建業の免許番号
   * @example 東京都知事免許（１）第１０５３１９号
   */
  license_number?: string

  /** @example companies/{company_id}/logo.png */
  icon_image_key: string

  /** @example https://some.where/images/logo.png */
  icon_image_url: string

  /** @example https://some.where/images/greeting.png */
  greeting_image_url: string

  /**
   * 決済情報を管理するモデルです。
   * planに1x1でひも付き、companiesと1*nの関係でひも付きます。
   * ただしcompanyモデルから参照される実装を想定しているのでcompaniesの属性は持たない設計にしています。
   */
  payment: Payment

  /**
   * 帳票の有効期限を設定します。
   * これを超過すると自動的に削除されます。
   * 設定のない場合（null）は有効期限はありません。
   * @example 90
   */
  report_expiration_days?: number
  office_custom_fields: OfficeCustomField[]

  /**
   * companyに属するユーザー数
   * @example 20
   */
  users_count: number

  /**
   * ㎡単位を坪単位に変換する際の数字の丸め方について設定することを目的としています。
   * デフォルトでは四捨五入が適用されます。
   * round: 四捨五入
   * truncate: 切り捨て
   */
  tsubo_conversion_type: TsuboConversionType

  /**
   * 査定書の表紙画像のキー
   * @example companies/{company_id}/cover_design.png
   */
  cover_design_image_key: string

  /**
   * 査定書の表紙画像のURL
   * @example https://some.where/images/cover-design.png
   */
  cover_design_image_url: string

  /**
   * リアクション機能表示フラグ
   * リアクション機能はURL共有した査定書から閲覧者が査定書作成者に連絡をとることができる機能です。
   * 企業設定のページでON／OFFの制御ができます。
   * 以下プロジェクトのイシューです。
   * https://www.notion.so/4e061f2f43944666a358ee290e93b7a6?v=5e54cf5d71dc40358afb862dcc1de557&p=782e0579000e4b20a294455c8c4b4d9a&pm=s
   * @example true
   */
  is_display_reaction_contacts: boolean
}

export interface Office {
  id: number

  /**
   * 企業ID
   * @example 12345
   */
  company_id: number

  /** 担当者一覧 */
  members: {
    user_id: number
    email: string
    given_name: string
    family_name: string
    tel1: string
    role_name: any
    selected: boolean
  }[]

  /**
   * 本店フラグ(trueの場合に本店)
   * @example true
   */
  head_office: boolean

  /**
   * 組織コード
   * NSLアカウントの場合に使用しています。
   * 店舗・担当者CSV取り込みを行う際の紐づけ処理のために使われます。
   * @example 2101020000
   */
  code: string

  /**
   * 並び順
   * @example 1
   */
  display_order: number

  /**
   * 支店・センター名
   * @example 芝浦本店
   */
  name: string

  /**
   * 郵便番号
   * @example 104-0052
   */
  zip: string

  /**
   * 住所
   * @example 東京都港区 芝浦1丁目13−10 第3東運ビル 8F
   */
  address: string

  /**
   * 電話番号
   * @example 53068444
   */
  tel1: string

  /**
   * フリーコール
   * @example 53068444
   */
  tel2: string

  /**
   * FAX番号
   * @example 53068444
   */
  fax: string

  /**
   * 営業時間
   * @example 10:00~18:00
   */
  business_hours_text: string

  /**
   * 定休日
   * @example 火・水
   */
  regular_day_off_text: string

  /**
   * アクセス
   * @example JR目黒駅より・・・
   */
  access_text: string

  /**
   * 駐車場
   * @example ...
   */
  parking_text: string

  /**
   * 紹介コメント
   * @example ...
   */
  comment_text: string

  /**
   * マップ画像 S3 key
   * @example companies/{company_id}/offices/{office_id}/map_image/1.png
   */
  map_image_key: string

  /**
   * マップ画像URL
   * @example https://some.where/images/logo.png
   */
  map_image_url: string

  /**
   * 店舗イメージ S3 key
   * @example companies/{company_id}/offices/{office_id}/office_image/1.png
   */
  office_image_key: string

  /**
   * 店舗イメージURL
   * @example https://some.where/images/logo.png
   */
  office_image_url: string

  /** カスタム属性一覧を取得します。 */
  office_custom_fields: OfficeCustomField[]

  /** カスタム属性の値を配列で返します。 */
  office_custom_field_values: OfficeCustomFieldValue[]

  /** カスタム属性一覧から特定したカスタム属性の値を配列で返します。 */
  custom_fields_data?: { name?: string; value?: string }[]
}

/**
* 独自資料
PDFや画像をアップロードして独自の資料ページを作成することができます。
*/
export interface Document {
  /**
   * 独自資料ID
   * @example 12345
   */
  id: number

  /**
   * ユーザーID
   * @example 12345
   */
  user_id?: number

  /**
   * 資料名
   * @example 売却応援キャンペーン
   */
  name: string

  /**
   * 資料タイトル
   * @example 売却応援キャンペーン
   */
  title: string

  /**
   * ファイル S3 key
   * @example companies/{company_id}/users/{user_id}/documents/{office_id}.png
   */
  file_key: string

  /**
   * ファイルURL
   * アップロード出来るのは下記の拡張子のファイルです。
   * - png
   * - jpeg
   * - jpg
   * - pdf
   * @example https://some.where/files/abc.pdf
   */
  file_url: string

  /** ファイルURL一覧 */
  file_urls: string[]

  /**
   * 作成時に自動でランダムな文字列（uuid）が設定されます。これは独自資料を査定書に挿入する際のページ識別子として使われます。
   * @example 291da1ce-a40f-ea97-e661-91d270bc3d6a
   */
  page_key: string

  /**
   * 帳票でヘッダーとフッターの有無を制御します。デフォルトは true です。
   * @example true
   */
  has_header_and_footer: boolean

  /**
   * 独自資料の作成日です。
   * @example 2023-03-07 03:40:29.95429
   */
  created_at: string

  /**
   * 独自資料の最終更新日です。
   * @example 2023-03-07 03:40:29.95429
   */
  updated_at: string
}

/**
 * 取り込み用店舗データファイル
 */
export interface OfficesImportFile {
  id: number

  /**
   * 企業ID
   * @example 12345
   */
  company_id: number

  /**
   * 取り込み用事例データファイルのステータス
   * unknown: 不明
   * wait: 取り込み処理が未実行
   * active: 取り込み処理を実行中
   * completed: 取り込み処理が完了
   * failed: 取り込み処理が失敗
   */
  status: ImportFileStatus

  /**
   * 取り込みに失敗した場合のメッセージ
   * @example TODO
   */
  error_message?: string

  /**
   * 添付ファイル（S3のキー）
   * @example companies/{company_id}/offices_import_files/example.csv
   */
  document_key: string
  created_at?: string
  updated_at?: string
}

/**
 * 取り込み用担当者データファイル
 */
export interface UsersImportFile {
  id: number

  /**
   * 企業ID
   * @example 12345
   */
  company_id: number

  /**
   * 取り込み用事例データファイルのステータス
   * unknown: 不明
   * wait: 取り込み処理が未実行
   * active: 取り込み処理を実行中
   * completed: 取り込み処理が完了
   * failed: 取り込み処理が失敗
   */
  status: ImportFileStatus

  /**
   * 取り込みに失敗した場合のメッセージ
   * @example TODO
   */
  error_message?: string

  /**
   * 添付ファイル（S3のキー）
   * @example companies/{company_id}/users_import_files/example.csv
   */
  document_key: string
  created_at?: string
  updated_at?: string
}

/**
* 入力チェックの結果
入力チェックに問題がない場合は空の配列が返却されます。
*/
export interface ImportFileValidationResult {
  /** 注意事項 */
  notices: ImportFileValidationResultRow[]

  /** エラーメッセージ */
  errors: ImportFileValidationResultRow[]
}

export interface ImportFileValidationResultRow {
  /** 行番号 */
  index: number

  /** 社員番号 */
  code: string

  /** エラーメッセージ */
  messages: string[]
}

export interface User {
  /**
   * ユーザーID（非推奨）
   * RailsのデフォルトのID
   * このIDは連番であり、セキュリティ上の観点から非推奨です。
   */
  id: number

  /**
   * 外部サービス用担当者ID
   * 担当者を一意に識別するためのコード
   *
   * 2024-08
   * HowMa連携案件をきっかけに追加した項目です。
   * ほかサービス連携にあたり連番のプライマリIDとは別に追加しました。
   * 連番だとレコード数が推測できてしまうため、セキュリティ上の観点から追加しました。
   * @example 550e8400-e29b-41d4-a716-446655440000
   */
  uuid: string

  /**
   * 認証されたユーザーの一意識別子
   * @example *******************
   */
  sub: string

  /**
   * 登録メールアドレス
   * @example test@example.com
   */
  email: string

  /**
   * 名前
   * @example 太郎
   */
  given_name?: string

  /**
   * 名字
   * @example 査定
   */
  family_name?: string

  /**
   * 名前のふりがな
   * @example たろう
   */
  given_name_ruby: string

  /**
   * 名字のふりがな
   * @example さてい
   */
  family_name_ruby: string

  /**
   * フルネーム
   * @example 査定 太郎
   */
  name?: string

  /**
   * 電話番号1
   * @example 9012341234
   */
  tel1?: string

  /**
   * 電話番号2
   * @example 53068444
   */
  tel2?: string

  /** ご挨拶文 */
  greeting_text: string

  /**
   * 従業員番号
   * 企業IDと従業員番号の結合文字列がユニークキーです。
   * CSV取込機能の際はこのキーを元に上書き保存されます。
   * @example 12345
   */
  code: string

  /**
   * 並び順
   * @example 1
   */
  display_order: number

  /**
   * プロフィール写真 S3 key
   * @example companies/{company_id}/offices/{office_id}/office_image/1.png
   */
  profile_image_key?: string

  /**
   * プロフィール写真URL
   * @example https://some.where/images/photo.png
   */
  profile_image_url?: string
  company: Company

  /** ユーザーの権限 */
  role_name: 'admin' | 'company_admin' | 'office_admin' | 'member'

  /** 権限設定を管理するデータです。 */
  permission_setting: PermissionSetting
  main_office?: Office
  offices?: Office[]

  /**
   * 査定書引き継ぎフラグ
   * 1: 異動先の店舗に査定書を引き継ぐ
   * 0: 異動元の店舗に査定書を残す(user_idはnullとなる)
   */
  is_taking_over_reports?: boolean

  /** 帳票のデザイン */
  default_design_type: PrintSettingDesignType

  /** 帳票のカバーの色 */
  default_color: PrintSettingColor
}

/**
 * 権限設定を管理するデータです。
 */
export interface PermissionSetting {
  /**
   * 担当者カスタムフィールド管理機能の有効フラグ
   * @example false
   */
  enabled_user_custom_fields: boolean

  /**
   * 帳票写真管理機能の有効フラグ
   * @example false
   */
  enabled_report_photos: boolean

  /**
   * 帳票カスタムフィールド管理機能の有効フラグ
   * @example false
   */
  enabled_report_custom_fields: boolean

  /**
   * 店舗管理機能(店舗管理者用)の有効フラグ
   * @example false
   */
  enabled_offices_level1: boolean

  /**
   * 店舗管理機能(企業管理者用)の有効フラグ
   * 一部の属性はこの権限がないと編集ができない。
   * @example false
   */
  enabled_offices_level2: boolean

  /**
   * 分譲価格比の査定の有効フラグ
   * 現在はNSLのみがtrueを返却します
   * @example false
   */
  enabled_sale_price_approach_assessment: boolean

  /**
   * 店舗CSVファイルの取込機能の有効フラグ
   * 現在はNSLのみがtrueを返却します
   * @example false
   */
  enabled_offices_import_file?: boolean

  /**
   * 担当者管理の有効フラグ
   * trueの場合は企業管理者の場合に担当者の追加、編集、削除が可能です。
   * 2024-10-21現在
   * 下記の企業の場合はfalseを返却します。
   * - nsl: 野村不動産ソリューションズ株式会社
   * - ttf: 東京建物不動産販売株式会社
   * - njs: 株式会社日住サービス(こちらは店舗管理が未導入)
   * - dka: 株式会社大京穴吹不動産(こちらはcustom_nameがないのでidで判定)
   * - emf: 株式会社駅前不動産
   * @example false
   */
  enabled_users?: boolean

  /**
   * 担当者CSVファイルの取込機能の有効フラグ
   * 現在はNSLのみがtrueを返却します
   * @example false
   */
  enabled_users_import_file?: boolean

  /**
   * 事例CSVファイルの取込機能の有効フラグ
   * 現在はNSLのみがtrueを返却します
   * @example false
   */
  enabled_stories_import_file: boolean

  /**
   * 事例の画像をマスキングする機能の有効フラグ
   * @example false
   */
  enabled_image_masking: boolean

  /**
   * マンションカテゴリーの有効フラグ
   * @example false
   */
  enabled_condo_report: boolean

  /**
   * 土地カテゴリーの有効フラグ
   * @example false
   */
  enabled_land_report: boolean

  /**
   * 戸建カテゴリーの有効フラグ
   * @example false
   */
  enabled_house_report: boolean

  /**
   * エリアレポートの有効フラグ
   * @example false
   */
  enabled_area_report: boolean

  /**
   * 独自資料のヘッダーとフッターの有効フラグ
   * @example false
   */
  enabled_document_header_and_footer: boolean

  /**
   * BRaiNから取得した物件資料のExport有効フラグ
   * @example false
   */
  enabled_brain_document: boolean

  /**
   * 売り仲介成功資料にアクセするためのURL表示有効フラグ
   * @example false
   */
  enabled_sales_document_url: boolean

  /**
   * リフォーム加算の有効フラグ
   * @example false
   */
  enabled_condo_renovation_price: boolean

  /**
   * 住所（表示用）の有効フラグ
   * @example false
   */
  enabled_address_for_display: boolean

  /**
   * 部位別耐用年数を考慮した建物価格算定の有効フラグ
   * @example false
   */
  enabled_depreciation_rate_item: boolean

  /**
   * その他補正項目の有効フラグ
   * @example false
   */
  enabled_other_correction_items: boolean

  /**
   * スピードモードの有効フラグ
   * @example false
   */
  enabled_speed_mode: boolean

  /**
   * 共有URLのパスワード有効フラグ
   * @example false
   */
  enabled_report_share_required_password: boolean

  /**
   * 収益還元法の有効フラグ
   * @example false
   */
  enabled_income_approach_assessment: boolean

  /**
   * 売却反響獲得への誘導モーダルの有効フラグ
   * @example false
   */
  enabled_guidance_description_modal: boolean

  /**
   * HowMaの一括査定リクエストの有効フラグ
   * @example false
   */
  enabled_howma_ba_requests: boolean

  /**
   * 新売出提案価格セクションの有効フラグ
   * @example false
   */
  enabled_v2_offer_price_section: boolean

  /**
   * オリジナルデザインカバー画像機能の有効フラグ
   * @example false
   */
  enabled_original_design_cover_image_feature: boolean

  /**
   * リアクション機能の有効フラグ
   * @example true
   */
  enabled_show_reaction_contacts: boolean

  /**
   * 帳票カスタム機能の有効フラグ
   * 主に、本番環境とステージング環境の出しわけに用いる目的で用意されています。
   * @example false
   */
  enabled_print_custom_design: boolean

  /**
   * 一棟査定の有効フラグ
   * 主に、本番環境とステージング環境の出しわけに用いる目的で用意されています。
   * @example false
   */
  enabled_multi_unit_building_category: boolean
}

/**
 * 店舗に対して設定できるカスタムフィールドです。
 */
export interface OfficeCustomField {
  id: number

  /** 企業ID */
  company_id?: number

  /** 項目名 */
  name: string

  /** 詳細情報 */
  description: string

  /**
   * 項目の値タイプ
   * - string: 文字列(単一行)
   * - text: 文字列(複数行)
   * - number: 数値
   */
  value_type: 'string' | 'text' | 'number'

  /**
   * 優先度
   * 小さい数値のほうが優先度が高い。
   */
  priority: number

  /**
   * 有効フラグ
   * 有効な場合はtrueを返却します。
   */
  enabled: boolean
}

/**
* 店舗に対して設定できるカスタムフィールドです。
(例: 出身地、資格など)
*/
export interface OfficeCustomFieldValue {
  id: number
  office_id: number
  office_custom_field_id: number

  /**
   * 項目の値
   * DB上はstring型で値を保存します。
   * APIで返却する際にはvalue_typeに応じた型に変換されます。
   */
  value: string
}

/**
* 企業担当者がユーザーに対して設定できるカスタムフィールドです。
(例: 出身地、資格など)
*/
export interface UserCustomField {
  id: number

  /** 企業ID */
  company_id?: number

  /** 項目名 */
  name: string

  /** 詳細情報 */
  description: string

  /**
   * 項目の値タイプ
   * - string: 文字列(単一行)
   * - text: 文字列(複数行)
   * - number: 数値
   */
  value_type: 'string' | 'text' | 'number'

  /**
   * 優先度
   * 小さい数値のほうが優先度が高い。
   */
  priority: number

  /**
   * 有効フラグ
   * 有効な場合はtrueを返却します。
   */
  enabled: boolean
}

/**
* 企業担当者がユーザーに対して設定できるカスタムフィールドです。
(例: 出身地、資格など)
*/
export interface UserCustomFieldValue {
  id: number
  user_id: number
  user_custom_field_id: number

  /**
   * 項目の値
   * DB上はstring型で値を保存します。
   * APIで返却する際にはvalue_typeに応じた型に変換されます。
   */
  value: string
}

/**
* 企業担当者が帳票に対して設定できるカスタムフィールドです。
(例: 購入見込客層、地域特性コメントなど)
*/
export interface ReportCustomField {
  id: number

  /** 企業ID */
  company_id?: number

  /**
   * 識別子
   * ソースベース上でどのカスタムフィールドか識別するための文字列です。
   */
  identifier: string

  /** 項目名 */
  name: string

  /** 詳細情報 */
  description: string

  /**
   * 項目の値タイプ
   * - string: 文字列(単一行)
   * - text: 文字列(複数行)
   * - integer: 整数
   * - float: 浮動小数点数
   */
  value_type: 'string' | 'text' | 'integer' | 'float'

  /**
   * 優先度
   * 小さい数値のほうが優先度が高い。
   */
  priority: number

  /**
   * 有効フラグ
   * 有効な場合はtrueを返却します。
   */
  enabled: boolean

  /**
   * 非表示フラグ
   * 非表示にする場合はtrueを返却します。
   * [NSLの場合]
   * NSLでは独自の売出提案モジュールを提供します。
   * そのモジュール内で扱う値はカスタムフィールドで管理します。
   * それらは通常のカスタムフィールドモジュールでは非表示としたいためこのカラムの値がtrueとなります。
   */
  hidden: boolean

  /**
   * 入力フォームの接頭辞です。
   * @example ¥
   */
  prefix_text: string

  /**
   * 入力フォームの接尾辞です。
   * @example 人
   */
  suffix_text: string
}

/**
* 企業担当者が帳票に対して設定した属性の値です。
(例: 購入見込客層、地域特性コメントなど)
*/
export interface ReportCustomFieldValue {
  id: number
  report_id: number
  report_custom_field_id: number

  /**
   * 識別子
   * ソースベース上でどのカスタムフィールドか識別するための文字列です。
   */
  identifier: string

  /**
   * 項目の値
   * DB上はstring型で値を保存します。
   * APIで返却する際にはvalue_typeに応じた型に変換されます。
   */
  value: string | number
}

/**
* プランを管理するためのデータモデルです。
最終的には既存のPlanからこのEnhancedPlanに移行する予定です。
*/
export interface Plan {
  id: number

  /**
   * プランの名称
   * エンドユーザ向けに表示される名称です。
   * @example エントリープラン
   */
  name: string

  /**
   * プランの名称(略称)
   * 管理画面などで表示される名称です。
   * @example エントリー
   */
  name_short: string

  /**
   * 無料査定数(これを超過する場合は別途料金が発生します)
   * @example 20
   */
  monthly_reports_count: number

  /**
   * 無料査定数が無制限の場合にtrue
   * @example false
   */
  reports_count_unlimited: boolean

  /**
   * 無料期間がある場合にtrue
   * @example false
   */
  has_trial_period?: boolean

  /**
   * 月額料金
   * @example 5000
   */
  monthly_price?: number

  /**
   * 期間内に査定できる回数
   * この回数を超えて査定を行ッた場合は、有料での査定となります。
   * この値がnilの場合は、無制限となります。
   * (EnhancedPlanと結合して追加されました)
   * @example 20
   */
  reports_count: number

  /**
   * 査定回数をカウントする期間
   * indefinite: 期間が定められておらず、回数がリセットされない。累計○回プランなどで選択されます。
   * monthly: 月ごとに回数がリセットされます。通常のプランはこちらが選択されます
   * (EnhancedPlanと結合して追加されました)
   * @example monthly
   */
  reports_count_period_type: string

  /**
   * プラン一覧の並び順を管理する番号 小さい値のならびが先になります。
   * @example 0
   */
  sort_order: number
}

/**
* 2024年4月に累計○回プランが新設されたため、このEnhancedPlanが作られました。
最終的には既存のPlanからこのEnhancedPlanに移行する予定です。

[試用期間について]
なお旧プランではhas_trial_periodの属性があり、試用期間があるかどうかを示していましたが、
このEnhancedPlanでは廃止されました。
理由は試用期間についてはプランに紐づくものではなく、企業のPaymentモデルに紐づくべきだという判断からです。
よって、EnhancedPlanに完全移行する場合は、試用期間をPaymentモデルに実装する必要があります。
*/
export interface EnhancedPlan {
  id: number

  /**
   * プランの名称
   * エンドユーザ向けに表示される名称です。
   * @example エントリープラン
   */
  name: string

  /**
   * プランの名称(略称)
   * 管理画面などで表示される名称です。
   * @example エントリー
   */
  name_short: string

  /**
   * 期間内に査定できる回数
   * この回数を超えて査定を行ッた場合は、有料での査定となります。
   * この値がnilの場合は、無制限となります。
   * @example 20
   */
  reports_count: number

  /**
   * 査定回数をカウントする期間
   * indefinite: 期間が定められておらず、回数がリセットされない。累計○回プランなどで選択されます。
   * monthly: 月ごとに回数がリセットされます。通常のプランはこちらが選択されます
   */
  reports_count_period_type: ReportsCountPeriodType

  /**
   * 月額料金
   * @example 12800
   */
  monthly_price: number
}

/**
* 外部サービスから査定書を作成した場合に別集計するためのオプションです。
このオプションのレコードを作成することで、外部サービスからの査定書作成回数をカウントし必要な制限を設けることができます。
*/
export interface PlanExternalServiceOption {
  id: number

  /**
   * 外部サービス名
   * - howma: HowMa
   */
  name: ExternalServiceName

  /**
   * 期間内に査定できる回数
   * この回数を超えて査定を行うことはできません。
   * @example 20
   */
  reports_count: number

  /**
   * 査定回数をカウントする期間
   * indefinite: 期間が定められておらず、回数がリセットされない。累計○回プランなどで選択されます。
   * monthly: 月ごとに回数がリセットされます。通常のプランはこちらが選択されます
   */
  reports_count_period_type: ReportsCountPeriodType
}

/**
* 決済情報を管理するモデルです。
planに1x1でひも付き、companiesと1*nの関係でひも付きます。
ただしcompanyモデルから参照される実装を想定しているのでcompaniesの属性は持たない設計にしています。
*/
export interface Payment {
  id: number

  /**
   * プランを管理するためのデータモデルです。
   * 最終的には既存のPlanからこのEnhancedPlanに移行する予定です。
   */
  plan: Plan

  /**
   * 残りの無料査定数(月間無料査定階数 - 月間帳票数)
   * @example 10
   */
  remaining_monthly_reports_count: number

  /**
   * 課金の対象の場合にtrueを返却します
   * @example true
   */
  billing?: boolean

  /**
   * お試し無料期間の場合にtrueを返却します
   * @example false
   */
  trial_period: boolean

  /**
   * 今月累計査定数
   * 無料期間内の査定数はカウントされません。
   *
   * 2024年4月14日
   * この属性はreports_count_in_periodが追加されて非推奨となりました。
   * @example 5
   */
  reports_count_this_month: number

  /**
   * 課金対象期間内の累計査定数
   * @example 5
   */
  reports_count_in_period: number

  /**
   * プランのアップグレードが必要な場合にtrueを返却します
   * お試しプランでremaining_monthly_reports_countが0の場合にtrueを返却します
   * @example false
   */
  need_upgrade_plan: boolean

  /**
   * 外部サービスのみの利用の場合にtrueを返却します
   * もしtrueの場合はTOPページや一覧ページへのアクセスが制限されます。
   * @example false
   */
  external_service_only: boolean

  /** 利用開始日 */
  activated_at: string

  /**
   * 決済ステータス
   * active: 課金状態
   * inactive: 非課金状態(紐づくuserはログイン時に認証ができなくなります。)
   *
   * auth0のDBとの連動はしていません。
   * 手動運用時(~202301)まではauth0をブロックする運用を行っていました。
   * しかし202302からはアプリケーションのDB上の属性だけで認証を制御するようにしています。
   */
  status: PaymentStatus
}

/**
* 請求内容を管理するモデルです。
paymentに一対一でひも付きます
*/
export interface Invoice {
  /**
   * 請求ID
   * @example 123
   */
  id: number

  /**
   * 決済ID
   * @example 123
   */
  payment_id: number

  /**
   * 請求年
   * @example 2023
   */
  target_year: number

  /**
   * 請求月
   * @example 1
   */
  target_month: number

  /**
   * 会社名
   * @example 株式会社コラビット
   */
  company_name: string

  /**
   * 請求年月のプラン名
   * プラン変更した場合は下記のように設定されます。
   * - 当月適用(現状プランより上位のプラン) -> 当月適用されたプラン名
   * - 翌月適用(現状プランより下位のプラン) -> 変更前の当月のプラン名
   * @example エントリープラン
   */
  plan_name: string

  /**
   * 請求年月のプランの査定可能回数
   * @example 20
   */
  monthly_reports_count: number

  /**
   * 請求年月の査定書の作成回数(削除含む)
   * @example 25
   */
  reports_count: number

  /**
   * 請求年月の請求金額(税抜)
   * 計算式: 基本料金 + 超過分の料金
   * 例: 17800 = 12800 + 1000 * (25 - 20)
   *
   * 請求金額の確定時点(毎月1日0:00)に下記の場合でも無関係に算出しています。
   * - 停止状態(status=inactive)
   * - デモアカウント(billing=false)
   * @example 17800
   */
  price: number

  /**
   * 請求年月の請求内容に関するメモ
   * @example あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。
   */
  memo: string

  /**
   * 会社ID
   * company_idは1対Nで紐づきますが、若いIDのものを1つだけ返却します。
   * @example 123
   */
  company_id: number
}

export interface ReportAggregation {
  /**
   * 会社ID
   * @example 1
   */
  company_id: number

  /**
   * 会社名
   * @example 2
   */
  name: string

  /**
   * 店舗ID
   * @example 3
   */
  office_id: number

  /**
   * 店舗名
   * @example 芝浦支店
   */
  office_name: string

  /**
   * ユーザーID
   * @example 4
   */
  user_id?: number

  /**
   * ユーザー名
   * @example 田中 太郎
   */
  user_full_name?: string

  /**
   * プロ査定モード_マンション査定回数
   * @example 0
   */
  condo_reports_count: number

  /**
   * プロ査定モード_土地査定回数
   * @example 1
   */
  land_reports_count: number

  /**
   * プロ査定モード_戸建査定回数
   * @example 0
   */
  house_reports_count: number

  /**
   * らくらくモード_マンション査定回数
   * @example 0
   */
  raku_condo_reports_count: number

  /**
   * らくらくモード_土地査定回数
   * @example 1
   */
  raku_land_reports_count: number

  /**
   * らくらくモード_戸建査定回数
   * @example 0
   */
  raku_house_reports_count: number

  /**
   * エリアレポート回数
   * @example 0
   */
  area_reports_count: number

  /**
   * 合計出力(エリアレポート以外)数
   * @example 2
   */
  reports_count_except_area_report: number

  /**
   * 区分収益レポート数
   * @example 2
   */
  income_condo_reports_count: number

  /**
   * 合計出力数
   * @example 2
   */
  reports_count: number
}

export interface PostedLandPriceStory {
  /**
   * id
   * @example 1
   */
  id?: number
  record_type?: RecordType

  /**
   * 標準地コード_見出し番号
   * @example 000
   */
  represented_land_code_index_number?: string

  /**
   * 標準地コード_一連番号
   * @example 017
   */
  represented_land_code_sequence_number?: string

  /**
   * 前年度標準地コード_見出し番号
   * @example 000
   */
  previous_represented_land_code_index_number?: string

  /**
   * 前年度標準地コード_一連番号
   * @example 017
   */
  previous_represented_land_code_sequence_number?: string

  /**
   * 年度
   * @example 2021
   */
  year?: number

  /**
   * 公示価格 or 都道府県地価
   * @example 1560000
   */
  price?: number

  /**
   * （仕様書に説明なし）
   * @example 1
   */
  selected_land_status?: number

  /**
   * 標準地行政コード
   * @example 13103
   */
  administrative_area_code?: string

  /**
   * 標準地市区町村名称
   * @example 港
   */
  city_name?: string

  /**
   * 住所
   * @example 東京都　港区芝浦２－３－２７
   */
  address?: string

  /**
   * 住所
   * @example 東京都　千代田区六番町６番１外
   */
  location?: string

  /**
   * 地積
   * @example 993
   */
  acreage?: number

  /**
   * 利用現況
   * @example 住宅,その他
   */
  current_use?: string

  /**
   * 利用状況表示
   * @example 共同住宅
   */
  usage_description?: string

  /**
   * 建物構造
   * @example RC11
   */
  building_structure?: string

  /**
   * 形状
   * @example 台形
   */
  configuration?: string

  /**
   * 前面道路状況
   * @example 区道
   */
  frontal_road?: string

  /**
   * 前面道路の方位
   * @example 北西
   */
  direction_of_frontal_road?: string

  /**
   * 前面道路の幅員
   * @example 11
   */
  width_of_frontal_road?: number

  /**
   * 側道状況
   * @example 側道
   */
  side_road?: string

  /**
   * 側道の方位
   * @example 南
   */
  direction_of_side_road?: string

  /**
   * 駅名
   * @example 田町
   */
  name_of_nearest_station?: string

  /**
   * 駅からの距離
   * @example 760
   */
  distance_from_station?: number

  /**
   * 用途区分
   * @example 準工
   */
  use_district: string

  /**
   * 建ぺい率
   * @example 60
   */
  building_coverage?: number

  /**
   * 容積率
   * @example 400
   */
  floor_area_ratio?: number

  /**
   * 直近5年分の調査価格
   * @example [{"year":2021,"value":3840000,"yoy":1.1},{"year":2020,"value":3840000,"yoy":-0.9}]
   */
  prices?: any[]

  /**
   * 緯度
   * @format double
   * @example 35.64386806000005
   */
  lat?: number

  /**
   * 経度
   * @format double
   * @example 139.7543469000001
   */
  lng?: number

  /**
   * 対象物件と事例物件の距離
   * @example 356
   */
  distance?: number

  /**
   * 接道状況
   * @example ippou
   */
  corner?: string

  /**
   * 区域区分
   * @example urbanization_area
   */
  area_classification?: string
}

export type Stations = Station[]

export type Lines = Line[]

export type PostedLandPriceStories = PostedLandPriceStory[]

export interface Report {
  /** 査定書ID */
  id: number

  /** ユーザーID */
  user_id?: number

  /** 企業ID */
  company_id: number

  /** 店舗ID */
  office_id?: number

  /**
   * 不動産の種類を表します。
   * - house: 戸建
   * - land: 土地
   * - condo: 区分マンション
   * - area: エリアレポート
   * - speed_mode_house: 戸建（スピードモード）
   * - speed_mode_land: 土地（スピードモード）
   * - speed_mode_condo: 区分マンション（スピードモード）
   * - multi_unit_building: 一棟
   */
  category: ReportCategory
  created_at: string
  updated_at: string
  created_year: number
  created_month: number

  /**
   * 原価法及び取引事例比較法を元に、手動で入力された査定価格が入ります。
   * @example 8888
   */
  assessment_price: number

  /**
   * 査定賃料（月額）(円)
   * 収益還元法の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   * @example 200000
   */
  assessment_rent_price?: number

  /**
   * 査定利回り(%)
   * 収益還元法の場合、返却します。
   * それ以外の査定方法の場合はnull値が返却されます。
   * @example 4.5
   */
  assessment_yield_percentage?: number

  /**
   * 査定価格の種別。利用可能な値は以下の通りです。
   * - unknown: 不明
   * - cost: 原価法
   * - market: 取引事例比較法
   * - sale_price: 分譲価格比の査定
   * - speed_mode: スピードモード査定
   * - income: 収益還元法
   * - manual: 任意の査定価格
   */
  assessment_price_category: AssessmentPriceCategory

  /**
   * 査定コメント
   * @example ここに査定結果に対するコメントが入ります
   */
  assessment_comment: string

  /**
   * 売出提案価格(下限)
   * @example 7444
   */
  offer_price_lower: number

  /**
   * 売出提案価格(上限)
   * @example 7777
   */
  offer_price: number

  /**
   * 手入力で売出提案価格の入力があった場合、そちらが優先されるようにするためのフラグです。
   * また、以下の条件でも true に設定されます:
   *   1. 初期の `offer_price_item` レコードの値が変更された場合。
   *   2. `offer_price_items` のレコードが新たに追加された場合（デフォルト設定のレコードも含む）。
   *   3. `offer_price_items` のレコードが削除された場合。
   */
  offer_price_edited: boolean

  /**
   * 売出提案価格コメント
   * @example ここに売出提案価格に対するコメントが入ります
   */
  offer_comment: string

  /**
   * 売出提案価格コメントの前半部分
   * @example ここに売出提案価格に対するコメントの前半部分が入ります。
   */
  offer_comment_first_part: string

  /**
   * 売出提案価格コメントの後半部分
   * @example ここに売出提案価格に対するコメントの後半部分が入ります。
   */
  offer_comment_second_part: string

  /**
   * レンジ表記フラグ
   * @example true
   */
  enabled_range: boolean

  /**
   * 売出提案価格(下限)の利回り
   * 収益還元法の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   * @example 4.88
   */
  yield_percentage_for_offer_price_lower?: number

  /**
   * 売出提案価格(上限)の利回り
   * 収益還元法の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   * @example 4.55
   */
  yield_percentage_for_offer_price?: number

  /** 査定書の初期化完了フラグ */
  initialized: boolean

  /** 査定書の印刷可能フラグ */
  printable: boolean

  /**
   * 編集可能かどうかのフラグ
   * @example true
   */
  editable?: boolean

  /**
   * 複製可能かどうかのフラグ
   * @example true
   */
  duplicatable: boolean
  land?: Land

  /** 建物のドメインモデルです。 */
  building?: Building
  condo?: Condo
  speed_mode_house?: SpeedModeHouse
  speed_mode_land?: SpeedModeLand
  speed_mode_condo?: SpeedModeCondo
  cost_approach_assessment?: Assessment
  market_approach_assessment?: Assessment
  sale_price_approach_assessment?: Assessment
  speed_mode_approach_assessment?: Assessment
  income_approach_assessment?: Assessment

  /** 競合分析データ */
  competitor_analysis: CompetitorAnalysis

  /** 相場分析データ */
  market_analysis?: MarketAnalysis
  user?: User
  office?: Office
  print_setting: PrintSetting

  /**
   * その他の補正率（score.etc）に対するコメントが入ります
   * @example 2022年8月にフルリノベーション済みです。
   */
  etc_point_comment: string

  /**
   * 「建物その他」の補正率（score.building_etc）に対するコメントが入ります
   * @example 対象不動産は特別人気のあるマンションです。
   */
  building_etc_point_comment: string
  company: Company

  /** 緯度 */
  lat?: number

  /** 経度 */
  lng?: number

  /** 帳票URLを発行した日時 */
  url_published_at?: string

  /** 共有URLにアクセスされた履歴を取得します（最大5件） */
  report_access_logs?: ReportAccessLog[]

  /** カスタム属性一覧を取得します。 */
  report_custom_fields: ReportCustomField[]

  /** カスタム属性の値を配列で返します。 */
  report_custom_field_values: ReportCustomFieldValue[]
  documents: Document[]
  exit_simulation?: ExitSimulation
  road_prices?: RoadPrice[]

  /** その他補正率の有効フラグ */
  other_correction_items_active: boolean

  /** 市場性調整率の有効フラグ */
  adjustment_percentage_active: boolean

  /** 売出提案価格の調整属性 */
  offer_price_items?: OfferPriceItem[]

  /**
   * REINSマーケット情報データ
   * NSLの帳票で使用します。(NSL以外の企業の場合はレスポンスはnullになります。)
   */
  reins_data_document?: ReinsDataDocument

  /** 共有ページ情報 */
  report_authentication?: ReportAuthentication

  /**
   * 提案価格セクションの有効フラグ。
   * このフラグが有効である場合、提案価格セクションが表示されます。
   * このフラグが無効である場合、`売出提案価格`という役割に固定されていましたが、
   * 有効になることで、より柔軟に提案価格を設定できるようになります。
   */
  enabled_v2_offer_price_section: boolean

  /**
   * 外部サービス名
   * - howma: HowMa
   */
  external_service_name: ExternalServiceName

  /**
   * 外部サービスのリソースID
   * @example 123e4567-e89b-12d3-a456-426614174000
   */
  external_service_resource_id: string

  /**
   * 別紙備考
   * @example ここに別紙備考が入ります
   */
  additional_remarks?: string
}

/**
 * 査定書に紐づく写真を保存するテーブルです。
 */
export interface ReportPhoto {
  /** 査定書に紐づく写真のID */
  id: number

  /** 査定書ID */
  report_id: number

  /** 帳票に添付する画像の種類 */
  category: PhotoCategory

  /**
   * 添付ファイル（S3のキー）
   * @example reports/{report_id}/{photo_type}/example.png
   */
  document_key: string

  /**
   * 添付ファイル（URL）
   * @example https://some.where/images/example.png
   */
  document_url: string

  /**
   * 物件写真の名前
   * @example 間取り図・平面図
   */
  name: string

  /**
   * 写真に対するコメント
   * @example ご所有不動産の測量図になります。確定測量が行われたのが15年前なるため、不確定要素を考慮すべきです。売却時には確定測量費用が発生することも想定する必要があります。
   */
  comment: string
}

/**
* 査定書のPDF出力ログです。
PDFの出力処理のステータスやURLを保存します。

このドメインモデルは査定書と1対1の関係です。
*/
export interface PdfExportLog {
  /** PDF出力ログID */
  id: number

  /**
   * PDF出力ログのステータス
   * unknown: 不明
   * wait: 出力処理が未実行
   * active: 出力処理を実行中
   * completed: 出力処理が完了（ダウンロードが可能）
   * failed: 出力処理が失敗
   */
  status: PdfExportLogStatus

  /**
   * PDFのS3キー
   * 作成されたPDFが保存されたS3のキーです。
   *
   * これを下にpresigned URLを生成し、urlとして返します。
   * @example companies/{company_id}/reports/{report_id}/pdf_export_logs/{id}/****.pdf
   */
  document_key: string

  /**
   * webサイトをPDF化する際のURL
   * このURLにアクセスするとPDFのURL(S3のpresigned URL)にリダイレクトされます。
   * アプリ側では非同期処理によってPDFのURLからdocument_keyを取得して、document_keyを保存します。
   */
  target_url: string

  /** PDFのURL(S3のpresigned URL) */
  url: string

  /**
   * PDFの再生成が必要かどうか
   *
   * Report.updated_atがPdfExportLog.updated_atより新しい場合はtrueになります。
   */
  need_update: boolean
}

export interface Land {
  /**
   * 住所
   * @example 東京都中央区月島1-22-1
   */
  address?: string

  /**
   * 住所（表示用）
   * @example 渋谷区渋谷１丁目
   */
  address_for_display?: string

  /**
   * 緯度
   * @format double
   * @example 35.663829636289904
   */
  lat?: number

  /**
   * 経度
   * @format double
   * @example 139.78193518226857
   */
  lng?: number

  /** 沿線名 */
  route1_line_name?: string

  /** 沿線ID */
  route1_line_code?: number

  /** 駅名 */
  route1_station_name?: string

  /** 駅ID */
  route1_station_code?: number

  /** 駅徒歩 */
  route1_distance_to_station?: number

  /** バス（停徒歩） */
  route1_distance_to_busstop?: number

  /** バス（乗車時間） */
  route1_distance_to_station_by_bus?: number

  /** バス停名 */
  route1_busstop_name?: string
  ownership: Ownership

  /** 借地権割合(所有権の場合は1扱い) */
  leasehold_percentage?: number

  /**
   * 土地面積
   * @format float
   * @example 80.12
   */
  area: number

  /**
   * 土地面積(坪)
   * 小数点第三位では四捨五入しています。
   * @format float
   * @example 24.27
   */
  tsubo?: number
  shape?: LandShape

  /**
   * セットバック面積
   * @format float
   * @example 0
   */
  setback_area?: number
  corner?: LandCorner
  area_classification?: AreaClassification
  use_district?: UseDistrictCode

  /** 建蔽率 */
  building_coverage_ratio?: number

  /** 容積率(指定) */
  specified_floor_area_ratio?: number

  /** 容積率(基準) */
  standard_floor_area_ratio?: number
  fire_prevention_law?: FirePreventionLaw

  /** その他法規制 */
  etc_law?: string

  /**
   * ２つめの用途地域を指します。
   * これは、一棟不動産を査定する手法を実装する上で追加されたものです。
   */
  secondary_use_district?: UseDistrictCode

  /**
   * ２つめの建蔽率を指します。
   * これは、一棟不動産を査定する手法を実装する上で追加されたものです。
   * @example 70
   */
  secondary_building_coverage_ratio?: number

  /**
   * ２つめの指定容積率(指定)を指します。
   * これは、一棟不動産を査定する手法を実装する上で追加されたものです。
   * @example 250
   */
  secondary_specified_floor_area_ratio?: number

  /**
   * ２つめの消防法を指します。
   * これは、一棟不動産を査定する手法を実装する上で追加されたものです。
   */
  secondary_fire_prevention_law?: FirePreventionLaw

  /**
   * ２つめのその他法規制を指します。
   * これは、一棟不動産を査定する手法を実装する上で追加されたものです。
   * @example 防火地域
   */
  secondary_etc_law?: string

  /** 備考(土地) */
  remarks?: string
  connecting_road1_direction?: Direction
  connecting_road1_type?: RoadType

  /**
   * 幅員(m)
   * @format float
   */
  connecting_road1_road_width?: number

  /**
   * 間口(m)
   * @format float
   */
  connecting_road1_front_width?: number
  connecting_road2_direction?: Direction
  connecting_road2_type?: RoadType

  /**
   * 幅員(m)
   * @format float
   */
  connecting_road2_road_width?: number

  /**
   * 間口(m)
   * @format float
   */
  connecting_road2_front_width?: number
  connecting_road3_direction?: Direction
  connecting_road3_type?: RoadType

  /**
   * 幅員(m)
   * @format float
   */
  connecting_road3_road_width?: number

  /**
   * 間口(m)
   * @format float
   */
  connecting_road3_front_width?: number
  connecting_road4_direction?: Direction
  connecting_road4_type?: RoadType

  /**
   * 幅員(m)
   * @format float
   */
  connecting_road4_road_width?: number

  /**
   * 間口(m)
   * @format float
   */
  connecting_road4_front_width?: number

  /**
   * 旗竿地補正率
   * @format float
   */
  hzt_correction_rate: number

  /**
   * 奥行
   * @format float
   */
  alley_depth?: number

  /**
   * 有効宅地の価値率
   * @format float
   */
  valid_land_value_rate?: number

  /**
   * 路地状部分の価値率
   * @format float
   */
  invalid_land_value_rate?: number

  /**
   * 有効宅地割合(%)
   * @format float
   */
  valid_land_percentage?: number

  /**
   * 路地状部分割合(%)
   * @format float
   */
  invalid_land_percentage?: number

  /**
   * 地番
   * @example 6番85
   */
  parcel_number?: string
  additional_routes: AdditionalRoute[]

  /**
   * 地目
   * @example 宅地
   */
  land_category?: string
}

/**
 * 建物のドメインモデルです。
 */
export interface Building {
  construction_type?: BuildingConstructionType

  /**
   * 階数（地上）
   * @example 32
   */
  ground_floor_number: number

  /**
   * 階数(地下)
   * @example 1
   */
  basement_floor_number?: number

  /**
   * @format float
   * @example 120.12
   */
  area: number

  /**
   * 建物面積(坪)
   * 小数点第三位では四捨五入しています。
   * @format float
   * @example 36.37
   */
  tsubo?: number

  /**
   * 間取り
   * @example 3LDK
   */
  layout?: string

  /**
   * 築年月日(年)
   * @example 2010
   */
  built_year: number

  /**
   * 築年月日(月)
   * @example 4
   */
  built_month: number
  current_state?: CurrentState

  /**
   * 施工会社
   * @example 大成建設株式会社
   */
  construct_company_name?: string

  /** 備考(建物) */
  remarks?: string

  /** 建築単価(万円/坪) */
  tsubo_price?: number

  /** 残価率(%) */
  residual_value_percentage?: number

  /** 耐用年数(年) */
  durable_years?: number

  /** 築年数 */
  age?: number

  /** 建物価格 */
  price: number

  /** 付加箇所1 名称 */
  added_part1_name?: string

  /** 付加箇所1 コメント */
  added_part1_comment?: string

  /** 付加箇所1 価格 */
  added_part1_price?: number

  /** 付加箇所2 名称 */
  added_part2_name?: string

  /** 付加箇所2 コメント */
  added_part2_comment?: string

  /** 付加箇所2 価格 */
  added_part2_price?: number

  /** 付加箇所3 名称 */
  added_part3_name?: string

  /** 付加箇所3 コメント */
  added_part3_comment?: string

  /** 付加箇所3 価格 */
  added_part3_price?: number

  /** 減価率項目 */
  depreciation_rate_items: DepreciationRateItem[]

  /** 減価率を一律にするか項目別にするかのフラグです。 */
  enabled_depreciation_rate_items: boolean

  /**
   * 減価率
   * @format float
   * @example 1.5
   */
  depreciation_rate: number

  /** 新築想定価格 */
  price_as_new: number

  /** 残価額 */
  residual_value: number

  /** 減価額 */
  depreciation_price: number

  /** 付加部位価格 */
  added_parts_price: number

  /**
   * 家屋番号
   * 登記簿藤本に記載されている家屋番号を指します。
   * @example 6番85
   */
  building_number?: string

  /**
   * 種類用途
   * @example 住居
   * 事務所
   * 工場
   * など、建物の用途を表します。（自由入力）
   */
  building_use_type?: string

  /**
   * 建物の種別を示します。利用可能な値は以下の通りです。
   * - unknown: 不明
   * - apartment: アパート
   * - condominium: マンション
   * - office_building: 事務所ビル
   */
  building_type?: BuildingType

  /**
   * 日本語名では貸床面積または総賃貸面積となります。
   * 建物全体の延床面積のうち、賃貸に出している面積の合計値（㎡）
   * 一棟不動産を査定する場合に使用されます。
   * @example 150.51
   */
  rentable_floor_area?: number

  /**
   * 貸床面積からおおよその総戸数を算出するための平均賃貸面積（㎡）
   * 一棟不動産を査定する場合に使用されます。
   * @example 20.01
   */
  average_rental_area?: number

  /**
   * 建物内の総戸数
   * @example 10
   */
  total_units?: number

  /**
   * 現況賃料（月額）
   * 収益還元法（一棟）の場合のみ返却されます。
   *   target_typeがReportの場合
   *     - 実際賃料（月額）
   *     - nullを許容します。
   *   target_typeがStoryの場合
   *     - 取引賃料（月額・共益費込）
   *     - nullを許容しません。事例利回りと事例賃料を算出する際に必ず必要になります。
   *   Storyの場合以下注意
   *     v3_rent_sell_storiesから取得できるものは月額賃料です。（賃料事例）
   *     v3_investment_sell_storiesから取得されるものは年間収入を12で割ったものになります。（利回り事例）
   * @example 200000
   */
  monthly_rent_price?: number

  /**
   * 満室想定賃料（月額）
   * 建物内の貸床面積が全て賃貸された際に想定される潜在賃料の合計値（円）
   * @example 500000
   */
  potential_monthly_rent_price?: number

  /** 平均賃貸面積の選択オプション */
  average_rental_area_option?: 'calculate' | 'input'

  /**
   * 総賃貸面積についてのコメント
   * @example この建物の総賃貸面積は主にオフィススペースとして利用されています。
   */
  rentable_floor_area_comment?: string

  /**
   * 更新日時
   * 一棟査定にて使用します。
   * 平均賃貸面積（average_rental_area）を変更した場合に賃料比較事例を取得し直しますが、
   * その際、事例取得中に事例選択ページへの遷移ボタンをdisabledにする要件があります。
   * 事例取得に失敗した場合に時限的にdisabledを解除するため使用します。
   * updated_atからの経過時間が一定時間を超えた場合にdisabledを解除する仕様です。
   * @format date-time
   * @example 2025-01-20T11:44:02.080+09:00
   */
  updated_at: string
}

export interface DepreciationRateItem {
  /** ID */
  id: number

  /** 建物ID */
  building_id: number

  /** 部位種別 */
  category: DepreciationRateCategory

  /**
   * 構成比率(%)
   * @format float
   * @example 10
   */
  percentage: number

  /**
   * 耐用年数
   * @example 10
   */
  durable_years: number

  /**
   * 経過年数
   * @example 5
   */
  age: number

  /**
   * 減価率
   * @format float
   * @example 1.5
   */
  depreciation_rate: number

  /**
   * 作成日時
   * @format date-time
   * @example "2019-12-31T15:00:00.000Z"
   */
  created_at: string

  /**
   * 更新日時
   * @format date-time
   * @example "2019-12-31T15:00:00.000Z"
   */
  updated_at: string
  [key: string]: any
}

export interface Condo {
  /** 区分マンションID */
  id: number

  /** 建物名 */
  building_name?: string

  /** 建物ID(コラビット事例DB) */
  building_id?: number

  /**
   * 住所
   * @example 東京都中央区月島1-22-1
   */
  address?: string

  /**
   * 住所（表示用）
   * @example 渋谷区渋谷１丁目
   */
  address_for_display?: string

  /**
   * 緯度
   * @format double
   * @example 35.663829636289904
   */
  lat?: number

  /**
   * 経度
   * @format double
   * @example 139.78193518226857
   */
  lng?: number

  /** 沿線名 */
  route1_line_name?: string

  /** 沿線ID */
  route1_line_code?: number

  /** 駅名 */
  route1_station_name?: string

  /** 駅ID */
  route1_station_code?: number

  /** 駅徒歩 */
  route1_distance_to_station?: number

  /** バス（停徒歩） */
  route1_distance_to_busstop?: number

  /** バス（乗車時間） */
  route1_distance_to_station_by_bus?: number

  /** バス停名 */
  route1_busstop_name?: string
  construction_type?: CondoConstructionType

  /**
   * 総戸数
   * @example 520
   */
  residence_count?: number

  /**
   * 総階数（地上）
   * @example 32
   */
  ground_floor_number?: number

  /**
   * 総階数(地下)
   * @example 1
   */
  basement_floor_number?: number

  /**
   * 築年月日(年)
   * @example 2010
   */
  built_year: number

  /**
   * 築年月日(月)
   * @example 4
   */
  built_month: number

  /**
   * 築年
   * @example 12
   */
  age?: number
  ownership?: Ownership

  /**
   * 分譲会社
   * @example 三井不動産レジデンシャル株式会社、丸紅株式会社、大成建設株式会社
   */
  sale_company_name?: string

  /**
   * 施工会社
   * @example 大成建設株式会社
   */
  construct_company_name?: string

  /**
   * 設計会社
   * @example ㈱熊谷組
   */
  architect_company_name?: string

  /**
   * 管理会社
   * @example 三井不動産レジデンシャルサービス株式会社
   */
  management_company_name?: string
  management_form?: CondoManagementForm
  manager_state?: CondoManagementState

  /**
   * 備考(建物)
   * @example 建物に関するメモです。
   */
  building_remarks?: string

  /**
   * 部屋番号
   * @example 1211
   */
  room_name?: string

  /**
   * 所在階
   * @example 12
   */
  room_floor_number?: number

  /**
   * 部屋所在階の階層区分
   * 下記の3区分です。
   * 低層階
   * 中層階
   * 高層階
   * @example 中階層
   */
  room_floor_position?: string

  /**
   * 専有面積(平米)
   * @format float
   * @example 72.8
   */
  room_area: number

  /**
   * 専有面積(坪)
   * 小数点第三位では四捨五入しています。
   * @format float
   * @example 22.02
   */
  room_tsubo: number

  /**
   * バルコニー面積
   * @format float
   * @example 10.8
   */
  room_balcony_area?: number

  /**
   * 間取り
   * @example 3LDK
   */
  room_layout?: string
  room_direction?: Direction
  room_current_state?: CurrentState

  /**
   * 管理費(月額)
   * @example 7800
   */
  management_fee?: number

  /**
   * 修繕費(月額)
   * @example 7800
   */
  repairment_fee?: number

  /**
   * その他費用(月額)
   * @example 0
   */
  etc_fee?: number

  /**
   * 分譲価格(万円)
   * @example 4000
   */
  sale_price?: number

  /**
   * 備考(部屋)
   * @example 部屋に関するメモです。
   */
  room_remarks?: string

  /**
   * ルーフバルコニー
   * @example false
   */
  roof_balcony?: boolean

  /**
   * 専用庭
   * @example false
   */
  private_garden?: boolean

  /**
   * 角住戸
   * @example true
   */
  corner?: boolean

  /**
   * 収益還元法（区分）の場合のみ返却されます。
   *   target_typeがReportの場合
   *     - 実際賃料（月額）
   *     - nullを許容します。
   *   target_typeがStoryの場合
   *     - 取引賃料（月額・共益費込）
   *     - nullを許容しません。事例利回りと事例賃料を算出する際に必ず必要になります。
   *   Storyの場合以下注意
   *     v3_rent_sell_storiesから取得できるものは月額賃料です。（賃料事例）
   *     v3_investment_sell_storiesから取得されるものは年間収入を12で割ったものになります。（利回り事例）
   * @example 200000
   */
  monthly_rent_price?: number

  /**
   * 取引価格と賃料で計算されたものが返却されます。
   * 取引価格と賃料が更新されると再計算されます。
   * 計算式（月額賃料x12÷取引価格）
   * @format float
   * @example 4.8
   */
  yield_percentage?: number
}

/**
 * 帳票に紐づく事例データです。
 */
export interface Story {
  /** 事例ID */
  id?: number

  /** 査定書ID */
  report_id?: number

  /**
   * 不動産の種類を表します。
   * - house: 戸建
   * - land: 土地
   * - condo: 区分マンション
   * - area: エリアレポート
   * - speed_mode_house: 戸建（スピードモード）
   * - speed_mode_land: 土地（スピードモード）
   * - speed_mode_condo: 区分マンション（スピードモード）
   * - multi_unit_building: 一棟
   */
  category: ReportCategory

  /** 取引の種別 */
  transaction_type: StoryTransactionType

  /** 事例の発生源 */
  source_type?: StorySourceType

  /**
   * 事例の用途。利用可能な値は以下の通りです。
   * - unknown: 不明
   * - cost_approach_assessment: 原価法
   * - market_approach_assessment: 取引事例比較法
   * - sale_price_approach_assessment: 分譲価格比の査定
   * - competitor_analysis: 競合分析
   * - speed_mode_assessment: スピードモード査定
   * - income_approach_assessment_for_rent: 収益還元法(賃料算出)
   * - income_approach_assessment_for_investment: 収益還元法(利回算出)
   */
  use: StoryUse

  /**
   * 成約年月日(年)
   * @example 2010
   */
  traded_year?: number

  /**
   * 成約年月日(月)
   * @example 4
   */
  traded_month?: number

  /**
   * 金額(万円)
   * @example 5500
   */
  price: number

  /**
   * 賃料(円)
   * (収益還元法の場合のみ返却されます)
   * @example 200000
   */
  rent_price?: number

  /**
   * 利回り(%)
   * (収益還元法の場合のみ返却されます)
   * @format float
   * @example 4.8
   */
  yield_percentage?: number

  /**
   * 分譲価格比(%)
   * sale_price_percentage = price / sale_price
   * (分譲価格が未入力の場合はnull値が返却されます)
   * @example 100
   */
  sale_price_percentage?: number

  /**
   * 建物価格(万円)
   * @example 1500
   */
  building_price?: number

  /**
   * 土地価格(万円)
   * @example 4000
   */
  land_price?: number

  /**
   * 事例の類似度
   * @example 100
   */
  similarity_score?: number

  /** 評価ポイントデータ */
  score: Score

  /** 添付ファイル（S3のキー） */
  document_key?: string

  /** 添付ファイル（URL） */
  document_url?: string
  land?: Land

  /** 建物のドメインモデルです。 */
  building?: Building
  condo?: Condo

  /** 評価ポイント */
  point?: number

  /** 補正前の坪単価 */
  correction_target_price: number

  /** 補正率 */
  correction_rate: number

  /** 補正後の坪単価 */
  corrected_price: number

  /**
   * その他の補正率（score.etc）に対するコメントが入ります
   * @example 2022年8月にフルリノベーション済みです。
   */
  etc_point_comment: string

  /**
   * 「建物その他」の補正率（score.building_etc）に対するコメントが入ります
   * @example 対象不動産に比べて比較的高値で取引される傾向にあるため補正されます。
   */
  building_etc_point_comment: string

  /** 事例に紐づく画像のマスキングデータが入ります。 */
  image_masking?: ImageMasking
}

/**
 * 帳票に紐づく事例データです。
 */
export interface CondoStory {
  /** 事例ID */
  id: number

  /** 査定書ID */
  report_id: number

  /**
   * 不動産の種類を表します。
   * - house: 戸建
   * - land: 土地
   * - condo: 区分マンション
   * - area: エリアレポート
   * - speed_mode_house: 戸建（スピードモード）
   * - speed_mode_land: 土地（スピードモード）
   * - speed_mode_condo: 区分マンション（スピードモード）
   * - multi_unit_building: 一棟
   */
  category: ReportCategory

  /** 取引の種別 */
  transaction_type: StoryTransactionType

  /** 事例の発生源 */
  source_type: StorySourceType

  /**
   * 事例の用途。利用可能な値は以下の通りです。
   * - unknown: 不明
   * - cost_approach_assessment: 原価法
   * - market_approach_assessment: 取引事例比較法
   * - sale_price_approach_assessment: 分譲価格比の査定
   * - competitor_analysis: 競合分析
   * - speed_mode_assessment: スピードモード査定
   * - income_approach_assessment_for_rent: 収益還元法(賃料算出)
   * - income_approach_assessment_for_investment: 収益還元法(利回算出)
   */
  use: StoryUse

  /**
   * 成約年月日(年)
   * @example 2010
   */
  traded_year: number

  /**
   * 成約年月日(月)
   * @example 4
   */
  traded_month: number

  /**
   * 金額(万円)
   * @example 5500
   */
  price: number

  /**
   * 賃料(円)
   * (収益還元法の場合のみ返却されます)
   * @example 200000
   */
  rent_price?: number

  /**
   * 利回り(%)
   * (収益還元法の場合のみ返却されます)
   * @format float
   * @example 4.8
   */
  yield_percentage?: number

  /**
   * 分譲価格比(%)
   * sale_price_percentage = price / sale_price
   * (分譲価格が未入力の場合はnull値が返却されます)
   * @example 100
   */
  sale_price_percentage?: number

  /**
   * 事例の類似度
   * @example 100
   */
  similarity_score: number

  /** 評価ポイントデータ */
  score: Score

  /** 添付ファイル（S3のキー） */
  document_key: string

  /** 添付ファイル（URL） */
  document_url: string
  condo: Condo

  /** 評価ポイント */
  point: number

  /** 補正前の坪単価 */
  correction_target_price: number

  /**
   * 補正前の利回り
   * @example 4.78
   */
  correction_target_yield_percentage?: number

  /** 補正率 */
  correction_rate: number

  /** 補正後の坪単価 */
  corrected_price: number

  /**
   * 補正後の利回り
   * @format float
   * @example 4.8
   */
  corrected_yield_percentage?: number

  /**
   * その他の補正率（score.etc）に対するコメントが入ります
   * @example 2022年8月にフルリノベーション済みです。
   */
  etc_point_comment: string

  /**
   * 「建物その他」の補正率（score.building_etc）に対するコメントが入ります
   * @example 対象不動産に比べて比較的高値で取引される傾向にあるため補正されます。
   */
  building_etc_point_comment: string

  /** 事例に紐づく画像のマスキングデータが入ります。 */
  image_masking?: ImageMasking
}

export type StoryIdList = number[]

export interface PresignedUrl {
  /** S3 Key */
  key?: string

  /** 保存済みか */
  persisted?: boolean

  /** 添付ファイルのURL(GET) */
  get?: string

  /** 添付ファイルのURL(PUT) */
  put?: string
}

export interface Assessment {
  /** 査定ID */
  id?: number

  /**
   * '査定方法の種別。利用可能な値は以下の通りです。
   *   - cost: 原価法
   *   - market: 取引事例比較法
   *   - sale_price: 分譲価格比の査定
   *   - speed_mode: スピードモード査定
   *   - income: 収益還元法
   */
  category?: AssessmentCategory | null

  /**
   * 平均価格
   * (分譲価格比の査定ではnull値となります)
   */
  mean_price?: number

  /**
   * 加重平均価格
   * (分譲価格比の査定ではnull値となります)
   */
  weighted_mean_price?: number

  /**
   * 補正前土地価格（整形地の価格）
   * (戸建の場合のみ返却されます。また取引事例比較法の場合は0が返却されます。)
   */
  land_price?: number

  /**
   * 補正前マンション価格
   * (マンションの場合のみ返却されます。)
   */
  room_price?: number

  /**
   * 補正前建物価格
   * (戸建の場合のみ返却されます。)
   */
  building_price?: number

  /**
   * 補正前の建物価格。価格推定apiの結果を格納するカラムです。
   * 価格はapiで算出された数字の万円未満を切り捨てた値が入ります。
   * (スピードモードの戸建の場合のみ返却されます。)
   */
  speed_mode_building_price?: number

  /**
   * 補正前の土地価格。価格推定apiの結果を格納するカラムです。
   * 価格はapiで算出された数字の万円未満を切り捨てた値が入ります。
   * (スピードモードの土地の場合と戸建の場合に返却されます。)
   */
  speed_mode_land_price?: number

  /**
   * 補正前のマンション価格。価格推定apiの結果を格納するカラムです。
   * 価格はapiで算出された数字の万円未満を切り捨てた値が入ります。
   * (スピードモードのマンションの場合のみ返却されます。)
   */
  speed_mode_room_price?: number

  /**
   * 市場性調整率の補正前の価格(万円)
   * - 戸建 > 原価法: 土地価格(補正後の土地事例価格の平均)+建物価格
   * - 戸建 > 取引事例比較法の場合: 補正後の建物事例価格の平均
   * - 土地 > 取引事例比較法の場合: 補正後の土地事例価格の平均
   * - MS  > 取引事例比較法の場合: 補正後のMS事例価格の平均
   * - MS  > 分譲価格比査定の場合: 対象事例の分譲価格 * 事例分譲価格比の平均 / 100.0
   */
  price?: number

  /**
   * 市場性調整率(%)
   * @example 100
   */
  adjustment_percentage: number

  /**
   * その他補正率(%)
   * @example 100
   */
  other_correction_percentage?: number

  /**
   * その他補正率の価格
   * @example 100
   */
  other_correction_price?: number

  /**
   * 補正済価格(万円)
   * @example 4000
   */
  adjusted_price?: number

  /**
   * 調整後の査定利回り(%)
   * assessment_yield_percentageに市場性調整(adjustment_yield_percentage)を足した値が返却されます。
   * 収益還元法の査定の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   * @example 3.89
   */
  adjusted_yield_percentage?: number

  /** 選択事例 */
  stories: (Story | CondoStory | (Story & CondoStory))[]

  /** 市場性調整率コメント */
  comment?: string

  /** 査定方法を使用する場合はtrue */
  active: boolean

  /** 補正前土地価格（旗竿地補正率を加味した価格） */
  hzt_land_price?: number

  /**
   * 事例分譲価格比の平均(%)
   * (分譲価格比の査定以外は一律100.0となります)
   * @example 100
   */
  sale_price_percentage_mean?: number

  /**
   * 事例分譲価格比(%)
   * デフォルトは事例分譲価格比の平均となります。
   * (分譲価格比の査定以外は一律100.0となります)
   * @example 100
   */
  sale_price_percentage?: number

  /** リフォーム加算の価格 */
  renovation_price?: number

  /** その他補正項目 */
  other_correction_items?: OtherCorrectionItem[]

  /**
   * 価格推定APIの結果から取得される価格です。
   * スピードモードのとき以外では取得されません。
   */
  estimated_price?: number

  /**
   * 利回り調整項目の%(adjustment_yield_itemsのpercentage)の合計です。
   * 収益還元法の査定の場合、返却します。
   * それ以外の査定方法の場合はnull値が返却されます。
   * @example -0.15
   */
  adjustment_yield_percentage?: number

  /**
   * 簡易査定賃料（月額）
   * 価格推定APIの結果から取得される賃料です。
   * 収益還元法の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   * 編集可能です。
   * condo_income_estimator_responsesテーブルのカラムにに返却値は全て格納されます。
   * @example 200000
   */
  estimated_rent_price?: number

  /**
   * 事例比較賃料（月額）
   * 補正後比較事例の賃料の平均値が返却されます。
   * 収益還元法の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   * @example 150000
   */
  mean_rent_price?: number

  /**
   * 簡易査定利回り(%)
   * 価格推定APIの結果から取得される利回りです。
   * 収益還元法の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   * 編集可能です。
   * condo_income_estimator_responsesテーブルのカラムに返却値は全て格納されます。
   * @example 4.5
   */
  estimated_yield_percentage?: number

  /**
   * 事例比較利回り(%)
   * 補正後比較事例の利回りの平均値が返却されます。
   * 収益還元法の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   * @example 3.5
   */
  mean_yield_percentage?: number

  /** 賃料算出事例 */
  rent_stories?: CondoStory[]

  /** 利回り算出事例 */
  investment_stories?: CondoStory[]

  /**
   * 利回り調整項目
   * 収益還元法の査定の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   */
  adjustment_yield_items?: AdjustmentYieldItem[]

  /**
   * 査定価格を算出する際に採用する賃料を選択します。
   * 収益還元法の査定の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   * @example estimated
   */
  rent_price_type?: 'estimated' | 'mean' | 'manual'

  /**
   * 査定価格を算出する際に採用する利回りを選択します。
   * 収益還元法の査定の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   * @example estimated
   */
  yield_percentage_type?: 'estimated' | 'mean'

  /**
   * 査定価格を算出する際に採用する土地価格を選択します。
   * 一棟査定の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   * @example estimated
   */
  land_price_type?: 'mean' | 'tax_base_land_value'

  /**
   * 価格推定APIの結果から取得されるデータです。
   * 収益還元法の場合、返却されます。
   */
  condo_income_estimator_response?: { rent_price: number; gross_rate: number }

  /**
   * 価格推定APIの結果から取得されるデータです。
   * 一棟査定の収益還元法の場合、返却されます。
   */
  multi_unit_building_income_estimator_response?: {
    rent_price: number
    gross_rate: number
  }
}

/**
 * 競合分析データ
 */
export interface CompetitorAnalysis {
  /** 選択事例 */
  stories: (Story | CondoStory | (Story & CondoStory))[]
}

/**
 * 相場分析データ
 */
export interface MarketAnalysis {
  /** 選択事例 */
  posted_land_price_stories?: PostedLandPriceStory[]
}

/**
 * 評価ポイントデータ
 */
export interface Score {
  /**
   * @format float
   * @example 1.1
   */
  time?: number

  /**
   * @format float
   * @example 1.1
   */
  address?: number

  /**
   * @format float
   * @example 1.1
   */
  access?: number

  /**
   * @format float
   * @example 1.1
   */
  use?: number

  /**
   * @format float
   * @example 1.1
   */
  corner?: number

  /**
   * @format float
   * @example 1.1
   */
  road_direction?: number

  /**
   * @format float
   * @example 1.1
   */
  road_width?: number

  /**
   * @format float
   * @example 1.1
   */
  road_type?: number

  /**
   * @format float
   * @example 1.1
   */
  ownership?: number

  /**
   * @format float
   * @example 1.1
   */
  area?: number

  /**
   * @format float
   * @example 1.1
   */
  shape?: number

  /**
   * @format float
   * @example 1.1
   */
  setback?: number

  /**
   * @format float
   * @example 1.1
   */
  etc?: number

  /**
   * @format float
   * @example 1.1
   */
  age?: number

  /**
   * @format float
   * @example 1.1
   */
  building_etc?: number

  /**
   * @format float
   * @example 1.1
   */
  room?: number

  /**
   * @format float
   * @example 1.1
   */
  monthly_fee?: number

  /**
   * @format float
   * @example 1.1
   */
  room_etc?: number

  /**
   * @format float
   * @example 1.1
   */
  land_and_building?: number

  /**
   * @format float
   * @example 1.1
   */
  room_floor_number?: number

  /**
   * @format float
   * @example 1.1
   */
  room_position?: number
}

export interface PrintSettingInput {
  /** お客さま名 */
  customer_name?: string

  /** 表紙タイトル */
  title?: string

  /** 査定対象 */
  name?: string

  /**
   * 査定日
   * @format date
   */
  proposed_at?: string

  /** 会社名 */
  company_name?: string

  /** 部署・支店 */
  department_name?: string

  /** 担当者名 */
  staff_name?: string

  /** メールアドレス */
  email?: string

  /** 電話番号 */
  tel?: string

  /** FAX番号 */
  fax?: string

  /** 郵便番号 */
  zip?: string

  /** 住所 */
  address?: string

  /** 住所（建物名） */
  building_name?: string

  /** 帳票のカバーの色 */
  color?: PrintSettingColor

  /** 帳票のデザイン種別 */
  design_type?: PrintSettingDesignType

  /** 表示しない帳票セクション */
  excluded_pages_keys?: ExcludedPagesKey[]

  /** 表示しない帳票セクション */
  excluded_page_keys?: ExcludedPageKey[]

  /** カスタム帳票の名称 */
  custom_name?: CustomName
}

export interface PrintSetting {
  /** お客さま名 */
  customer_name: string

  /** 表紙タイトル */
  title: string

  /** 査定対象 */
  name: string

  /**
   * 査定日
   * @format date
   */
  proposed_at: string

  /** 会社名 */
  company_name: string

  /** 部署・支店 */
  department_name: string

  /** 担当者名 */
  staff_name?: string

  /** メールアドレス */
  email: string

  /** 電話番号 */
  tel: string

  /** FAX番号 */
  fax: string

  /** 郵便番号 */
  zip: string

  /** 住所 */
  address: string

  /** 住所（建物名） */
  building_name: string

  /** 帳票のカバーの色 */
  color: PrintSettingColor

  /** 帳票のデザイン */
  design_type: PrintSettingDesignType
  greeting_text: string
  pages: Page[]

  /** デフォルトで表示する帳票 */
  default_page_setting: DefaultPageSetting

  /** 表示しない帳票セクション */
  excluded_pages_keys: ExcludedPagesKey[]

  /** 表示しない帳票ページ */
  excluded_page_keys: ExcludedPageKey[]

  /** 表示順の設定 */
  custom_order_keys: ExcludedPagesKey[]

  /** カスタム帳票の名称 */
  custom_name: CustomName

  /** 携帯電話番号 */
  cell_phone_number: string

  /**
   * ページ番号の表示
   * true: 表示有
   * false: 表示なし
   * defaultは表示有りです。
   * @example true
   */
  visible_page_number: boolean

  /** 敬称の接尾語 */
  honorific_suffix: HonorificSuffix
}

export interface PrintSettingPage {
  key: string
  label: string
}

/**
 * デフォルトで表示する帳票
 */
export type DefaultPageSetting = Record<string, PrintSettingPage[]>

/**
 * デフォルトで表示する帳票
 */
export interface Page {
  /** 帳票のセクションキー */
  section_key: ExcludedPagesKey

  /**
   * 帳票のページキー
   * @example cover_page
   */
  page_key: string

  /**
   * 帳票のラベル(表示名)
   * @example 表紙
   */
  label: string

  /**
   * 表示するかどうか
   * @example true
   */
  visible: boolean
}

export interface Station {
  /**
   * 駅名
   * @example 品川駅
   */
  station_name?: string

  /**
   * 駅コード（グループコード）
   * @example 100202
   */
  station_g_cd?: number

  /**
   * 駅コード
   * @example 100202
   */
  station_cd?: number

  /**
   * 緯度
   * @format double
   * @example 35.663829636289904
   */
  lat?: number

  /**
   * 経度
   * @format double
   * @example 139.78193518226857
   */
  lng?: number

  /**
   * 沿線名
   * @example 山手線
   */
  line_name?: string

  /**
   * 路線コード
   * @example 11302
   */
  line_cd?: number

  /**
   * 徒歩分数
   * @example 5
   */
  walk_minutes?: number
}

export interface Line {
  id?: number

  /**
   * 路線名
   * @example 山手線
   */
  line_name?: string

  /**
   * 路線コード
   * @example 11302
   */
  line_cd?: number
}

export interface DetailedDBBuilding {
  /** @example 12345 */
  building_id?: number

  /** @example ミッドタワーグランド */
  name?: string

  /** @example 東京都荒川区南千住８丁目 */
  address?: string

  /** @example １７－７ */
  other_address?: string

  /**
   * 緯度
   * @format double
   * @example 35.64386806000005
   */
  lat?: number

  /**
   * 経度
   * @format double
   * @example 139.7543469000001
   */
  lng?: number

  /** @example 32 */
  floor_number?: number

  /** @example 1 */
  basement_floor_number?: number

  /** @example 1 */
  built_at_year?: number

  /** @example 1 */
  built_at_month?: number

  /** @example 12345 */
  age?: number

  /** @example 363 */
  residence_count?: number
  route1_line_code?: number
  route1_line_name?: string
  route1_station_code?: number
  route1_station_name?: string

  /** @example 3 */
  route1_distance_to_station?: number
  ownership?: Ownership

  /** @example 鹿島建設,西台産業 */
  sale_company_name?: string

  /** @example 三井住友建設㈱ */
  construct_company_name?: string

  /** @example ㈱熊谷組 */
  architect_company_name?: string

  /** @example 日本ハウジング */
  management_company_name?: string
}

export interface DBBuilding {
  /** @example 13118007008 */
  area_cd?: string

  /** @example 東京都荒川区南千住８丁目 */
  address?: string

  /** @example 12345 */
  building_id?: number

  /** @example ミッドタワーグランド */
  name?: string

  /** @example 2020 */
  built_at_year?: number

  /** @example 32 */
  floor_number?: number

  /** @example 1132004 */
  station_cd?: number

  /** @example 11320 */
  line_cd?: number

  /** @example 南千住 */
  station_name?: string
}

export interface DetailedDBRoom {
  name?: string
  floor_number?: number
  m2?: number
  balcony_m2?: number
  room_layout?: string
  monthly_management_fee?: number
  monthly_repair_fee?: number
  price?: number
  has_roof_balcony?: boolean
  has_garden?: boolean
  kado?: boolean
}

export interface DBRoom {
  name?: string
  m2?: number
  floor_number?: number

  /**
   * 表示用の文字列です。
   * @example 101(75.33 ㎡)
   */
  display_name?: string
}

export interface AdminCompany {
  /**
   * 企業ID
   * @example 123
   */
  id: number

  /**
   * 企業名
   * @example 株式会社コラビット
   */
  name: string

  /**
   * 店舗名
   * 店舗管理機能を利用していない場合はこちらで店舗名(部署・支店名)を入力します。
   * @example 田町支店
   */
  department_name?: string

  /**
   * 選択中のプラン名
   * @example エントリープラン
   */
  plan_name: string

  /**
   * 選択中のプランの査定可能回数
   * @example 20
   */
  plan_monthly_reports_count: number

  /**
   * 当月の査定回数(削除含む)
   * @example 123
   */
  reports_count_this_month: number

  /**
   * 決済ステータス
   * active: 課金状態
   * inactive: 非課金状態(紐づくuserはログイン時に認証ができなくなります。)
   *
   * auth0のDBとの連動はしていません。
   * 手動運用時(~202301)まではauth0をブロックする運用を行っていました。
   * しかし202302からはアプリケーションのDB上の属性だけで認証を制御するようにしています。
   */
  status: PaymentStatus

  /**
   * 利用開始日
   * @example "2024-10-11T00:00:00.000Z"
   */
  activated_at: string

  /**
   * 企業に紐づく店舗数
   * @example 5
   */
  offices_count: number
}

export interface AdminCompanyDetail {
  id: number
  name: string
  address: string
  tel?: string
  zip?: string
  fax?: string
  department_name?: string
  license_number?: string
  memo?: string
  custom_name?: string

  /**
   * 決済情報を管理するモデルです。
   * planに1x1でひも付き、companiesと1*nの関係でひも付きます。
   * ただしcompanyモデルから参照される実装を想定しているのでcompaniesの属性は持たない設計にしています。
   */
  payment: Payment
  users: User[]
  invoices: {
    id: number
    target_year: number
    target_month: number
    plan_name?: string
    monthly_reports_count?: number
    reports_count?: number
    price?: number
    memo?: string
  }[]
  offices_count: number

  /** @example 123e4567-e89b-12d3-a456-426614174000 */
  howma_company_id: string
}

export interface AdminCompanyEdit {
  id: number
  name: string
  address: string
  tel?: string
  zip?: string
  fax?: string
  department_name?: string
  license_number?: string
  memo?: string
  custom_name?: string
}

export interface AdminProductStatistic {
  /**
   * 累計発行アカウント数
   * @example 1234
   */
  companies_count: number

  /**
   * 利用中アカウント数
   * @example 1000
   */
  active_companies_count: number

  /**
   * 解約（停止）アカウント数
   * @example 10
   */
  canceled_companies_count: number

  /**
   * アカウント毎の査定書作成回数
   * @example 10.12
   */
  reports_count_average: number
}

export interface AdminReport {
  /** 査定書ID */
  id: number

  /** ユーザーID */
  user_id: number

  /** 企業ID */
  company_id: number

  /**
   * 不動産の種類を表します。
   * - house: 戸建
   * - land: 土地
   * - condo: 区分マンション
   * - area: エリアレポート
   * - speed_mode_house: 戸建（スピードモード）
   * - speed_mode_land: 土地（スピードモード）
   * - speed_mode_condo: 区分マンション（スピードモード）
   * - multi_unit_building: 一棟
   */
  category: ReportCategory
  created_at: string
  updated_at: string

  /**
   * 原価法及び取引事例比較法を元に、手動で入力された査定価格が入ります。
   * @example 8888
   */
  assessment_price: number

  /** 査定書の印刷可能フラグ */
  printable: boolean
}

export interface AdminAnnouncement {
  id: number

  /**
   * お知らせの内容を記載します。
   * この内容は、お知らせ一覧画面に表示されます。
   * 入力必須項目です。
   * @example 「収益還元法」の査定書作成が可能になりました。
   */
  message: string

  /**
   * お知らせのURLを記載します。
   * このURLは、お知らせ一覧画面に表示されます。
   * 入力必須項目です。
   * @example https://collab-it.net/
   */
  url: string

  /**
   * お知らせの日付を記載します。
   * この日付は、お知らせ一覧画面に表示されます。
   * 登録日以降にTOP画面の一覧に表示されるようになります。
   * 入力必須項目です。
   * @format date-time
   * @example 2022-01-01T10:30
   */
  posted_at: string

  /**
   * お知らせを登録したユーザー情報を記載します。
   * この情報は、お知らせ一覧画面に表示されます。
   * この情報は、お知らせの投稿者を表示する目的です。
   */
  user?: User
}

export interface BRaiNProjectDocument {
  /** @example 44946 */
  id: string

  /** @example シエリア秋葉原EAST */
  name: string
  document: {
    pdfs: { fileName: string; signedUrl: string }[]
    isPriceListExportable: boolean
  }
}

export interface BRaiNProjectSignedUrl {
  /** @example https://brain~ */
  signedUrl: string
}

export interface DBUseDistrict {
  /**
   * 市区町村コード(5桁)
   * @example 13101
   */
  city_code: string
  code: UseDistrictCode

  /**
   * 建ぺい率(%)
   * @example 80
   */
  building_coverage: number

  /**
   * 容積率(%)
   * @example 500
   */
  floor_area_ratio: number

  /**
   * 指定した緯度経度が含まれている地域かどうか
   * @example false
   */
  is_target: boolean
}

export interface DBUrbanArea {
  area_classification: AreaClassification

  /** 対象事例が該当の都市地域にふくまれているかどうか */
  is_target: boolean
}

export interface HouseSellStoriesStatistic {
  city_area_mean: number
  city_price_mean: number
  city_year_data: { label: string; count: number; price_mean: number }[]
  city_area_data: { label: string; count: number }[]
  city_price_data: { label: string; count: number }[]
}

export interface LandSellStoriesStatistic {
  city_area_mean: number
  city_price_mean: number
  city_tsubo_price_mean: number
  city_year_data: { label: string; count: number; tsubo_price_mean: number }[]
  city_area_data: { label: string; count: number }[]
  city_price_data: { label: string; count: number }[]
}

export interface CondoSellStoriesStatistic {
  city_area_mean: number
  city_price_mean: number
  city_tsubo_price_mean: number
  city_year_data: { label: string; count: number; tsubo_price_mean: number }[]
  city_area_data: { label: string; count: number }[]
  city_price_data: { label: string; count: number }[]

  /** 売却期間ごとの件数 */
  city_sales_period_data: { label: string; count: number }[]

  /**
   * 売却期間の中央値(日)
   * @example 76
   */
  city_sales_period_median: number

  /** 価格改定数ごとの件数 */
  city_price_change_count_data: { label: string; count: number }[]

  /**
   * 平均価格改定数
   * @example 2.5
   */
  city_price_change_count_mean: number

  /** 価格改定率ごとの件数 */
  city_price_change_rate_data: { label: string; count: number }[]

  /**
   * 平均価格改定率
   * @example 0.051
   */
  city_price_change_rate_mean: number
  building_area_mean?: number
  building_price_mean?: number
  building_tsubo_price_mean?: number
  building_year_data: {
    label: string
    count: number
    tsubo_price_mean: number
  }[]
  building_area_data: { label?: string; count?: number }[]
  building_price_data: { label: string; count: number }[]
  building_stories: {
    ad_start_at: string
    ad_end_at: string
    selling: boolean
    floor_number?: number
    area: number
    layout: string
    open_price: number
    open_tsubo_price: number
    close_price: number
    close_tsubo_price: number
  }[]
}

export interface Spot {
  category: SpotCategory
  name: string
  walk_distance: number
  walk_minutes: number
  address: string
  lat: number
  lng: number
  spot_type: string
}

export interface TransportNode {
  /**
   * レコードid
   * @example 120607537
   */
  id: number

  /**
   * 駅/バス停の交通機関会社名
   * @example 東京都交通局
   */
  company_name?: string

  /**
   * 駅/バス停の路線名
   * @example 都営大江戸線（環状部）
   */
  line_name?: string

  /**
   * 駅/バス停の名称
   * @example 綾瀬
   */
  name?: string

  /**
   * 駅/バス停の名称の読み仮名
   * @example あやせ
   */
  ruby?: string

  /**
   * ノードタイプの一覧
   * @example ["station"]
   */
  types?: TransportNodeType[]

  /**
   * 住所の表示名
   * @example 東京都
   */
  address_name?: string

  /**
   * 駅/バス停の中心緯度
   * @example 35.762031
   */
  lat: number

  /**
   * 駅/バス停の中心経度
   * @example 139.823465
   */
  lng: number

  /**
   * 出発地点緯度経度からの距離(m)
   * @example 346
   */
  distance?: number

  /**
   * 所要時間(分)
   * @example 4
   */
  time?: number

  /** ルート検索（徒歩）結果の地理データ(Geojson) */
  shape?: {
    type?: string
    features?: {
      type?: string
      bbox?: number[]
      geometry?: { type?: string; coordinates?: number[][] }
      properties?: object
    }[]
    bbox?: number[]
  }
}

export interface TransportLink {
  id: number
  types: ('train' | 'bus')[]
  line_name: string
  stations: { id: string; station_name: string; is_nearest_station: boolean }[]
}

export interface UseDistrict {
  id?: number
  code?: UseDistrictCode
  city_code?: number
  building_coverage?: number
  floor_area_ratio?: number
  is_target?: boolean
  polygon?: number[][][]
}

/**
 * 都市地域データ
 */
export interface UrbanArea {
  /** 都市地域データID */
  id: number
  area_classification: AreaClassification

  /** 対象事例が該当の都市地域にふくまれているかどうか */
  is_target: boolean

  /** ポリゴンデータ */
  polygon: number[][][]
}

export interface CrowdCity {
  /**
   * 地区世帯数
   * @example 1234
   */
  number_households: number

  /**
   * 建物総棟数
   * @example 1234
   */
  building_number: number

  /**
   * 住所
   * @example 荒川区 荒川４丁目地区
   */
  address: string

  /**
   * 直線距離
   * @example 512
   */
  distance: number

  /** 地理データ(Polygon) */
  shape: number[][][]
}

export interface SedimentDisasterHazardArea {
  line: {
    remarks: string
    sediment_disasters_prone_area_cd: number
    line?: number[][]
  }[]
  point: {
    remarks: string
    sediment_disasters_prone_area_cd: number
    point?: number[]
  }[]
  surface: {
    remarks: string
    sediment_disasters_prone_area_cd: number
    surface: number[][][]
  }[]
}

export interface ExpectedTsunamiInundatedArea {
  /** 都道府県名 */
  prefecture_name: string

  /** 範囲 */
  bounds: number[][][]

  /** 都道府県コード */
  prefecture_code: string

  /** 津波浸水深の区分 */
  classification_of_water_depth: string

  /** 対象地域がどうか */
  is_target: boolean
  level: WaterDepthLevel
}

export interface AreasExpectedToBeFloodedByStormSurge {
  /** 範囲 */
  bounds: number[][][]

  /** 高潮浸水深の区分 */
  classification_of_water_depth: string

  /** 対象地域がどうか */
  is_target: boolean
  level: WaterDepthLevel
}

export interface InundationHazardousArea {
  /** 都道府県コード(国管理の場合は81-89の番号、都道府県管理の場合は、1-47の番号が入る) */
  prefecture_code: number

  /** 河川名 */
  river_name: string

  /** 津波浸水深の区分 */
  classification_of_water_depth: number

  /** 対象地域がどうか */
  is_target: boolean
  level: WaterDepthLevel

  /** 範囲 */
  bounds: number[][][]
}

export interface JapanSeismicHazardFault {
  /**
   * 断層コード
   * https://www.j-shis.bosai.go.jp/map/JSHIS2/data/DOC/DataFileRule/A-RULES.pdf
   * 上記URLの93Pに記載有
   */
  code: string

  /** 断層の名称 */
  name: string

  /** 断層への距離距離(m) */
  distance?: number

  /** ポリゴンデータ */
  shape: number[][][]
}

export interface JapanSeismicHazardMesh {
  /** 250mメッシュコード10桁 */
  meshcode: number

  /** 微地形分類コード */
  code: number

  /** 微地形分類名称 */
  name: string

  /** 工学的基盤 (Vs=400m/s)から地表に至る最大速度の増幅率 */
  amplification_factor: number

  /** 30年間で震度5弱以上となる確率 */
  probability_of_exceedance_ijma_gte_5_lower_within_30_years: number

  /** 30年間で震度5強以上となる確率 */
  probability_of_exceedance_ijma_gte_5_upper_within_30_years: number

  /** 30年間で震度6弱以上となる確率 */
  probability_of_exceedance_ijma_gte_6_lower_within_30_years: number

  /** 30年間で震度6強以上となる確率 */
  probability_of_exceedance_ijma_gte_6_upper_within_30_years: number

  /**
   * 液状化の可能性
   * https://docs.google.com/spreadsheets/d/1wpmD0jHFm4yN49g8degmkQyqdPX_wuk_o676O216p4g/edit#gid=1516355427
   */
  probability_of_liquefaction?: JapanSeismicHazardMeshProbabilityOfLiquefactionType

  /** ポリゴンデータ */
  shape: number[][][]

  /** 対象地かどうか */
  is_target: boolean
}

export interface CondoSellStory {
  area_m2?: number
  price?: number
  age?: number
  lat?: number
  lng?: number
  building_id?: number
}

export interface HouseSellStory {
  building_area_m2?: number
  price?: number
  age?: number
}

export interface LandSellStory {
  land_area_m2?: number
  price?: number
  connect_road_width1?: number
}

export interface CondoStatisticArea {
  /**
   * MS地域統計量ID
   * @example 123
   */
  id: number

  /**
   * 査定書ID
   * @example 123
   */
  report_id: number

  /**
   * 販売中の物件数
   * 査定書のAI補正の仕組み（事例収集）のシートでポリゴンの色分けのために使用
   *
   * 参考実装箇所
   * https://github.com/collabit-inc/ai-satei-pro-frontend-print/blob/c861c07f66433942e72ddbdaf84735e753584b38/src/components/reports/feature/StoriesDistributionPage/StoriesDistributionMap/index.tsx#L87
   */
  count: number

  /**
   * 不明。現在は未使用。
   * 一律 default値の0を採用してレコードが作成されている。
   * おそらくcorrect_rateとの関連があると思われるが詳細は不明。
   * @example 0
   */
  error_rate?: number

  /**
   * 地域名
   * 都道府県名、市区町村名、町丁目名、丁目をハイフン区切りで連結したもの
   * @example 東京都-中央区-月島-１丁目
   */
  name: string

  /**
   * 対象地(is_target=true)と比較値(is_target=false)の地域補正値(area_parameter)と地域標準価格(standard_price)をかけ合わせた値の比率。
   *
   * 具体的な計算式は下記の通り。
   * 比較地の値 = 比較地の地域補正値(area_parameter) * 比較地の地域標準価格(standard_price)
   * 対象地の値 = 対象地の地域補正値(area_parameter) * 対象地の地域標準価格(standard_price)
   * correction_rate = 比較地の値 / 対象地の値
   * @example 1
   */
  correction_rate: number

  /**
   * GeoJSON形式のPolygonの座標情報
   * この値はAPIのレスポンスとして返却されませんがDBには保存されています。
   * @example {"type":"MultiPolygon","coordinates":[[[[139.782882589,35.663024796],[139.782409643,35.662687819],[139.781880673,35.663171751],[139.781432697,35.663584693],[139.78100772,35.663987636],[139.780572743,35.66439158],[139.780363753,35.664583553],[139.780133765,35.664794523],[139.780320742,35.664994506],[139.780521716,35.665210489],[139.780952663,35.66567145],[139.781749565,35.666506382],[139.781753562,35.666579374],[139.781842535,35.667124317],[139.782275513,35.666732372],[139.78292848,35.666114458],[139.782959478,35.666086462],[139.783851429,35.665293575],[139.783863428,35.665282576],[139.784800387,35.664412699],[139.784810386,35.6644037],[139.784407422,35.66411472],[139.784247437,35.663997728],[139.78413345,35.663914734],[139.784108452,35.663896735],[139.783984466,35.663807741],[139.783974467,35.663800742],[139.783832483,35.663700749],[139.783827484,35.663697749],[139.783696498,35.663604755],[139.783692499,35.663601756],[139.78341453,35.663403769],[139.783128562,35.663199784],[139.782981578,35.663093791],[139.782882589,35.663024796],[139.782882589,35.663024796]]]]}
   */
  shape: any[]

  /**
   * 地域の中心点の緯度
   * @example 35.664792
   */
  lat: number

  /**
   * 地域の中心点の経度
   * @example 139.782254
   */
  lng: number

  /**
   * 対象地かどうかのフラグ
   * 対象地の場合はtrue、比較地の場合はfalse
   * @example true
   */
  is_target: boolean

  /**
   * レコード作成日時
   * @format date-time
   * @example "2021-01-01T00:00:00.000Z"
   */
  created_at: string

  /**
   * レコード更新日時
   * @format date-time
   * @example "2021-01-01T00:00:00.000Z"
   */
  updated_at: string

  /**
   * 地域詳細名
   * 町丁目名、丁目を結合したもの
   * 例: 芝浦1丁目, 芝浦2丁目
   * (この値はAPIのレスポンスとして返却されます。)
   */
  area_detail_name: string

  /**
   * Polygonの座標情報
   * (この値はAPIのレスポンスとして返却されます。)
   */
  polygon: number[][][]
}

export interface HouseStatisticArea {
  /**
   * 土地地域統計量ID
   * @example 123
   */
  id: number

  /**
   * 査定書ID
   * @example 123
   */
  report_id: number

  /**
   * 販売中の物件数
   * 査定書のAI補正の仕組み（事例収集）のシートでポリゴンの色分けのために使用
   *
   * 参考実装箇所
   * https://github.com/collabit-inc/ai-satei-pro-frontend-print/blob/c861c07f66433942e72ddbdaf84735e753584b38/src/components/reports/feature/StoriesDistributionPage/StoriesDistributionMap/index.tsx#L87
   */
  count: number

  /**
   * 不明。現在は未使用。
   * 一律 default値の0を採用してレコードが作成されている。
   * おそらくcorrect_rateとの関連があると思われるが詳細は不明。
   * @example 0
   */
  error_rate?: number

  /**
   * 地域名
   * 都道府県名、市区町村名、町丁目名、丁目をハイフン区切りで連結したもの
   * @example 東京都-中央区-月島-１丁目
   */
  name: string

  /**
   * 対象地(is_target=true)と比較値(is_target=false)の事例補正値(story_parameter)と加重平均した公示地価(weighted_average)をかけ合わせた値の比率。
   *
   * 具体的な計算式は下記の通り。
   * 比較地の値 = 比較地の地域補正値(story_parameter) * 比較地の地域標準価格(weighted_average)
   * 対象地の値 = 対象地の地域補正値(story_parameter) * 対象地の地域標準価格(weighted_average)
   * correction_rate = 比較地の値 / 対象地の値
   * @example 1
   */
  correction_rate: number

  /**
   * GeoJSON形式のPolygonの座標情報
   * この値はAPIのレスポンスとして返却されませんがDBには保存されています。
   * @example {"type":"MultiPolygon","coordinates":[[[[139.782882589,35.663024796],[139.782409643,35.662687819],[139.781880673,35.663171751],[139.781432697,35.663584693],[139.78100772,35.663987636],[139.780572743,35.66439158],[139.780363753,35.664583553],[139.780133765,35.664794523],[139.780320742,35.664994506],[139.780521716,35.665210489],[139.780952663,35.66567145],[139.781749565,35.666506382],[139.781753562,35.666579374],[139.781842535,35.667124317],[139.782275513,35.666732372],[139.78292848,35.666114458],[139.782959478,35.666086462],[139.783851429,35.665293575],[139.783863428,35.665282576],[139.784800387,35.664412699],[139.784810386,35.6644037],[139.784407422,35.66411472],[139.784247437,35.663997728],[139.78413345,35.663914734],[139.784108452,35.663896735],[139.783984466,35.663807741],[139.783974467,35.663800742],[139.783832483,35.663700749],[139.783827484,35.663697749],[139.783696498,35.663604755],[139.783692499,35.663601756],[139.78341453,35.663403769],[139.783128562,35.663199784],[139.782981578,35.663093791],[139.782882589,35.663024796],[139.782882589,35.663024796]]]]}
   */
  shape: any[]

  /**
   * 地域の中心点の緯度
   * @example 35.664792
   */
  lat: number

  /**
   * 地域の中心点の経度
   * @example 139.782254
   */
  lng: number

  /**
   * 対象地かどうかのフラグ
   * 対象地の場合はtrue、比較地の場合はfalse
   * @example true
   */
  is_target: boolean

  /**
   * レコード作成日時
   * @format date-time
   * @example "2021-01-01T00:00:00.000Z"
   */
  created_at: string

  /**
   * レコード更新日時
   * @format date-time
   * @example "2021-01-01T00:00:00.000Z"
   */
  updated_at: string

  /**
   * 地域詳細名
   * 町丁目名、丁目を結合したもの
   * 例: 芝浦1丁目, 芝浦2丁目
   * (この値はAPIのレスポンスとして返却されます。)
   */
  area_detail_name: string

  /**
   * Polygonの座標情報
   * (この値はAPIのレスポンスとして返却されます。)
   */
  polygon: number[][][]
}

export interface LandStatisticArea {
  /**
   * 土地地域統計量ID
   * @example 123
   */
  id: number

  /**
   * 査定書ID
   * @example 123
   */
  report_id: number

  /**
   * 販売中の物件数
   * 査定書のAI補正の仕組み（事例収集）のシートでポリゴンの色分けのために使用
   *
   * 参考実装箇所
   * https://github.com/collabit-inc/ai-satei-pro-frontend-print/blob/c861c07f66433942e72ddbdaf84735e753584b38/src/components/reports/feature/StoriesDistributionPage/StoriesDistributionMap/index.tsx#L87
   */
  count: number

  /**
   * 不明。現在は未使用。
   * 一律 default値の0を採用してレコードが作成されている。
   * おそらくcorrect_rateとの関連があると思われるが詳細は不明。
   * @example 0
   */
  error_rate?: number

  /**
   * 地域名
   * 都道府県名、市区町村名、町丁目名、丁目をハイフン区切りで連結したもの
   * @example 東京都-中央区-月島-１丁目
   */
  name: string

  /**
   * 対象地(is_target=true)と比較値(is_target=false)の事例補正値(story_parameter)と加重平均した公示地価(weighted_average)をかけ合わせた値の比率。
   *
   * 具体的な計算式は下記の通り。
   * 比較地の値 = 比較地の地域補正値(story_parameter) * 比較地の地域標準価格(weighted_average)
   * 対象地の値 = 対象地の地域補正値(story_parameter) * 対象地の地域標準価格(weighted_average)
   * correction_rate = 比較地の値 / 対象地の値
   * @example 1
   */
  correction_rate: number

  /**
   * GeoJSON形式のPolygonの座標情報
   * この値はAPIのレスポンスとして返却されませんがDBには保存されています。
   * @example {"type":"MultiPolygon","coordinates":[[[[139.782882589,35.663024796],[139.782409643,35.662687819],[139.781880673,35.663171751],[139.781432697,35.663584693],[139.78100772,35.663987636],[139.780572743,35.66439158],[139.780363753,35.664583553],[139.780133765,35.664794523],[139.780320742,35.664994506],[139.780521716,35.665210489],[139.780952663,35.66567145],[139.781749565,35.666506382],[139.781753562,35.666579374],[139.781842535,35.667124317],[139.782275513,35.666732372],[139.78292848,35.666114458],[139.782959478,35.666086462],[139.783851429,35.665293575],[139.783863428,35.665282576],[139.784800387,35.664412699],[139.784810386,35.6644037],[139.784407422,35.66411472],[139.784247437,35.663997728],[139.78413345,35.663914734],[139.784108452,35.663896735],[139.783984466,35.663807741],[139.783974467,35.663800742],[139.783832483,35.663700749],[139.783827484,35.663697749],[139.783696498,35.663604755],[139.783692499,35.663601756],[139.78341453,35.663403769],[139.783128562,35.663199784],[139.782981578,35.663093791],[139.782882589,35.663024796],[139.782882589,35.663024796]]]]}
   */
  shape: any[]

  /**
   * 地域の中心点の緯度
   * @example 35.664792
   */
  lat: number

  /**
   * 地域の中心点の経度
   * @example 139.782254
   */
  lng: number

  /**
   * 対象地かどうかのフラグ
   * 対象地の場合はtrue、比較地の場合はfalse
   * @example true
   */
  is_target: boolean

  /**
   * レコード作成日時
   * @format date-time
   * @example "2021-01-01T00:00:00.000Z"
   */
  created_at: string

  /**
   * レコード更新日時
   * @format date-time
   * @example "2021-01-01T00:00:00.000Z"
   */
  updated_at: string

  /**
   * 地域詳細名
   * 町丁目名、丁目を結合したもの
   * 例: 芝浦1丁目, 芝浦2丁目
   * (この値はAPIのレスポンスとして返却されます。)
   */
  area_detail_name: string

  /**
   * Polygonの座標情報
   * (この値はAPIのレスポンスとして返却されます。)
   */
  polygon: number[][][]
}

export interface HeatMapEstimatePrice {
  isBase: boolean
  cityName?: string
  areaName?: string
  detailName?: string
  lat: number
  lng: number
  estimatePrice?: { standardPrice?: number }
  shape: number[][][]
}

export interface RentHeatMapEstimatePrice {
  isBase: boolean
  cityName?: string
  areaName?: string
  detailName?: string
  lat: number
  lng: number
  estimatePrice?: { rentPrice25?: number }
  shape: number[][][]
}

export interface ReportAuthentication {
  /**
   * report_authentication_id
   * @example 12345
   */
  id: string

  /**
   * 共有済URLの末尾に入るトークン
   * @example example
   */
  token: string

  /**
   * パスワード設定してるかどうかのBoolean
   * @example true
   */
  is_password_not_set?: boolean
}

export interface ElevationAndSlopeAngleFifthMesh {
  /** メッシュの中心間距離 */
  distance?: number

  /** 位置 */
  direction?: string

  /** 最小傾斜度 */
  slope_angle_min?: number

  /** 最小傾斜方向 */
  min_slope_angle_direction?: string

  /** 最大傾斜方向 */
  max_slope_angle_direction?: string

  /** 平均傾斜度 */
  slope_angle_avg?: number

  /** 最大傾斜度 */
  slope_angle_max?: number

  /** 最小標高(m) */
  elevation_min?: number

  /** 最大標高(m) */
  elevation_max?: number

  /** 平均標高(m) */
  elevation_avg?: number

  /** ポリゴンデータ */
  shape: number[][][]
}

export interface LowPriceArea {
  /** 最大浸水深(m) */
  max_flood_depth?: number

  /** 面積(ha) */
  area?: number

  /** 住所 */
  address?: string

  /** 対象地からポリゴンの先端までの距離 */
  distance: number

  /** ポリゴン最北の緯度 */
  lat: number

  /** ポリゴン最北の経度 */
  lng: number

  /** ポリゴンデータ */
  shape: number[][][]
}

export interface HeatMapIncomePerPerson {
  isBase: boolean
  cityName?: string
  areaName?: string
  detailName?: string
  lat: number
  lng: number
  chome2020?: { incomePerPerson?: number }
  shape: number[][][]
}

export interface ExitSimulation {
  id: number
  report_id: number

  /**
   * 手残り金額です。
   * 売買金額から諸費用を引いた金額です。
   * @example 38000000
   */
  exit_price: number

  /**
   * 有効フラグ
   * @example true
   */
  active: boolean

  /**
   * 編集済みフラグ
   * @example false
   */
  items_edited: boolean
  items: ExitSimulationItem[]
}

export interface ExitSimulationItem {
  /** 項目名 */
  name: string

  /** コメント */
  comment: string

  /** 金額 */
  amount: number

  /** 計算上で負の値として取り扱うかどうか(負の値の場合にtrue) */
  negative: boolean

  /**
   * base: 売買金額
   * commission: 仲介手数料
   * etc: それ以外
   */
  type: string
}

export interface CustomOffer {
  id: number
  report_id: number

  /** 項目名 */
  name: string

  /** 金額(万円)(下限) */
  price_lower: number

  /** 金額(万円)(上限) */
  price: number

  /**
   * 下限の金額から算出された利回りです。
   * 収益還元法の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   */
  yield_percentage_for_price_lower: number

  /**
   * 上限の金額から算出された利回りです。
   * 収益還元法の場合、返却されます。
   * それ以外の査定方法の場合はnull値が返却されます。
   */
  yield_percentage_for_price: number
  active: boolean

  /**
   * レンジ表記フラグ
   * @example true
   */
  enabled_range: boolean
}

export interface ReportAccessLog {
  /** アクセス履歴のID */
  id: number

  /** 帳票ID */
  report_id: number

  /** IPアドレス */
  ip_address: string

  /** 参照元 */
  referrer: string

  /** アクセスが発生した時間 */
  created_at: string
}

/**
 * 取り込み用事例データファイル
 */
export interface StoriesImportFile {
  id: number

  /**
   * 帳票ID
   * @example 12345
   */
  report_id: number

  /**
   * 取り込み用事例データファイルのステータス
   * unknown: 不明
   * wait: 取り込み処理が未実行
   * active: 取り込み処理を実行中
   * completed: 取り込み処理が完了
   * failed: 取り込み処理が失敗
   */
  status: ImportFileStatus

  /**
   * 取り込みに失敗した場合のメッセージ
   * @example TODO
   */
  error_message?: string

  /**
   * 不動産の種類を表します。
   * - house: 戸建
   * - land: 土地
   * - condo: 区分マンション
   * - area: エリアレポート
   * - speed_mode_house: 戸建（スピードモード）
   * - speed_mode_land: 土地（スピードモード）
   * - speed_mode_condo: 区分マンション（スピードモード）
   * - multi_unit_building: 一棟
   */
  category: ReportCategory

  /** 取引の種別 */
  transaction_type: StoryTransactionType

  /**
   * 事例の用途。利用可能な値は以下の通りです。
   * - unknown: 不明
   * - cost_approach_assessment: 原価法
   * - market_approach_assessment: 取引事例比較法
   * - sale_price_approach_assessment: 分譲価格比の査定
   * - competitor_analysis: 競合分析
   * - speed_mode_assessment: スピードモード査定
   * - income_approach_assessment_for_rent: 収益還元法(賃料算出)
   * - income_approach_assessment_for_investment: 収益還元法(利回算出)
   */
  use: StoryUse

  /**
   * 添付ファイル（S3のキー）
   * @example reports/{report_id}/stories_import_files/example.csv
   */
  document_key: string
  created_at?: string
  updated_at?: string
}

/**
 * 事例に紐づく画像のマスキングデータ
 */
export interface ImageMasking {
  /** 画像に紐づく事例ID */
  story_id: number

  /**
   * 画像の横幅
   * @example 600
   */
  image_width: number

  /**
   * 画像の縦幅
   * @example 400
   */
  image_height: number

  /**
   * 画像に紐づくマスキングデータ
   * @example [{"masking_width":169.07540893554688,"masking_height":77.38116455078125,"top":125.517333984375,"left":224.39944458007812}]
   */
  image_masking_rectangle_items: ImageMaskingRectangleItem[]
}

/**
 * 事例に紐づく画像のマスキングデータ
 */
export interface ImageMaskingRectangleItem {
  /** マスキングの横幅 */
  masking_width: number

  /** マスキングの縦幅 */
  masking_height: number

  /**
   * 画像に対するマスキングの相対位置。マスキングの左上角のy座標に当たります。
   * 参考:https://markerjs.com/reference/interfaces/rectanglemarkerstate.html
   */
  top: number

  /**
   * 画像に対するマスキングの相対位置。マスキングの左上角のx座標に当たります。
   * 参考:https://markerjs.com/reference/interfaces/rectanglemarkerstate.html
   */
  left: number
}

/**
* NSLカスタム帳票内の成約者分析ページで使用されるデータです。
査定書の対象物件の住所に基づいた成約者分析データです。
*/
export interface ContractorAnalysis {
  id: number
  report_id: number

  /**
   * 市区町村名
   * 成約者分析ページの1ページ目に表示されるエリア名です。
   * 2024-04-26時点ではタイトル部分で使用しています。
   * @example 東京都千代田区
   */
  area_name: string

  /**
   * エリア定義名
   * 成約者分析ページの2ページ目に表示されるエリア名です。
   * 2024-04-26時点ではタイトル部分で使用しています。
   * @example 千代田区
   */
  area_title: string

  /** 購入者の契約前住所のカテゴリ別件数 */
  address_before_contract_count_data: { label: string; count: number }[]

  /** 購入事情のカテゴリ別件数 */
  reason_for_purchase_count_data: { label: string; count: number }[]

  /** 購入者の年代のカテゴリ別件数 */
  age_group_count_data: { label: string; count: number }[]

  /** 媒体経路（30~49歳） */
  channel_under50_count_data: { label: string; count: number }[]

  /** 媒体経路（50歳~） */
  channel_over50_count_data: { label: string; count: number }[]
}

/**
* 査定書に紐づく学区情報です。
査定書作成直後に非同期通信で取得されます。
別途NavitimeAPIで取得される学校情報と結合して帳票に表示されます。
[参考イシュー]
https://www.notion.so/NSL-_-c54bb80cbff449949185c54d41a7175b?pvs=4

プロパティ名は下記の国土数値情報ダウンロードサイトの仕様書を参考にしています。
https://nlftp.mlit.go.jp/ksj/gml/product_spec/KS-PS-A27-v3_0.pdf
*/
export interface SchoolDistrict {
  /**
   * 学区ID
   * @example 1
   */
  id: number

  /**
   * 小学校の行政区域コード
   * @example 13102
   */
  elementary_school_administrative_area_code: string

  /**
   * 小学校の設置主体
   * @example 中央区立
   */
  elementary_school_establishment_personnel: string

  /**
   * 小学校の学校コード
   * @example B113210200101
   */
  elementary_school_school_code: string

  /**
   * 小学校の名称
   * @example 月島第一小学校
   */
  elementary_school_name: string

  /**
   * 小学校の所在地
   * @example 中央区月島4-15-1
   */
  elementary_school_address: string

  /**
   * 中学校の行政区域コード
   * @example 13102
   */
  junior_high_school_administrative_area_code: string

  /**
   * 中学校の設置主体
   * @example 中央区
   */
  junior_high_school_establishment_personnel: string

  /**
   * 中学校の学校コード
   * @example C113210200038
   */
  junior_high_school_school_code: string

  /**
   * 中学校の名称
   * @example 銀座中学校
   */
  junior_high_school_name: string

  /**
   * 中学校の所在地
   * @example 中央区銀座8-19-15
   */
  junior_high_school_address: string
}

export interface AreaSummary {
  areaType: 'in_23s' | 'tokyo_cities' | 'others'
  cityName: string
  entiretyAreaName: string
  households2015: number
  households2020?: number
  statistics: {
    entiretyArea: AreaSummaryStaticsItem
    targetCity: AreaSummaryStaticsItem
  }
  stationPassengers: { name: string; count: number }[]
}

export interface AreaSummaryStaticsItem {
  household: {
    onePerson: number
    twoPeople: number
    threePeople: number
    fourPeople: number
    moreThanFivePeople: number
  }
  ownedHouseRatio: number
  population: {
    under20: number
    in20s: number
    over30Under60: number
    over60: number
  }
  detachedHouseRatio: number
}

/**
* NSLから入稿される成約者分析データです。
このデータをもとに成約者分析(ContractorAnalysis)を作成します。
*/
export interface NslContractorAnalysis {
  id: number

  /**
   * 入稿データのバージョン
   * (入稿日がバージョンになります)
   * @example "2023-01-01T00:00:00.000Z"
   */
  version: string

  /**
   * 市区町村コード
   * @example 13101
   */
  city_code: string

  /**
   * 都道府県名
   * @example 東京都
   */
  prefecture_name: string

  /**
   * 市区町村名
   * @example 千代田区
   */
  city_name: string

  /**
   * 購入者の契約前住所 > 区内(市内)の件数
   * @example 123
   */
  address_before_contract_category1: number

  /**
   * 購入者の契約前住所 > 区外(市外)の件数
   * @example 123
   */
  address_before_contract_category2: number

  /**
   * 購入事情 > 家への不満の件数
   * @example 123
   */
  reason_for_purchase_category1: number

  /**
   * 購入事情 > 家族数の変化の件数
   * @example 123
   */
  reason_for_purchase_category2: number

  /**
   * 購入事情 > 結婚・独立の件数
   * @example 123
   */
  reason_for_purchase_category3: number

  /**
   * 購入事情 > 通勤・通学の件数
   * @example 123
   */
  reason_for_purchase_category4: number

  /**
   * 購入事情 > 家族数の増加の件数
   * @example 123
   */
  reason_for_purchase_category5: number

  /**
   * 購入事情 > 高齢化への備えの件数
   * @example 123
   */
  reason_for_purchase_category6: number

  /**
   * 購入事情 > その他の件数
   * @example 123
   */
  reason_for_purchase_category7: number

  /**
   * 購入者の年代 > 20代・30代・40代の件数
   * @example 123
   */
  age_group_category1: number

  /**
   * 購入者の年代 > 50代以上の件数
   * @example 123
   */
  age_group_category2: number

  /**
   * 経路区分 > 20代・30代・40代 > ＳＵＵＭＯの件数
   * @example 123
   */
  channel_under50_category1: number

  /**
   * 経路区分 > 20代・30代・40代 > アットホームの件数
   * @example 123
   */
  channel_under50_category2: number

  /**
   * 経路区分 > 20代・30代・40代 > その他の件数
   * @example 123
   */
  channel_under50_category3: number

  /**
   * 経路区分 > 20代・30代・40代 > チラシ・DM・看板の件数
   * @example 123
   */
  channel_under50_category4: number

  /**
   * 経路区分 > 20代・30代・40代 > ネットその他サイトの件数
   * @example 123
   */
  channel_under50_category5: number

  /**
   * 経路区分 > 20代・30代・40代 > ノムコムの件数
   * @example 123
   */
  channel_under50_category6: number

  /**
   * 経路区分 > 20代・30代・40代 > リピーター・既契約者の件数
   * @example 123
   */
  channel_under50_category7: number

  /**
   * 経路区分 > 20代・30代・40代 > 紹介の件数
   * @example 123
   */
  channel_under50_category8: number

  /**
   * 経路区分 > 50代以上 > ＳＵＵＭＯの件数
   * @example 123
   */
  channel_over50_category1: number

  /**
   * 経路区分 > 50代以上 > アットホームの件数
   * @example 123
   */
  channel_over50_category2: number

  /**
   * 経路区分 > 50代以上 > その他の件数
   * @example 123
   */
  channel_over50_category3: number

  /**
   * 経路区分 > 50代以上 > チラシ・DM・看板の件数
   * @example 123
   */
  channel_over50_category4: number

  /**
   * 経路区分 > 50代以上 > ネットその他サイトの件数
   * @example 123
   */
  channel_over50_category5: number

  /**
   * 経路区分 > 50代以上 > ノムコムの件数
   * @example 123
   */
  channel_over50_category6: number

  /**
   * 経路区分 > 50代以上 > リピーター・既契約者の件数
   * @example 123
   */
  channel_over50_category7: number

  /**
   * 経路区分 > 50代以上 > 紹介の件数
   * @example 123
   */
  channel_over50_category8: number

  /**
   * エリア定義名
   * @example 千代田区
   */
  area_title: string
}

export interface RoadPrice {
  /** 路線価ID */
  id: number

  /** 関連する査定書ID */
  report_id: number

  /** 路線価の種別 */
  road_price_type: RoadPriceType

  /**
   * 路線価価格
   * @example 1000000
   */
  price: number

  /**
   * 路線価の年度（西暦）
   * @example 2020
   */
  year: number

  /**
   * 路線価の年度（和暦）
   * @example 元
   */
  japanese_year: string

  /**
   * 和暦
   * @example reiwa
   */
  japanese_calendar: string

  /** 借地権割合 */
  leasehold_ratio: LeaseholdRatio
  created_at: string
  updated_at: string
}

export interface SpeedModeLand {
  /**
   * 帳票のID
   * @example 12345678
   */
  report_id: number

  /**
   * 住所
   * @example 東京都中央区月島1-22-1
   */
  address: string

  /**
   * 緯度
   * @format double
   * @example 35.663829636289904
   */
  lat: number

  /**
   * 経度
   * @format double
   * @example 139.78193518226857
   */
  lng: number

  /**
   * 沿線名
   * @example 都営大江戸線
   */
  route1_line_name?: string

  /**
   * 沿線ID
   * @example 1
   */
  route1_line_code?: number

  /**
   * 駅名
   * @example 月島
   */
  route1_station_name?: string

  /**
   * 駅ID
   * @example 1
   */
  route1_station_code?: number

  /**
   * 駅徒歩
   * @example 10
   */
  route1_distance_to_station?: number

  /**
   * バス（停徒歩）
   * @example 10
   */
  route1_distance_to_busstop?: number

  /**
   * バス（乗車時間）
   * @example 10
   */
  route1_distance_to_station_by_bus?: number

  /**
   * バス停名
   * @example 月島駅前
   */
  route1_busstop_name?: string
  ownership: Ownership

  /**
   * 借地権割合(所有権の場合は1扱い)
   * @example 1
   */
  leasehold_percentage?: number

  /**
   * 土地面積
   * @format float
   * @example 80.12
   */
  area: number

  /**
   * 土地面積(坪)
   * 小数点第三位では四捨五入しています。
   * @format float
   * @example 24.27
   */
  tsubo?: number
  shape?: LandShape

  /**
   * セットバック面積
   * @format float
   * @example 5
   */
  setback_area?: number

  /**
   * 指定容積率
   * @example 400
   */
  specified_floor_area_ratio: number

  /**
   * 備考(土地)
   * @example 備考です。
   */
  remarks?: string
  connecting_road1_direction?: Direction
  connecting_road1_type?: RoadType

  /**
   * 幅員(m)
   * @format float
   * @example 10
   */
  connecting_road1_road_width?: number

  /**
   * 間口(m)
   * @format float
   * @example 10
   */
  connecting_road1_front_width?: number
}

export interface SpeedModeHouse {
  /**
   * 帳票のID
   * @example 12345678
   */
  report_id: number

  /**
   * 住所
   * @example 東京都中央区月島1-22-1
   */
  address: string

  /**
   * 緯度
   * @format double
   * @example 35.663829636289904
   */
  lat: number

  /**
   * 経度
   * @format double
   * @example 139.78193518226857
   */
  lng: number

  /**
   * 沿線名
   * @example 都営大江戸線
   */
  route1_line_name?: string

  /**
   * 沿線ID
   * @example 1
   */
  route1_line_code?: number

  /**
   * 駅名
   * @example 月島
   */
  route1_station_name?: string

  /**
   * 駅ID
   * @example 1
   */
  route1_station_code?: number

  /**
   * 駅徒歩
   * @example 10
   */
  route1_distance_to_station?: number

  /**
   * バス（停徒歩）
   * @example 10
   */
  route1_distance_to_busstop?: number

  /**
   * バス（乗車時間）
   * @example 10
   */
  route1_distance_to_station_by_bus?: number

  /**
   * バス停名
   * @example 月島駅前
   */
  route1_busstop_name?: string
  ownership: Ownership

  /**
   * 借地権割合(所有権の場合は1扱い)
   * @example 1
   */
  leasehold_percentage?: number

  /**
   * 土地面積
   * @format float
   * @example 80.12
   */
  land_area: number

  /**
   * 土地面積(坪)
   * 小数点第三位では四捨五入しています。
   * @format float
   * @example 24.27
   */
  land_tsubo?: number
  shape?: LandShape

  /**
   * セットバック面積
   * @format float
   * @example 0
   */
  setback_area?: number

  /**
   * 指定容積率
   * @example 500
   */
  specified_floor_area_ratio: number

  /**
   * 備考(土地)
   * @example 土地に関する備考です。
   */
  land_remarks?: string
  connecting_road1_direction?: Direction
  connecting_road1_type?: RoadType

  /**
   * 幅員(m)
   * @format float
   * @example 10
   */
  connecting_road1_road_width?: number

  /**
   * 間口(m)
   * @format float
   * @example 10
   */
  connecting_road1_front_width?: number
  construction_type: BuildingConstructionType

  /**
   * @format float
   * @example 120.12
   */
  building_area: number

  /**
   * 建物面積(坪)
   * 小数点第三位では四捨五入しています。
   * @format float
   * @example 36.37
   */
  building_tsubo?: number

  /**
   * 築年月日(年)
   * @example 2010
   */
  built_year: number

  /**
   * 築年月日(月)
   * @example 4
   */
  built_month: number

  /**
   * 備考(建物)
   * @example 建物に関する備考です。
   */
  building_remarks?: string

  /**
   * 築年数
   * @example 12
   */
  age?: number
}

export interface SpeedModeCondo {
  /**
   * 帳票のID
   * @example 12345678
   */
  report_id: number

  /**
   * 建物名
   * @example パークシティ月島レジデンスタワー
   */
  building_name: string

  /**
   * 建物ID(コラビット事例DB)
   * @example 1
   */
  building_id?: number

  /**
   * 住所
   * @example 東京都中央区月島1-22-1
   */
  address: string

  /**
   * 緯度
   * @format double
   * @example 35.663829636289904
   */
  lat: number

  /**
   * 経度
   * @format double
   * @example 139.78193518226857
   */
  lng: number

  /**
   * 沿線名
   * @example 都営大江戸線
   */
  route1_line_name?: string

  /**
   * 沿線ID
   * @example 1
   */
  route1_line_code?: number

  /**
   * 駅名
   * @example 月島
   */
  route1_station_name?: string

  /**
   * 駅ID
   * @example 1
   */
  route1_station_code?: number

  /**
   * 駅徒歩
   * @example 10
   */
  route1_distance_to_station?: number

  /**
   * バス（停徒歩）
   * @example 10
   */
  route1_distance_to_busstop?: number

  /**
   * バス（乗車時間）
   * @example 10
   */
  route1_distance_to_station_by_bus?: number

  /**
   * バス停名
   * @example 月島駅前
   */
  route1_busstop_name?: string
  construction_type?: CondoConstructionType

  /**
   * 総戸数
   * @example 520
   */
  residence_count?: number

  /**
   * 総階数（地上）
   * @example 32
   */
  ground_floor_number: number

  /**
   * 総階数(地下)
   * @example 1
   */
  basement_floor_number?: number

  /**
   * 築年月日(年)
   * @example 2010
   */
  built_year: number

  /**
   * 築年月日(月)
   * @example 4
   */
  built_month: number

  /**
   * 築年
   * @example 12
   */
  age: number
  ownership: Ownership

  /**
   * 備考(建物)
   * @example 建物に関するメモです。
   */
  building_remarks?: string

  /**
   * 部屋番号
   * @example 1211
   */
  room_name?: string

  /**
   * 所在階
   * @example 12
   */
  room_floor_number: number

  /**
   * 専有面積(平米)
   * @format float
   * @example 72.8
   */
  room_area: number

  /**
   * 専有面積(坪)
   * 小数点第三位では四捨五入しています。
   * @format float
   * @example 22.02
   */
  room_tsubo?: number
  room_direction?: Direction

  /**
   * 備考(部屋)
   * @example 部屋に関するメモです。
   */
  room_remarks?: string
}

export interface SpeedModeBaseReport {
  /** 査定書ID */
  id: number

  /** ユーザーID */
  user_id?: number

  /** 企業ID */
  company_id: number

  /**
   * 不動産の種類を表します。
   * - house: 戸建
   * - land: 土地
   * - condo: 区分マンション
   * - area: エリアレポート
   * - speed_mode_house: 戸建（スピードモード）
   * - speed_mode_land: 土地（スピードモード）
   * - speed_mode_condo: 区分マンション（スピードモード）
   * - multi_unit_building: 一棟
   */
  category: ReportCategory
  created_at: string
  updated_at: string

  /** @example 2020 */
  created_year: number

  /** @example 1 */
  created_month: number

  /**
   * スピードモードの査定価格
   * マンションの査定価格の算出はtype2（マンション（実需））APIにリクエストします: https://docs.google.com/spreadsheets/d/1JVbavbgHsGdbr15Z0yltUNAREfgwsYcrZW_VW4neL5Q/edit#gid=305934392
   * 土地・戸建の査定価格の算出はtype1（戸建）APIにリクエストします: https://docs.google.com/spreadsheets/d/1JVbavbgHsGdbr15Z0yltUNAREfgwsYcrZW_VW4neL5Q/edit#gid=1708854043
   * @example 8888
   */
  assessment_price: number

  /**
   * 査定価格の種別です。(speed_modeが入ります)
   * @example speed_mode
   */
  assessment_price_category: string

  /**
   * 査定コメント
   * @example ここに査定結果に対するコメントが入ります
   */
  assessment_comment: string

  /**
   * 査定書の初期化完了フラグ
   * @example true
   */
  initialized: boolean

  /**
   * 査定書の印刷可能フラグ
   * @example true
   */
  printable: boolean
  speed_mode_approach_assessment: Assessment

  /** 相場分析データ */
  market_analysis?: MarketAnalysis
  user?: User
  print_setting: PrintSetting
  company: Company

  /**
   * 緯度
   * @example 35.663829636289904
   */
  lat?: number

  /**
   * 経度
   * @example 139.78193518226857
   */
  lng?: number

  /**
   * 帳票URLを発行した日時
   * @example 2020-01-01 00:00:00
   */
  url_published_at?: string

  /** 共有URLにアクセスされた履歴を取得します（最大5件） */
  report_access_logs?: ReportAccessLog[]

  /** その他補正率の有効フラグ */
  other_correction_items_active: boolean

  /** 市場性調整率の有効フラグ */
  adjustment_percentage_active: boolean

  /** 売出提案価格の調整属性 */
  offer_price_items?: OfferPriceItem[]
}

export type SpeedModeLandReport = SpeedModeBaseReport & {
  speed_mode_land?: SpeedModeLand
}

export type SpeedModeHouseReport = SpeedModeBaseReport & {
  speed_mode_house?: SpeedModeHouse
}

export type SpeedModeCondoReport = SpeedModeBaseReport & {
  speed_mode_condo?: SpeedModeCondo
}

export interface SpeedModeAssessmentPriceAdjustment {
  /** 査定価格の調整ID */
  id: number

  /** 査定書ID */
  report_id: number

  /**
   * 査定価格の調整項目名
   * @example お墓による影響
   */
  name: string

  /**
   * 査定価格の調整についてのコメント
   * @example 周辺施設にお墓があります。
   */
  comment: string

  /**
   * 査定価格の調整額
   * @example 100000
   */
  adjustment_price: number
  created_at: string
  updated_at: string
}

export interface OfferPriceItem {
  /** 売出提案価格の調整属性のID */
  id: number

  /** 査定書ID */
  report_id: number

  /** 売出提案価格の調整項目名 */
  name?: string

  /** 売出提案価格の調整についてのコメント */
  comment?: string

  /**
   * 売出提案などに相当する価格です。
   * 上限は99億9999万です。
   * @example 7000
   */
  offer_price: number

  /**
   * 売出提案などに相当する下限価格です。
   * 上限は99億9999万です。
   * @example 6666
   */
  offer_price_lower: number

  /**
   * 売出提案などに相当する上限価格です。
   * 上限は99億9999万です。
   * @example 7777
   */
  offer_price_upper: number

  /**
   * レンジ表記フラグ
   * @example true
   */
  enabled_range: boolean
}

export interface OtherCorrectionItem {
  /** その他補正の調整項目のID */
  id: number

  /** 査定ID */
  assessment_id: number

  /**
   * 調整項目名
   * @example 造成が必要
   */
  name: string

  /**
   * 補正率(%)
   * @example 0
   */
  correction_percentage: number

  /**
   * 補正価格(万円)
   * speed_modeでのみ使われている属性です。
   * speed_modeでは、correction_percentageは使いません。
   * 補正率ではなく補正価格で計算する仕様です。
   * @example 100
   */
  correction_price: number

  /**
   * コメント
   * @example 造成が必要な場合は、造成費分マイナス評価
   */
  comment: string
}

/**
* REINSマーケット情報データ
NSLの帳票で使用します。(NSL以外の企業の場合はレスポンスはnullになります。)
*/
export interface ReinsDataDocument {
  /**
   * 資料タイトル
   * @example REINSマーケット情報
   */
  title: string

  /** ファイルURL一覧 */
  file_urls: string[]
}

export interface CiraveilRiskBasicInfoResponse {
  /**
   * データ種別
   * @example ekijo
   */
  datatype: string

  /**
   * 診断項目名称
   * @example 液状化の可能性
   */
  title: string

  /**
   * 地図タイルURL
   * @example https://ciraveil.pckk-service.jp/tile_map/Ekijoka/{z}/{x}/{y}.png
   */
  tileurl: string

  /**
   * データ注意事項
   * @example ●微地形をもとに地形が示す一般的な地盤特性に対応した相対的な液状化の発生傾向の強弱を 5 段階区分で表したものであり、明確な境界線を示したものではありません。参考情報として活用してください。<br>●地図上のアイコン等の情報は、付近の住所に所在していることを示すものであり、該当アイコンの施設等の地点を保証するものではありません。<br>●防災科学技術研究所 地震ハザードステーション地形・地盤分類メッシュマップをもとにパシフィックコンサルタンツ株式会社が作成したものです。本データの利用により損失・損害が発生しても一切の責任を負いません。<br>　（※改行は<br>タグを設定しているので、表示時に必要に応じ変換して利用）
   */
  attention: string

  /**
   * 最新のレコードがどうか
   * @example true
   */
  latest: boolean
}

export interface CiraveilRiskInfoResponse {
  /** 査定書ID */
  report_id: number

  /** リスク診断APIレスポンス情報 */
  data: { datatype: string; level: number; note: string; title: string }[]
}

/**
 * 利回りを調整する項目（市場性調整）
 */
export interface AdjustmentYieldItem {
  /** 査定ID */
  id?: number

  /** 査定ID */
  assessment_id?: number

  /**
   * 調整項目名
   * @example 旧耐震
   */
  name: string

  /**
   * 調整率(%)
   * @example 15
   */
  percentage: number
}

export interface BasicInformation {
  /**
   * objectのキー名は以下の10ページ目に記載してあります。
   * https://drive.google.com/drive/u/0/folders/1S-U5oRfhy5pOZXxoKoOkHJ2_sJau1LpP
   */
  datatype: RiskInformationDataType

  /**
   * 診断項目名称
   * @example 液状化の可能性
   */
  title: string

  /**
   * 地図タイルURL
   * @example https://ciraveil.pckk-service.jp/tile_map/Ekijoka/{z}/{x}/{y}.png
   */
  tileurl: string

  /**
   * データ注意事項
   * @example ●微地形をもとに地形が示す一般的な地盤特性に対応した相対的な液状化の発生傾向の強弱を 5 段階区分で表したものであり、明確な境界線を示したものではありません。参考情報として活用してください。<br>●地図上のアイコン等の情報は、付近の住所に所在していることを示すものであり、該当アイコンの施設等の地点を保証するものではありません。<br>●防災科学技術研究所 地震ハザードステーション地形・地盤分類メッシュマップをもとにパシフィックコンサルタンツ株式会社が作成したものです。本データの利用により損失・損害が発生しても一切の責任を負いません。<br>
   */
  attention: string
}

export interface RiskInformation {
  /**
   * objectのキー名は以下の10ページ目に記載してあります。
   * https://drive.google.com/drive/u/0/folders/1S-U5oRfhy5pOZXxoKoOkHJ2_sJau1LpP
   */
  datatype: RiskInformationDataType

  /**
   * リスクランク
   * @example 1
   */
  level: number

  /**
   * 診断メモ
   * @example 火山灰台地
   */
  note: string

  /**
   * 診断項目名称
   * @example 液状化の可能性
   */
  title: string
}

export interface Announcement {
  id: number

  /**
   * お知らせの内容を記載します。
   * この内容は、お知らせ一覧画面に表示されます。
   * 入力必須項目です。
   * @example 「収益還元法」の査定書作成が可能になりました。
   */
  message: string

  /**
   * お知らせのURLを記載します。
   * このURLは、お知らせ一覧画面に表示されます。
   * 入力必須項目です。
   * @example https://collab-it.net/
   */
  url: string

  /**
   * お知らせの日付を記載します。
   * この日付は、お知らせ一覧画面に表示されます。
   * 登録日以降にTOP画面の一覧に表示されるようになります。
   * 入力必須項目です。
   * @format date-time
   * @example 2022-01-01T10:30
   */
  posted_at: string
}

/**
 * HowMaの企業情報です。
 */
export interface HowmaCompany {
  /**
   * レコードのID
   * @example 1
   */
  id: number

  /**
   * AI査定プロの企業のID
   * @example 1
   */
  company_id: number

  /**
   * HowMa上の企業のUUID
   * @example 12345678-1234-1234-1234-123456789012
   */
  uuid: string
}

/**
* HowMaの一括査定リクエスト(担当者が未アサイン)の一覧です。

HowMa提供のGraphQLのエンドポイントに接続してレスポンスを返却します。
*/
export interface HowmaBaRequest {
  /** 一括査定リクエストのID */
  id: number

  /**
   * 物件の種別
   * - condo: マンション
   * - condo_income: マンション(賃貸)
   * - house: 戸建
   * - land: 土地
   */
  real_estate_type: 'condo' | 'condo_income' | 'house' | 'land'

  /** AI査定プロの概要入力ページのプリセット用のデータ */
  report: { land?: Land; building?: Building; condo?: Condo }

  /**
   * 査定書ID
   * すでに作成済みの場合は、査定書IDが返却されます。
   * @example 12345
   */
  report_id?: number | null
}

/**
* HowMaのマンション物件情報です。

HowMa提供のGraphQLのエンドポイントに接続してレスポンスを返却します。
*/
export interface HowmaCondo {
  /**
   * 物件ID
   * @example 123
   */
  id: number

  /**
   * エリアコード
   * @example 13101
   */
  area_code: string

  /**
   * 住所(その他)
   * @example 1-1-1
   */
  address_other: string

  /**
   * 緯度
   * @format double
   * @example 35.6895
   */
  lat: number

  /**
   * 経度
   * @format double
   * @example 139.6917
   */
  lng: number

  /**
   * 建物名
   * @example サンプルマンション
   */
  building_name: string

  /**
   * 部屋番号
   * @example 101
   */
  room_number: string

  /**
   * 階数(所在階)
   * @example 1
   */
  floor_number: number

  /**
   * 面積(m2)
   * @format double
   * @example 54.25
   */
  area_m2: number

  /**
   * 築年数
   * 年以外は間違っている可能性があるので注意すること。
   * @format date
   * @example "2010-01-01T00:00:00.000Z"
   */
  built_at: string

  /**
   * 建物ID
   * JireiDBの建物ID
   * @example 123
   */
  v1_building_id: number
}

/**
* HowMaの戸建物件情報です。

HowMa提供のGraphQLのエンドポイントに接続してレスポンスを返却します。
*/
export interface HowmaHouse {
  /**
   * 物件ID
   * @example 123
   */
  id: number

  /**
   * エリアコード
   * @example 13101
   */
  area_code: string

  /**
   * 住所(その他)
   * @example 1-1-1
   */
  address_other: string

  /**
   * 緯度
   * @format double
   * @example 35.6895
   */
  lat: number

  /**
   * 経度
   * @format double
   * @example 139.6917
   */
  lng: number

  /**
   * 延床面積(m2)
   * @format double
   * @example 54.25
   */
  area_m2: number

  /**
   * 土地面積(m2)
   * @format double
   * @example 54.25
   */
  land_area_m2: number

  /**
   * 築年数
   * 年以外は間違っている可能性があるので注意すること。
   * @format date
   * @example "2010-01-01T00:00:00.000Z"
   */
  built_at: string
}

/**
* HowMaの土地物件情報です。

HowMa提供のGraphQLのエンドポイントに接続してレスポンスを返却します。
*/
export interface HowmaLand {
  /**
   * 物件ID
   * @example 123
   */
  id: number

  /**
   * エリアコード
   * @example 13101
   */
  area_code: string

  /**
   * 住所(その他)
   * @example 1-1-1
   */
  address_other: string

  /**
   * 緯度
   * @format double
   * @example 35.6895
   */
  lat: number

  /**
   * 経度
   * @format double
   * @example 139.6917
   */
  lng: number

  /**
   * 土地面積(m2)
   * @format double
   * @example 54.25
   */
  land_area_m2: number
}

/**
* 住所はユーザーが入力した値が保存されます。
緯度経度はGeokit::Geocoders::GoogleGeocoderのレスポンス内容から取得した値が保存されます。
*/
export interface GeocodeLocation {
  /** 位置情報ID */
  id: number

  /**
   * 住所
   * @example 東京都台東区台東一丁目２０−６
   */
  address: string

  /**
   * 緯度
   * @format double
   * @example 35.701814
   */
  lat: number

  /**
   * 経度
   * @format double
   * @example 139.779847
   */
  lng: number
}

/**
* 駅や路線の情報はStationAPIのロジックから取得したデータが保存されます。
徒歩分巣だけGoogleDistanceMatrix::Matrixの値が採用されます。
*/
export interface GeocodeLocationStation {
  /** ID */
  id: number

  /**
   * 位置情報ID
   * @example 1
   */
  geocode_location_id: number

  /**
   * 駅名
   * @example 秋葉原
   */
  station_name: string

  /**
   * 駅ID
   * @example 2800307
   */
  station_cd: number

  /**
   * 駅グループID
   * @example 1130222
   */
  station_g_cd: number

  /**
   * 緯度
   * @format double
   * @example 35.698162
   */
  lat?: number

  /**
   * 経度
   * @format double
   * @example 139.775459
   */
  lng?: number

  /**
   * 沿線名
   * @example 東京メトロ日比谷線
   */
  line_name: string

  /**
   * 沿線ID
   * @example 28003
   */
  line_cd: number

  /**
   * 徒歩分数
   * @example 9
   */
  walk_minutes?: number
}

/**
 * 担当者別査定書タイトルデフォルト設定
 */
export interface UserReportTitleDefaultSetting {
  /** @example 12345 */
  id: number

  /**
   * 担当者ID
   * @example 12345
   */
  user_id: number

  /**
   * プロ査定モードレポートタイトル
   * @example 不動産査定報告書
   */
  report_title: string

  /**
   * らくらくモードレポートタイトル
   * @example 不動産査定報告書
   */
  speed_mode_report_title: string

  /**
   * エリアレポートタイトル
   * @example エリアレポート
   */
  area_report_title: string
}

/**
 * 担当者別査定コメントデフォルト設定
 */
export interface UserOfferCommentDefaultSetting {
  /** @example 12345 */
  id: number

  /**
   * 担当者ID
   * @example 12345
   */
  user_id: number

  /**
   * プロ査定モードマンション査定コメント
   * @example この度は査定のご依頼をいただきまして誠にありがとうございます。
   * 査定結果に関しましてご報告申し上げます。
   * 査定価格はお客様から頂戴した情報に基づき、近隣の類似不動産の事例情報をもとに算出した価格になります。
   * まずは本査定報告書をご高覧いただき、ご検討賜りますようよろしくお願い申し上げます。
   * 本資料に関するご質問やご要望などがございましたら、お気軽にお問い合わせください。
   */
  condo_offer_comment: string

  /**
   * プロ査定モード戸建査定コメント
   * @example この度は査定のご依頼をいただきまして誠にありがとうございます。
   * 査定結果に関しましてご報告申し上げます。
   * 査定価格はお客様から頂戴した情報に基づき、近隣の類似不動産の事例情報をもとに算出した価格になります。
   * まずは本査定報告書をご高覧いただき、ご検討賜りますようよろしくお願い申し上げます。
   * 本資料に関するご質問やご要望などがございましたら、お気軽にお問い合わせください。
   */
  house_offer_comment: string

  /**
   * プロ査定モード土地査定コメント
   * @example この度は査定のご依頼をいただきまして誠にありがとうございます。
   * 査定結果に関しましてご報告申し上げます。
   * 査定価格はお客様から頂戴した情報に基づき、近隣の類似不動産の事例情報をもとに算出した価格になります。
   * まずは本査定報告書をご高覧いただき、ご検討賜りますようよろしくお願い申し上げます。
   * 本資料に関するご質問やご要望などがございましたら、お気軽にお問い合わせください。
   */
  land_offer_comment: string

  /**
   * らくらくモードマンション査定コメント
   * @example この度は査定のご依頼をいただきまして誠にありがとうございます。
   * 査定結果に関しましてご報告申し上げます。
   * 査定価格はお客様から頂戴した情報に基づき、近隣の類似不動産の事例情報をもとに算出した価格になります。
   * まずは本査定報告書をご高覧いただき、ご検討賜りますようよろしくお願い申し上げます。
   * 本資料に関するご質問やご要望などがございましたら、お気軽にお問い合わせください。
   */
  speed_mode_condo_offer_comment: string

  /**
   * らくらくモード戸建査定コメント
   * @example この度は査定のご依頼をいただきまして誠にありがとうございます。
   * 査定結果に関しましてご報告申し上げます。
   * 査定価格はお客様から頂戴した情報に基づき、近隣の類似不動産の事例情報をもとに算出した価格になります。
   * まずは本査定報告書をご高覧いただき、ご検討賜りますようよろしくお願い申し上げます。
   * 本資料に関するご質問やご要望などがございましたら、お気軽にお問い合わせください。
   */
  speed_mode_house_offer_comment: string

  /**
   * らくらくモード土地査定コメント
   * @example この度は査定のご依頼をいただきまして誠にありがとうございます。
   * 査定結果に関しましてご報告申し上げます。
   * 査定価格はお客様から頂戴した情報に基づき、近隣の類似不動産の事例情報をもとに算出した価格になります。
   * まずは本査定報告書をご高覧いただき、ご検討賜りますようよろしくお願い申し上げます。
   * 本資料に関するご質問やご要望などがございましたら、お気軽にお問い合わせください。
   */
  speed_mode_land_offer_comment: string

  /**
   * 区分収益査定コメント
   * @example この度は査定のご依頼をいただきまして誠にありがとうございます。
   * 査定結果に関しましてご報告申し上げます。
   * 査定価格はお客様から頂戴した情報に基づき、近隣の類似不動産の事例情報をもとに算出した価格になります。
   * まずは本査定報告書をご高覧いただき、ご検討賜りますようよろしくお願い申し上げます。
   * 本資料に関するご質問やご要望などがございましたら、お気軽にお問い合わせください。
   */
  condo_income_offer_comment: string

  /**
   * 一棟収益査定コメント
   * @example この度は査定のご依頼をいただきまして誠にありがとうございます。
   * 査定結果に関しましてご報告申し上げます。
   * 査定価格はお客様から頂戴した情報に基づき、近隣の類似不動産の事例情報をもとに算出した価格になります。
   * まずは本査定報告書をご高覧いただき、ご検討賜りますようよろしくお願い申し上げます。
   * 本資料に関するご質問やご要望などがございましたら、お気軽にお問い合わせください。
   */
  apartment_offer_comment: string
}

export interface UserOfferPriceItemDefaultSetting {
  /** 売出提案価格の調整属性のID */
  id: number

  /** ユーザーID */
  user_id: number

  /** 売出提案価格の調整項目名 */
  offer_price_item_name: string

  /** 売出提案価格の調整についてのコメント */
  offer_price_item_comment?: string

  /**
   * 売出提案価格の割合（assessment_price に対する百分率）。
   * 例えば、105%で設定するには 105.00 を入力します。
   * @format float
   * @example 105
   */
  offer_price_item_price_percentage: number

  /**
   * 売出提案価格の下限割合（assessment_price に対する百分率）。
   * 例えば、評価額と同じであれば 100.00 を入力します。
   * @format float
   * @example 100
   */
  offer_price_item_price_percentage_lower: number

  /**
   * 売出提案価格の上限割合（assessment_price に対する百分率）。
   * 例えば、110%で設定するには 110.00 を入力します。
   * @format float
   * @example 110
   */
  offer_price_item_price_percentage_upper: number

  /**
   * レンジ表記フラグ
   * @example true
   */
  offer_price_item_enabled_range: boolean
}

export interface TaxBaseLandValue {
  /** 土地価格ID */
  id?: number

  /** 対応する査定ID */
  assessment_id: number

  /**
   * 土地価格の種類（相続税路線価または固定資産税路線価を選択するフラグ）
   * @example inheritance_tax_route_value
   */
  land_price_type: 'inheritance_tax_route_value' | 'fixed_asset_tax_route_value'

  /**
   * 路線価（円）
   * @example 300000
   */
  route_value: number

  /**
   * 実勢価格調整率（%）
   * @format float
   * @example 95
   */
  market_adjustment_percentage: number

  /**
   * コメント
   * @example 価格調整は近隣取引事例を参考にしました
   */
  comment?: string
}

export interface MultiUnitBuildingIncomeEstimatorResponse {
  /** レスポンスID */
  id?: number

  /** 紐づく査定ID */
  assessment_id: number

  /**
   * 収益評価APIからのレスポンスデータ
   * @example {"gross_price":75775458,"profit_price":75775458,"gross_rate":4.32,"net_rate":2.95,"rent_price":273083,"vacancy_rate":5,"monthly_vacancy_loss":13654,"annual_vacancy_loss":163850,"monthly_management_cost":23560,"annual_management_cost":282720,"monthly_maintenance_cost":13536,"annual_maintenance_cost":162432,"monthly_insurance_cost":785,"annual_insurance_cost":9425,"monthly_land_rent":0,"annual_land_rent":0,"monthly_tax":14762,"annual_tax":177151,"monthly_property_management_cost":13654,"annual_property_management_cost":163850,"monthly_restoration_cost":2201,"annual_restoration_cost":26423,"monthly_recruitment_cost":4963,"annual_recruitment_cost":59559,"deposit_profit":2594,"investment_yield":1,"key_money_amortization_amount":0,"amortization_term":4.6,"monthly_other_cost":0,"annual_other_cost":0,"future_price":[{"year":2024,"gross_price":75775458,"profit_price":75775458,"gross_rate":4.32,"net_rate":2.95,"rent_price":273083}]}
   */
  type3_data?: Record<string, any>

  /**
   * 収益評価APIからのレスポンスデータ
   * @example {"gross_price":30000000,"profit_price":28000000,"gross_rate":5.5,"net_rate":4.8,"monthly_rent_price":250000,"annual_rent_price":3000000,"monthly_parking_income":10000,"annual_parking_income":120000,"monthly_other_income":5000,"annual_other_income":60000,"vacancy_rate":0.05,"monthly_vacancy_loss":12500,"annual_vacancy_loss":150000,"parking_vacancy_rate":10,"monthly_parking_vacancy_loss":1000,"annual_parking_vacancy_loss":12000,"deposit_profit":50000,"key_money_amortization_amount":100000,"amortization_term":12,"investment_yield":5,"monthly_maintenance_repair_cost":15000,"annual_maintenance_repair_cost":180000,"monthly_elevator_cost":5000,"annual_elevator_cost":60000,"monthly_utility_bill":8000,"annual_utility_bill":96000,"monthly_insurance_cost":2000,"annual_insurance_cost":24000,"monthly_land_rent":10000,"annual_land_rent":120000,"monthly_tax":15000,"annual_tax":180000,"monthly_property_management_cost":10000,"annual_property_management_cost":120000,"monthly_restoration_cost":5000,"annual_restoration_cost":60000,"monthly_recruitment_cost":3000,"annual_recruitment_cost":36000,"monthly_capex":7000,"annual_capex":84000,"future_price":[{"year":2030,"gross_price":35000000,"profit_sale_price":30000000,"gross_rate":5.7,"net_rate":4.9},{"year":2035,"gross_price":34000000,"profit_sale_price":29000000,"gross_rate":5.6,"net_rate":4.8}]}
   */
  type4_data?: Record<string, any>

  /**
   * 作成日時
   * @format date-time
   */
  created_at?: string

  /**
   * 更新日時
   * @format date-time
   */
  updated_at?: string
}

export interface AdditionalRoute {
  /**
   * 対象ID
   * 土地・マンションなどのモデルを想定しています。
   */
  target_id?: number

  /** 沿線名 */
  route_line_name: string

  /** 沿線ID */
  route_line_code: number

  /** 駅名 */
  route_station_name: string

  /** 駅ID */
  route_station_code: number

  /** 駅徒歩 */
  route_distance_to_station: number
}

/**
 * 担当者別査定書諸費用設定
 */
export interface UserExitSimulationDefaultSetting {
  /** @example 12345 */
  id: number

  /**
   * 担当者ID
   * @example 12345
   */
  user_id: number
  data: {
    exit_simulation_name: string
    exit_simulation_comment: string
    exit_simulation_amount: number
    exit_simulation_category: string
  }[]
}

/**
 * 企業別建築単価・耐用年数・構成割合設定デフォルト設定
 */
export interface CompanyBuildingDefaultSetting {
  /** @example 12345 */
  id: number

  /**
   * 企業ID
   * @example 12345
   */
  company_id: number
  construction_type: BuildingConstructionType

  /**
   * 坪単価
   * @example 100000
   */
  tsubo_price: number

  /**
   * 部位別構成割合(基礎・躯体)
   * @example 25.5
   */
  foundation_structure_percentage: number

  /**
   * 部位別構成割合(外部)
   * @example 24.5
   */
  external_finish_percentage: number

  /**
   * 部位別構成割合(内部)
   * @example 25.5
   */
  internal_finish_percentage: number

  /**
   * 部位別構成割合(設備)
   * @example 24.5
   */
  facility_percentage: number

  /**
   * 部位別耐用年数（基礎・躯体)
   * @example 20
   */
  foundation_structure_durable_years: number

  /**
   * 部位別耐用年数（外部)
   * @example 20
   */
  external_finish_durable_years: number

  /**
   * 部位別耐用年数（内部)
   * @example 20
   */
  internal_finish_durable_years: number

  /**
   * 部位別耐用年数（設備)
   * @example 20
   */
  facility_durable_years: number
}

export interface Error {
  /** @format int32 */
  code: number
  message: string
}

export interface ValidationError {
  /**
   * 入力エラーとなった項目
   * 例えばemail, passwordなどの項目名です。
   */
  additionalProperties?: string[]
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'http://localhost:8010',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`
      )
      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = (format && this.format) || void 0

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      requestParams.headers.common = { Accept: '*/*' }
      requestParams.headers.post = {}
      requestParams.headers.put = {}

      body = this.createFormData(body as Record<string, unknown>)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title AI査定API
 * @version 1.5.0
 * @baseUrl http://localhost:8010
 *
 * AI査定プロで使用するAPIです。
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  admin = {
    /**
     * @description 企業一覧を返却します。 システム管理者のみがアクセス可能です。 これはシステム管理画面のアカウント一覧画面で利用されます。
     *
     * @tags admin
     * @name AdminCompanyList
     * @summary 企業一覧
     * @request GET:/admin/companies
     * @secure
     */
    adminCompanyList: (
      query?: { page?: number; keyword?: string },
      params: RequestParams = {}
    ) =>
      this.request<{ companies: AdminCompany[]; total_pages: number }, any>({
        path: `/admin/companies`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyCreate
     * @summary アカウント作成
     * @request POST:/admin/companies
     * @secure
     */
    adminCompanyCreate: (
      data: {
        plan_id: string
        password: string
        activated_at: string
        company: {
          name: string
          zip?: string
          address: string
          tel?: string
          fax?: string
          department_name?: string
          license_number?: string
        }
        user: { email: string; family_name?: string; given_name?: string }
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/companies`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyDetail
     * @summary アカウント詳細
     * @request GET:/admin/companies/{company_id}
     * @secure
     */
    adminCompanyDetail: (companyId: string, params: RequestParams = {}) =>
      this.request<AdminCompanyDetail, any>({
        path: `/admin/companies/${companyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyUpdate
     * @summary アカウント更新
     * @request PUT:/admin/companies/{company_id}
     * @secure
     */
    adminCompanyUpdate: (
      companyId: string,
      data: AdminCompanyEdit,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/companies/${companyId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyDelete
     * @summary アカウント削除
     * @request DELETE:/admin/companies/{company_id}
     * @secure
     */
    adminCompanyDelete: (companyId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/companies/${companyId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyEdit
     * @summary アカウント
     * @request GET:/admin/companies/{company_id}/edit
     * @secure
     */
    adminCompanyEdit: (companyId: string, params: RequestParams = {}) =>
      this.request<AdminCompanyEdit, any>({
        path: `/admin/companies/${companyId}/edit`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyReportList
     * @summary アカウント別の帳票一覧
     * @request GET:/admin/companies/{company_id}/reports
     * @secure
     */
    adminCompanyReportList: (
      companyId: string,
      query?: { page?: number },
      params: RequestParams = {}
    ) =>
      this.request<{ reports: Report[]; total_page_count: number }, any>({
        path: `/admin/companies/${companyId}/reports`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyOfficeList
     * @summary アカウント別の店舗一覧
     * @request GET:/admin/companies/{company_id}/offices
     * @secure
     */
    adminCompanyOfficeList: (
      companyId: string,
      query?: { page?: number },
      params: RequestParams = {}
    ) =>
      this.request<{ offices: Office[]; total_page_count: number }, any>({
        path: `/admin/companies/${companyId}/offices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyHowmaRelationCreate
     * @summary HowMaに連携する
     * @request PUT:/admin/companies/{company_id}/howma_relation
     * @secure
     */
    adminCompanyHowmaRelationCreate: (
      companyId: number,
      data: { password?: string },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/companies/${companyId}/howma_relation`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminUserCreate
     * @summary ユーザー作成
     * @request POST:/admin/users
     * @secure
     */
    adminUserCreate: (
      data: {
        company_id: number
        email: string
        password: string
        family_name?: string
        given_name?: string
        tel1?: string
        tel2?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminUserDetail
     * @summary アカウント
     * @request GET:/admin/users/{user_id}
     * @secure
     */
    adminUserDetail: (userId: string, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/admin/users/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminUserUpdate
     * @summary ユーザー更新
     * @request PUT:/admin/users/{user_id}
     * @secure
     */
    adminUserUpdate: (userId: string, data: User, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/admin/users/${userId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminUserDelete
     * @summary ユーザー削除
     * @request DELETE:/admin/users/{user_id}
     * @secure
     */
    adminUserDelete: (userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/users/${userId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminUserEdit
     * @summary アカウント
     * @request GET:/admin/users/{user_id}/edit
     * @secure
     */
    adminUserEdit: (userId: string, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/admin/users/${userId}/edit`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name PlanList
     * @summary プラン一覧
     * @request GET:/admin/plans
     * @secure
     */
    planList: (params: RequestParams = {}) =>
      this.request<Plan[], any>({
        path: `/admin/plans`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminPlanCreate
     * @summary プラン作成
     * @request POST:/admin/plans
     * @secure
     */
    adminPlanCreate: (
      data: {
        name: string
        name_short?: string
        monthly_reports_count: number
        has_trial_period: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/plans`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminInvoicesList
     * @summary 請求情報の一覧
     * @request GET:/admin/invoices
     * @secure
     */
    adminInvoicesList: (
      query: {
        target_year: string
        target_month: string
        is_over_count: boolean
        keyword?: string
        status?: PaymentStatus
      },
      params: RequestParams = {}
    ) =>
      this.request<{ invoices: Invoice[]; total_pages: number }, any>({
        path: `/admin/invoices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminUpdatePayment
     * @summary 利用ステータス更新
     * @request PUT:/admin/payments/{payment_id}
     * @secure
     */
    adminUpdatePayment: (
      paymentId: number,
      data: {
        status?: PaymentStatus
        plan_id?: number
        activated_at?: string
        billing?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<Payment, any>({
        path: `/admin/payments/${paymentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminProductStatisticDetail
     * @summary プロダクトの統計量データ
     * @request GET:/admin/product_statistic
     * @secure
     */
    adminProductStatisticDetail: (params: RequestParams = {}) =>
      this.request<AdminProductStatistic, any>({
        path: `/admin/product_statistic`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminReportAggregationsList
     * @summary 査定書集計一覧
     * @request GET:/admin/report_aggregations
     * @secure
     */
    adminReportAggregationsList: (
      query?: { period?: { start?: string; end?: string }; keyword?: string },
      params: RequestParams = {}
    ) =>
      this.request<
        { report_aggregations: ReportAggregation[]; total_pages: number },
        any
      >({
        path: `/admin/report_aggregations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 査定書集計一覧の出力CSVがダウンロードできます。
     *
     * @tags admin
     * @name AdminReportAggregationsExport
     * @summary 査定書集計一覧CSVの出力
     * @request GET:/admin/report_aggregations/export
     * @secure
     */
    adminReportAggregationsExport: (
      query?: { period?: { start?: string; end?: string }; keyword?: string },
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/admin/report_aggregations/export`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminAnnouncementsList
     * @summary お知らせ一覧
     * @request GET:/admin/announcements
     * @secure
     */
    adminAnnouncementsList: (
      query?: { page?: number },
      params: RequestParams = {}
    ) =>
      this.request<
        { announcements: AdminAnnouncement[]; total_pages: number },
        any
      >({
        path: `/admin/announcements`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminAnnouncementsCreate
     * @summary アカウント作成
     * @request POST:/admin/announcements
     * @secure
     */
    adminAnnouncementsCreate: (
      data: { message: string; url: string; posted_at: string },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/announcements`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminAnnouncementUpdate
     * @summary お知らせ更新
     * @request PUT:/admin/announcements/{announcement_id}
     * @secure
     */
    adminAnnouncementUpdate: (
      announcementId: string,
      data: AdminAnnouncement,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/announcements/${announcementId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminAnnouncementDelete
     * @summary お知らせ削除
     * @request DELETE:/admin/announcements/{announcement_id}
     * @secure
     */
    adminAnnouncementDelete: (
      announcementId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/announcements/${announcementId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminAnnouncementDetail
     * @summary お知らせ詳細
     * @request GET:/admin/announcements/{announcement_id}
     * @secure
     */
    adminAnnouncementDetail: (
      announcementId: string,
      params: RequestParams = {}
    ) =>
      this.request<AdminAnnouncement, any>({
        path: `/admin/announcements/${announcementId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  brainProject = {
    /**
     * No description
     *
     * @tags brain/project
     * @name GetBrainProjectDocument
     * @summary BRaiNから資料一覧を取得するためのクエリ
     * @request GET:/brain/project_document
     * @secure
     */
    getBrainProjectDocument: (
      query: { building_id: number },
      params: RequestParams = {}
    ) =>
      this.request<BRaiNProjectDocument, any>({
        path: `/brain/project_document`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags brain/project
     * @name GetBrainProjectOutline
     * @summary BRaiNから物件概要を取得するためのクエリ
     * @request GET:/brain/project_outline
     * @secure
     */
    getBrainProjectOutline: (
      query: { projectId: string },
      params: RequestParams = {}
    ) =>
      this.request<BRaiNProjectSignedUrl, any>({
        path: `/brain/project_outline`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags brain/project
     * @name GetBrainProjectPriceList
     * @summary BRaiNから価格表を取得するためのクエリ
     * @request GET:/brain/project_price_list
     * @secure
     */
    getBrainProjectPriceList: (
      query: { projectId: string },
      params: RequestParams = {}
    ) =>
      this.request<BRaiNProjectSignedUrl, any>({
        path: `/brain/project_price_list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  officesImportFiles = {
    /**
     * @description CSVアップロードで取り込みする処理を管理します。
     *
     * @tags officesImportFiles
     * @name OfficesImportFilesList
     * @summary 取り込み用店舗データファイル一覧
     * @request GET:/offices_import_files
     * @secure
     */
    officesImportFilesList: (
      query?: { status?: ImportFileStatus },
      params: RequestParams = {}
    ) =>
      this.request<OfficesImportFile[], any>({
        path: `/offices_import_files`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description POSTするとS3のファイルを解析して取り込む処理が非同期で実行されます。
     *
     * @tags officesImportFiles
     * @name OfficesImportFilesCreate
     * @summary 取り込み用店舗データファイル作成
     * @request POST:/offices_import_files
     * @secure
     */
    officesImportFilesCreate: (
      data: OfficesImportFile,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/offices_import_files`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  usersImportFiles = {
    /**
     * @description CSVアップロードで取り込みする処理を管理します。
     *
     * @tags usersImportFiles
     * @name UsersImportFilesList
     * @summary 取り込み用担当者データファイル一覧
     * @request GET:/users_import_files
     * @secure
     */
    usersImportFilesList: (
      query?: { status?: ImportFileStatus },
      params: RequestParams = {}
    ) =>
      this.request<UsersImportFile[], any>({
        path: `/users_import_files`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description POSTするとS3のファイルを解析して取り込む処理が非同期で実行されます。 - 全体版の場合: エクセルファイルがアップロードできます - NSLの場合: TSVファルがアップロードできます
     *
     * @tags usersImportFiles
     * @name UsersImportFilesCreate
     * @summary 取り込み用担当者データファイル作成
     * @request POST:/users_import_files
     * @secure
     */
    usersImportFilesCreate: (
      data: UsersImportFile,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/users_import_files`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 担当者一覧TSVの入力チェックを行います。
     *
     * @tags usersImportFiles
     * @name UsersImportFilesValidate
     * @summary 担当者一覧TSVの入力チェック
     * @request POST:/users_import_files/validate
     * @secure
     */
    usersImportFilesValidate: (
      data: UsersImportFile,
      params: RequestParams = {}
    ) =>
      this.request<ImportFileValidationResult, any>({
        path: `/users_import_files/validate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  users = {
    /**
     * @description 企業内の担当者一覧を返却します。 システム管理者、企業管理者のみがアクセス可能です。
     *
     * @tags users
     * @name UsersList
     * @summary 担当者一覧
     * @request GET:/users
     * @secure
     */
    usersList: (params: RequestParams = {}) =>
      this.request<{ users: User[] }, any>({
        path: `/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 企業内の担当者を作成します。
     *
     * @tags users
     * @name UsersCreate
     * @summary 担当者作成
     * @request POST:/users
     * @secure
     */
    usersCreate: (data: User, params: RequestParams = {}) =>
      this.request<User, ValidationError>({
        path: `/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した担当者の情報を取得します。 認証した担当者が所属する企業内の担当者のみ対象です。
     *
     * @tags users
     * @name UsersDetail
     * @summary 担当者詳細
     * @request GET:/users/{uuid}
     * @secure
     */
    usersDetail: (uuid: number, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users/${uuid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した担当者の情報を更新します。 認証した担当者が所属する企業内の担当者のみ対象です。
     *
     * @tags users
     * @name UsersUpdate
     * @summary 担当者更新
     * @request PUT:/users/{uuid}
     * @secure
     */
    usersUpdate: (uuid: number, data: User, params: RequestParams = {}) =>
      this.request<User, ValidationError>({
        path: `/users/${uuid}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した担当者の情報を論理削除します。 認証した担当者が所属する企業内の担当者のみ対象です。
     *
     * @tags users
     * @name UsersDelete
     * @summary 担当者削除
     * @request DELETE:/users/{uuid}
     * @secure
     */
    usersDelete: (uuid: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${uuid}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersMeDetail
     * @summary ユーザー詳細
     * @request GET:/users/me
     * @secure
     */
    usersMeDetail: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersMeUpdate
     * @summary ユーザー編集
     * @request PUT:/users/me
     * @secure
     */
    usersMeUpdate: (data: User, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/users/me`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 認証したユーザーが所属する企業内の担当者一覧がダウンロードできます。 - 全体版の場合: エクセルファイルがダウンロードできます - NSLの場合: TSVファルがダウンロードできます 一覧には企業管理者は含まれません。これはシステム管理者が利用する運用を行っているため、そういったユーザーがTSV出力でエンドユーザーに表示されないことを考慮した背景です。
     *
     * @tags users
     * @name UsersExport
     * @summary 担当者一覧TSVの出力
     * @request GET:/users/export
     * @secure
     */
    usersExport: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users/export`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  }
  offices = {
    /**
     * @description 認証したユーザーが所属する企業内の店舗の一覧を返却します。
     *
     * @tags offices
     * @name OfficesList
     * @summary 店舗一覧
     * @request GET:/offices
     * @secure
     */
    officesList: (params: RequestParams = {}) =>
      this.request<Office[], any>({
        path: `/offices`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 認証したユーザーが所属する企業内に店舗を追加します。
     *
     * @tags offices
     * @name OfficesCreate
     * @summary 店舗作成
     * @request POST:/offices
     * @secure
     */
    officesCreate: (data: Office, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/offices`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 指定した店舗の情報を取得します。 認証したユーザーが所属する企業内の店舗のみ対象です。
     *
     * @tags offices
     * @name OfficesDetail
     * @summary 店舗詳細
     * @request GET:/offices/{office_id}
     * @secure
     */
    officesDetail: (officeId: number, params: RequestParams = {}) =>
      this.request<Office, any>({
        path: `/offices/${officeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した店舗の情報を更新します。 認証したユーザーが所属する企業内の店舗のみ対象です。
     *
     * @tags offices
     * @name OfficesUpdate
     * @summary 店舗更新
     * @request PUT:/offices/{office_id}
     * @secure
     */
    officesUpdate: (
      officeId: number,
      data: Office,
      params: RequestParams = {}
    ) =>
      this.request<Office, any>({
        path: `/offices/${officeId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した店舗の情報を論理削除します。 認証したユーザーが所属する企業内の店舗のみ対象です。
     *
     * @tags offices
     * @name OfficesDelete
     * @summary 店舗削除
     * @request DELETE:/offices/{office_id}
     * @secure
     */
    officesDelete: (officeId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/offices/${officeId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description 店舗のカスタム項目を取得します。
     *
     * @tags offices
     * @name OfficeCustomFieldValuesDetail
     * @summary 店舗のカスタム項目の取得
     * @request GET:/offices/{office_id}/office_custom_field_values/{office_custom_field_value_id}
     * @secure
     */
    officeCustomFieldValuesDetail: (
      officeId: number,
      officeCustomFieldValueId: number,
      params: RequestParams = {}
    ) =>
      this.request<OfficeCustomFieldValue, any>({
        path: `/offices/${officeId}/office_custom_field_values/${officeCustomFieldValueId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 店舗のカスタム項目を更新します。
     *
     * @tags offices
     * @name OfficeCustomFieldValuesUpdate
     * @summary 店舗のカスタム項目の更新
     * @request PATCH:/offices/{office_id}/office_custom_field_values/{office_custom_field_value_id}
     * @secure
     */
    officeCustomFieldValuesUpdate: (
      officeId: number,
      officeCustomFieldValueId: number,
      data: OfficeCustomFieldValue[],
      params: RequestParams = {}
    ) =>
      this.request<OfficeCustomFieldValue, any>({
        path: `/offices/${officeId}/office_custom_field_values/${officeCustomFieldValueId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  officesUsers = {
    /**
     * @description 認証したユーザーが所属する企業内の指定店舗に所属する担当者一覧を返却します。
     *
     * @tags officesUsers
     * @name UsersList
     * @summary ユーザー一覧
     * @request GET:/offices/{office_id}/users
     * @secure
     */
    usersList: (officeId: string, params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/offices/${officeId}/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 認証したユーザーが所属する企業内の指定店舗に所属する担当者を作成します。
     *
     * @tags officesUsers
     * @name UsersCreate
     * @summary ユーザー作成
     * @request POST:/offices/{office_id}/users
     * @secure
     */
    usersCreate: (officeId: string, data: User, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/offices/${officeId}/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 認証したユーザーが所属する企業内の指定店舗に所属する担当者情報を取得します。
     *
     * @tags officesUsers
     * @name UsersDetail
     * @summary ユーザー詳細
     * @request GET:/offices/{office_id}/users/{user_id}
     * @secure
     */
    usersDetail: (
      userId: number,
      officeId: string,
      params: RequestParams = {}
    ) =>
      this.request<User, any>({
        path: `/offices/${officeId}/users/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 認証したユーザーが所属する企業内の指定店舗に所属する担当者情報を更新します。
     *
     * @tags officesUsers
     * @name UsersUpdate
     * @summary ユーザー更新
     * @request PUT:/offices/{office_id}/users/{user_id}
     * @secure
     */
    usersUpdate: (
      userId: number,
      officeId: string,
      data: User,
      params: RequestParams = {}
    ) =>
      this.request<User, any>({
        path: `/offices/${officeId}/users/${userId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 認証したユーザーが所属する企業内の指定店舗に所属する担当者情報を削除します。
     *
     * @tags officesUsers
     * @name UsersDelete
     * @summary ユーザー削除
     * @request DELETE:/offices/{office_id}/users/{user_id}
     * @secure
     */
    usersDelete: (
      userId: number,
      officeId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/offices/${officeId}/users/${userId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  documents = {
    /**
     * @description 自分が作成した独自資料の一覧を返却します。
     *
     * @tags documents
     * @name DocumentsList
     * @summary 独自資料一覧
     * @request GET:/documents
     * @secure
     */
    documentsList: (
      query?: { page?: number; per_page?: number },
      params: RequestParams = {}
    ) =>
      this.request<{ documents: Document[]; total_page_count: number }, any>({
        path: `/documents`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 独自資料を作成します。
     *
     * @tags documents
     * @name DocumentsCreate
     * @summary 独自資料作成
     * @request POST:/documents
     * @secure
     */
    documentsCreate: (data: Document, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/documents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 指定した独自資料の情報を取得します。 自分の作成した独自資料のみ対象です。
     *
     * @tags documents
     * @name DocumentsDetail
     * @summary 独自資料詳細
     * @request GET:/documents/{document_id}
     * @secure
     */
    documentsDetail: (documentId: number, params: RequestParams = {}) =>
      this.request<Document, any>({
        path: `/documents/${documentId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した独自資料の情報を更新します。 自分の作成した独自資料のみ対象です。
     *
     * @tags documents
     * @name DocumentsUpdate
     * @summary 独自資料更新
     * @request PUT:/documents/{document_id}
     * @secure
     */
    documentsUpdate: (
      documentId: number,
      data: Document,
      params: RequestParams = {}
    ) =>
      this.request<Document, any>({
        path: `/documents/${documentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した独自資料の情報を論理削除します。 自分の作成した独自資料のみ対象です。
     *
     * @tags documents
     * @name DocumentsDelete
     * @summary 独自資料削除
     * @request DELETE:/documents/{document_id}
     * @secure
     */
    documentsDelete: (documentId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/documents/${documentId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  pdf2Image = {
    /**
     * @description 独自資料のアップロード用URLを取得します。
     *
     * @tags pdf2image
     * @name Pdf2ImagePostUrl
     * @summary 独自資料のアップロード用URL
     * @request GET:/pdf2image/post_url
     * @secure
     */
    pdf2ImagePostUrl: (query: { key: string }, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/pdf2image/post_url`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 独自資料のアップロード用URLを取得します。
     *
     * @tags pdf2image
     * @name Pdf2ImageImages
     * @summary 独自資料のアップロード用URL
     * @request GET:/pdf2image/images
     * @secure
     */
    pdf2ImageImages: (query: { key: string }, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/pdf2image/images`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  reports = {
    /**
     * No description
     *
     * @tags reports
     * @name ReportsList
     * @summary 査定書一覧
     * @request GET:/reports
     * @secure
     */
    reportsList: (
      query?: {
        page?: number
        category?: string
        keyword?: string
        office_id?: string
        user_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          reports: (
            | Report
            | SpeedModeLandReport
            | SpeedModeHouseReport
            | SpeedModeCondoReport
          )[]
          total_page_count: number
        },
        any
      >({
        path: `/reports`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsCreate
     * @summary 査定書作成
     * @request POST:/reports
     * @secure
     */
    reportsCreate: (
      data:
        | Report
        | SpeedModeLandReport
        | SpeedModeHouseReport
        | SpeedModeCondoReport,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/reports`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsDetail
     * @summary 査定書詳細
     * @request GET:/reports/{report_id}
     * @secure
     */
    reportsDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<
        | Report
        | SpeedModeLandReport
        | SpeedModeHouseReport
        | SpeedModeCondoReport,
        any
      >({
        path: `/reports/${reportId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsUpdate
     * @summary 査定書更新
     * @request PUT:/reports/{report_id}
     * @secure
     */
    reportsUpdate: (
      reportId: number,
      data:
        | Report
        | SpeedModeLandReport
        | SpeedModeHouseReport
        | SpeedModeCondoReport,
      params: RequestParams = {}
    ) =>
      this.request<
        | Report
        | SpeedModeLandReport
        | SpeedModeHouseReport
        | SpeedModeCondoReport,
        any
      >({
        path: `/reports/${reportId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 査定書を論理削除します。 認証したユーザーが作成した査定書のみ対象です。
     *
     * @tags reports
     * @name ReportsDelete
     * @summary 査定書削除
     * @request DELETE:/reports/{report_id}
     * @secure
     */
    reportsDelete: (reportId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reports/${reportId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportEtcPointCommentUpdate
     * @summary 査定書のその他ポイントのコメント更新
     * @request PATCH:/reports/{report_id}/etc_point_comment
     * @secure
     */
    reportEtcPointCommentUpdate: (
      reportId: number,
      data: { etc_point_comment?: string },
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/reports/${reportId}/etc_point_comment`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportBuildingEtcPointCommentUpdate
     * @summary 査定書のその他ポイントのコメント更新
     * @request PATCH:/reports/{report_id}/building_etc_point_comment
     * @secure
     */
    reportBuildingEtcPointCommentUpdate: (
      reportId: number,
      data: { building_etc_point_comment?: string },
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/reports/${reportId}/building_etc_point_comment`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportCustomFieldValuesUpdate
     * @summary 帳票のカスタム属性を更新します。
     * @request PATCH:/reports/{report_id}/report_custom_field_values
     * @secure
     */
    reportCustomFieldValuesUpdate: (
      reportId: number,
      data: ReportCustomFieldValue[],
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/reports/${reportId}/report_custom_field_values`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportBuildingUpdate
     * @summary 査定書の建物情報を更新するエンドポイント
     * @request PATCH:/reports/{report_id}/building
     * @secure
     */
    reportBuildingUpdate: (
      reportId: number,
      data: Building,
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/reports/${reportId}/building`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 査定書複製機能には大きく分けて2つ注意すべき点があります。 1. 複製には物件種別によって要件が異なります。 2. 複製時に引き継いではならない項目があります。 詳細については下記URLのスライドをご参照ください。 要件のスライド: https://docs.google.com/presentation/d/1Lz7YJDpkftKI6x6tj2O_RYk5zl58RvPcDM9SEK5yBXo/edit#slide=id.p
     *
     * @tags reports
     * @name ReportsDuplicateCreate
     * @summary 査定書の複製を作成
     * @request POST:/reports/{report_id}/duplicate
     * @secure
     */
    reportsDuplicateCreate: (
      reportId: number,
      data: Condo,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/duplicate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
 * No description
 * 
 * @tags reports
 * @name PdfExportLogDetail
 * @summary 査定書・レポートの出力

プレビュー画面のPDF出力機能でPDFのURLを取得する際に利用します。

PDFがまだ生成されていない場合はリソースがないので404エラーが返ります。
 * @request GET:/reports/{report_id}/pdf_export_log
 * @secure
 */
    pdfExportLogDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<PdfExportLog, void>({
        path: `/reports/${reportId}/pdf_export_log`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
 * No description
 * 
 * @tags reports
 * @name PdfExportLogUpdate
 * @summary 査定書・レポートの出力

プレビュー画面のPDF出力機能で利用します。
まだ作成されていない場合は査定書・レポートのPDFを作成します。
すでに作成済みの場合は、査定書・レポートのPDFを更新します。
どちらの処理も非同期で実行されるため、PDFのURLは即座に取得できません。

非同期処理の進捗はGETエンドポイントで取得できるステータスを確認してください。
 * @request PUT:/reports/{report_id}/pdf_export_log
 * @secure
 */
    pdfExportLogUpdate: (
      reportId: number,
      data: { target_url: string },
      params: RequestParams = {}
    ) =>
      this.request<PdfExportLog, any>({
        path: `/reports/${reportId}/pdf_export_log`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name UpdateUser
     * @summary 査定書担当者更新
     * @request PATCH:/reports/{report_id}/update_user
     * @secure
     */
    updateUser: (
      reportId: number,
      data: { office_id: number; user_id?: number },
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/reports/${reportId}/update_user`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ExitSimulationCreate
     * @summary レポートに紐づく手残りシミュレーションの作成
     * @request POST:/reports/{report_id}/exit_simulation
     * @secure
     */
    exitSimulationCreate: (
      reportId: string,
      data: ExitSimulation,
      params: RequestParams = {}
    ) =>
      this.request<ExitSimulation[], Error>({
        path: `/reports/${reportId}/exit_simulation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name CustomOfferCreate
     * @summary レポートに紐づく第三の価格の作成
     * @request POST:/reports/{report_id}/custom_offer
     * @secure
     */
    customOfferCreate: (
      reportId: string,
      data: CustomOffer,
      params: RequestParams = {}
    ) =>
      this.request<CustomOffer[], Error>({
        path: `/reports/${reportId}/custom_offer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsUpdateDocuments
     * @summary 査定書に紐づく独自資料を更新
     * @request PATCH:/reports/{report_id}/documents
     * @secure
     */
    reportsUpdateDocuments: (
      reportId: number,
      data: StoryIdList,
      params: RequestParams = {}
    ) =>
      this.request<Report, Error>({
        path: `/reports/${reportId}/documents`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name SpeedModeReportsCreate
     * @summary 査定書作成
     * @request POST:/speed_mode_reports
     * @secure
     */
    speedModeReportsCreate: (
      data:
        | Report
        | SpeedModeLandReport
        | SpeedModeHouseReport
        | SpeedModeCondoReport,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/speed_mode_reports`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name SpeedModeReportsDetail
     * @summary 査定書詳細（スピードモード）
     * @request GET:/speed_mode_reports/{report_id}
     * @secure
     */
    speedModeReportsDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<
        | Report
        | SpeedModeLandReport
        | SpeedModeHouseReport
        | SpeedModeCondoReport,
        any
      >({
        path: `/speed_mode_reports/${reportId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name SpeedModeReportsUpdate
     * @summary 査定書更新（スピードモード）
     * @request PUT:/speed_mode_reports/{report_id}
     * @secure
     */
    speedModeReportsUpdate: (
      reportId: number,
      data:
        | Report
        | SpeedModeLandReport
        | SpeedModeHouseReport
        | SpeedModeCondoReport,
      params: RequestParams = {}
    ) =>
      this.request<
        | Report
        | SpeedModeLandReport
        | SpeedModeHouseReport
        | SpeedModeCondoReport,
        any
      >({
        path: `/speed_mode_reports/${reportId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name SpeedModeReportAssessmentCommentUpdate
     * @summary 査定書の査定コメント更新
     * @request PATCH:/speed_mode_reports/{report_id}/assessment_comment
     * @secure
     */
    speedModeReportAssessmentCommentUpdate: (
      reportId: number,
      data: { assessment_comment?: string },
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/speed_mode_reports/${reportId}/assessment_comment`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name SpeedModeReportCorrectionAndAdjustmentActiveUpdate
     * @summary その他補正率の有効フラグと市場性調整率の有効フラグの更新
     * @request PATCH:/speed_mode_reports/{report_id}/correction_and_adjustment_active
     * @secure
     */
    speedModeReportCorrectionAndAdjustmentActiveUpdate: (
      reportId: number,
      data: {
        other_correction_items_active: string
        adjustment_percentage_active: string
      },
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/speed_mode_reports/${reportId}/correction_and_adjustment_active`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name CondoIncomeReportsCreate
     * @summary 査定書作成（収益還元法）
     * @request POST:/condo_income_reports
     * @secure
     */
    condoIncomeReportsCreate: (data: Report, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/condo_income_reports`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name CondoIncomeReportsDetail
     * @summary 査定書詳細（収益還元法）
     * @request GET:/condo_income_reports/{report_id}
     * @secure
     */
    condoIncomeReportsDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<Report, any>({
        path: `/condo_income_reports/${reportId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name CondoIncomeReportsUpdate
     * @summary 査定書更新（収益還元法）
     * @request PUT:/condo_income_reports/{report_id}
     * @secure
     */
    condoIncomeReportsUpdate: (
      reportId: number,
      data: Report,
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/condo_income_reports/${reportId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name MultiUnitBuildingReportsCreate
     * @summary 査定書作成（一棟査定）
     * @request POST:/multi_unit_building_reports
     * @secure
     */
    multiUnitBuildingReportsCreate: (
      data: Report,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/multi_unit_building_reports`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name MultiUnitBuildingReportsDetail
     * @summary 査定書詳細（一棟査定）
     * @request GET:/multi_unit_building_reports/{report_id}
     * @secure
     */
    multiUnitBuildingReportsDetail: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/multi_unit_building_reports/${reportId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name MultiUnitBuildingReportsUpdate
     * @summary 査定書更新（一棟査定）
     * @request PUT:/multi_unit_building_reports/{report_id}
     * @secure
     */
    multiUnitBuildingReportsUpdate: (
      reportId: number,
      data: Report,
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/multi_unit_building_reports/${reportId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  stories = {
    /**
     * No description
     *
     * @tags stories
     * @name StoriesList
     * @summary 事例一覧
     * @request GET:/reports/{report_id}/stories
     * @secure
     */
    storiesList: (
      reportId: number,
      query?: {
        use?:
          | 'cost_approach_assessment'
          | 'market_approach_assessment'
          | 'competitor_analysis'
        source_type?: 'db' | 'posted'
        page?: number
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<(Story | CondoStory | (Story & CondoStory))[], any>({
        path: `/reports/${reportId}/stories`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name StoriesCreate
     * @summary 事例作成
     * @request POST:/reports/{report_id}/stories
     * @secure
     */
    storiesCreate: (
      reportId: number,
      data: Story | CondoStory | (Story & CondoStory),
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/stories`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name StoriesDetail
     * @summary 事例詳細
     * @request GET:/reports/{report_id}/stories/{story_id}
     * @secure
     */
    storiesDetail: (
      reportId: number,
      storyId: number,
      params: RequestParams = {}
    ) =>
      this.request<Story | CondoStory | (Story & CondoStory), any>({
        path: `/reports/${reportId}/stories/${storyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name StoriesUpdate
     * @summary 事例更新
     * @request PUT:/reports/{report_id}/stories/{story_id}
     * @secure
     */
    storiesUpdate: (
      reportId: number,
      storyId: number,
      data: Story | CondoStory | (Story & CondoStory),
      params: RequestParams = {}
    ) =>
      this.request<Story | CondoStory | (Story & CondoStory), any>({
        path: `/reports/${reportId}/stories/${storyId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name StoriesDelete
     * @summary 事例削除
     * @request DELETE:/reports/{report_id}/stories/{story_id}
     * @secure
     */
    storiesDelete: (
      storyId: number,
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/reports/${reportId}/stories/${storyId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name StoriesUpdateScores
     * @summary 評価ポイントの更新
     * @request PATCH:/reports/{report_id}/stories/scores
     * @secure
     */
    storiesUpdateScores: (
      reportId: number,
      data: { id?: number; score?: Score; etc_point_comment?: string }[],
      params: RequestParams = {}
    ) =>
      this.request<
        { id?: number; score?: Score; etc_point_comment?: string }[],
        Error
      >({
        path: `/reports/${reportId}/stories/scores`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name StoriesUpdateWeights
     * @summary ウェイトの更新
     * @request PATCH:/reports/{report_id}/stories/weights
     * @secure
     */
    storiesUpdateWeights: (
      reportId: number,
      data: { id?: number; weight?: number }[],
      params: RequestParams = {}
    ) =>
      this.request<{ id?: number; weight?: number }[], Error>({
        path: `/reports/${reportId}/stories/weights`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name ImageMaskingDetail
     * @summary 事例に紐づく画像のマスキングデータの詳細
     * @request GET:/reports/{report_id}/stories/{story_id}/image_masking
     * @secure
     */
    imageMaskingDetail: (
      reportId: number,
      storyId: number,
      params: RequestParams = {}
    ) =>
      this.request<ImageMasking, any>({
        path: `/reports/${reportId}/stories/${storyId}/image_masking`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 事例に紐づく画像のマスキングデータを作成します。
     *
     * @tags stories
     * @name ImageMaskingsCreate
     * @summary 事例に紐づく画像のマスキングデータの作成
     * @request POST:/reports/{report_id}/stories/{story_id}/image_masking
     * @secure
     */
    imageMaskingsCreate: (
      reportId: number,
      storyId: number,
      data: ImageMasking,
      params: RequestParams = {}
    ) =>
      this.request<ImageMasking, Error>({
        path: `/reports/${reportId}/stories/${storyId}/image_masking`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name ImageMaskingUpdate
     * @summary 事例に紐づく画像のマスキングデータを更新
     * @request PATCH:/reports/{report_id}/stories/{story_id}/image_masking
     * @secure
     */
    imageMaskingUpdate: (
      reportId: number,
      storyId: number,
      data: ImageMasking,
      params: RequestParams = {}
    ) =>
      this.request<ImageMasking, Error>({
        path: `/reports/${reportId}/stories/${storyId}/image_masking`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  reportAccessLogs = {
    /**
     * No description
     *
     * @tags report_access_logs
     * @name ReportAccessLogsCreate
     * @summary 履歴作成
     * @request POST:/reports/{report_id}/report_access_logs
     * @secure
     */
    reportAccessLogsCreate: (reportId: number, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/report_access_logs`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  }
  storiesImportFiles = {
    /**
     * @description 取込中
     *
     * @tags storiesImportFiles
     * @name StoriesImportFilesList
     * @summary 取り込み用事例データファイル一覧
     * @request GET:/reports/{report_id}/stories_import_files
     * @secure
     */
    storiesImportFilesList: (
      reportId: number,
      query?: {
        status?: ImportFileStatus
        use?:
          | 'cost_approach_assessment'
          | 'market_approach_assessment'
          | 'sale_price_approach_assessment'
          | 'competitor_analysis'
      },
      params: RequestParams = {}
    ) =>
      this.request<StoriesImportFile[], any>({
        path: `/reports/${reportId}/stories_import_files`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description POSTするとS3のファイルを解析して取り込む処理が非同期で実行されます。
     *
     * @tags storiesImportFiles
     * @name StoriesImportFilesCreate
     * @summary 取り込み用事例データファイル作成
     * @request POST:/reports/{report_id}/stories_import_files
     * @secure
     */
    storiesImportFilesCreate: (
      reportId: number,
      data: StoriesImportFile,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/stories_import_files`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  assessments = {
    /**
     * No description
     *
     * @tags assessments
     * @name AssessmentsDetail
     * @summary 査定データの詳細
     * @request GET:/reports/{report_id}/assessments/{category}
     * @secure
     */
    assessmentsDetail: (
      reportId: number,
      category: AssessmentCategory,
      params: RequestParams = {}
    ) =>
      this.request<Assessment, Error>({
        path: `/reports/${reportId}/assessments/${category}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments
     * @name AssessmentsUpdate
     * @summary 査定データの更新
     * @request PATCH:/reports/{report_id}/assessments/{category}
     * @secure
     */
    assessmentsUpdate: (
      reportId: number,
      category: AssessmentCategory,
      data: Assessment,
      params: RequestParams = {}
    ) =>
      this.request<Assessment, Error>({
        path: `/reports/${reportId}/assessments/${category}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments
     * @name AssessmentsUpdateStories
     * @summary 査定データの更新(紐づくstoryを変更)
     * @request PUT:/reports/{report_id}/assessments/{category}/stories
     * @secure
     */
    assessmentsUpdateStories: (
      reportId: number,
      category: AssessmentCategory,
      data: StoryIdList,
      params: RequestParams = {}
    ) =>
      this.request<Assessment, Error>({
        path: `/reports/${reportId}/assessments/${category}/stories`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description このエンドポイントは、査定書に紐づくstory(賃料事例)を更新します。 収益還元法のみで使用されるエンドポイントのため、categoryパラメータは'income'に限定されます。 requestBodyで指定されたIDリストに基づいて、story(賃料事例)の関連付けを更新します。
     *
     * @tags assessments
     * @name AssessmentsUpdateInvestmentStories
     * @summary 査定データの更新(紐づくstoryを変更)
     * @request PUT:/reports/{report_id}/assessments/{category}/investment_stories
     * @secure
     */
    assessmentsUpdateInvestmentStories: (
      reportId: number,
      category: AssessmentCategory,
      data: StoryIdList,
      params: RequestParams = {}
    ) =>
      this.request<Assessment, Error>({
        path: `/reports/${reportId}/assessments/${category}/investment_stories`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description このエンドポイントは、査定書に紐づくstory(利回り事例)を更新します。 収益還元法のみで使用されるエンドポイントのため、categoryパラメータは'income'に限定されます。 requestBodyで指定されたIDリストに基づいて、story(利回り事例)の関連付けを更新します。
     *
     * @tags assessments
     * @name AssessmentsUpdateRentStories
     * @summary 査定データの更新(紐づくstoryを変更)
     * @request PUT:/reports/{report_id}/assessments/{category}/rent_stories
     * @secure
     */
    assessmentsUpdateRentStories: (
      reportId: number,
      category: AssessmentCategory,
      data: StoryIdList,
      params: RequestParams = {}
    ) =>
      this.request<Assessment, Error>({
        path: `/reports/${reportId}/assessments/${category}/rent_stories`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description このエンドポイントは特定の査定に対して複数の利回り調整項目を一括で作成・更新するために使用されます。 エンドポイントを叩く度、入れ替わるように査定に紐づく利回り調整項目は全て削除され、新しい利回り調整項目が登録されます。 その調整項目に応じて、査定の利回りが調整されますが、その結果を元に査定価格は再計算されます。
     *
     * @tags assessments
     * @name AssessmentsSaveAdjustmentYieldItems
     * @summary 利回り調整項目の一括更新
     * @request PUT:/reports/{report_id}/assessments/{category}/adjustment_yield_items
     * @secure
     */
    assessmentsSaveAdjustmentYieldItems: (
      reportId: number,
      category: AssessmentCategory,
      data: { adjustment_yield_items?: AdjustmentYieldItem[] },
      params: RequestParams = {}
    ) =>
      this.request<AdjustmentYieldItem[], Error>({
        path: `/reports/${reportId}/assessments/${category}/adjustment_yield_items`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  competitorAnalyses = {
    /**
     * No description
     *
     * @tags competitorAnalyses
     * @name CompetitorAnalysesUpdateStories
     * @summary 競合分析データの更新(紐づくstoryを変更)
     * @request PUT:/reports/{report_id}/competitor_analysis/stories
     * @secure
     */
    competitorAnalysesUpdateStories: (
      reportId: number,
      data: StoryIdList,
      params: RequestParams = {}
    ) =>
      this.request<CompetitorAnalysis, Error>({
        path: `/reports/${reportId}/competitor_analysis/stories`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  printSettings = {
    /**
     * No description
     *
     * @tags printSettings
     * @name PrintSettingsDetail
     * @summary 印刷設定
     * @request GET:/reports/{report_id}/print_setting
     * @secure
     */
    printSettingsDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<PrintSetting, any>({
        path: `/reports/${reportId}/print_setting`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags printSettings
     * @name PrintSettingUpdate
     * @summary 印刷設定更新
     * @request PUT:/reports/{report_id}/print_setting
     * @secure
     */
    printSettingUpdate: (
      reportId: number,
      data: PrintSettingInput,
      params: RequestParams = {}
    ) =>
      this.request<PrintSetting, any>({
        path: `/reports/${reportId}/print_setting`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  reportPhotos = {
    /**
     * @description 帳票に紐づく画像の一覧を返却します。 categoryでフィルターできます。
     *
     * @tags report_photos
     * @name ReportPhotosList
     * @summary 帳票画像の一覧
     * @request GET:/reports/{report_id}/report_photos
     * @secure
     */
    reportPhotosList: (
      reportId: string,
      query?: { category?: string },
      params: RequestParams = {}
    ) =>
      this.request<
        { report_photos: ReportPhoto[]; total_page_count: number },
        any
      >({
        path: `/reports/${reportId}/report_photos`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 帳票に紐づく画像を作成します。
     *
     * @tags report_photos
     * @name ReportPhotosCreate
     * @summary 帳票画像の作成
     * @request POST:/reports/{report_id}/report_photos
     * @secure
     */
    reportPhotosCreate: (
      reportId: string,
      data: ReportPhoto,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/report_photos`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 指定した帳票写真IDの情報を返却します。
     *
     * @tags report_photos
     * @name ReportPhotosDetail
     * @summary 帳票写真の詳細
     * @request GET:/reports/{report_id}/report_photos/{report_photo_id}
     * @secure
     */
    reportPhotosDetail: (
      reportPhotoId: number,
      reportId: string,
      params: RequestParams = {}
    ) =>
      this.request<ReportPhoto, any>({
        path: `/reports/${reportId}/report_photos/${reportPhotoId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した帳票写真IDの情報を更新します。
     *
     * @tags report_photos
     * @name ReportPhotosUpdate
     * @summary 帳票写真の更新
     * @request PUT:/reports/{report_id}/report_photos/{report_photo_id}
     * @secure
     */
    reportPhotosUpdate: (
      reportPhotoId: number,
      reportId: string,
      data: ReportPhoto,
      params: RequestParams = {}
    ) =>
      this.request<ReportPhoto, any>({
        path: `/reports/${reportId}/report_photos/${reportPhotoId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した帳票写真の情報を削除します。
     *
     * @tags report_photos
     * @name ReportPhotosDelete
     * @summary 帳票写真の削除
     * @request DELETE:/reports/{report_id}/report_photos/{report_photo_id}
     * @secure
     */
    reportPhotosDelete: (
      reportPhotoId: number,
      reportId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/reports/${reportId}/report_photos/${reportPhotoId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  reportReactionContacts = {
    /**
     * No description
     *
     * @tags reportReactionContacts
     * @name ReportReactionContacts
     * @summary リアクションメール送信
     * @request POST:/reports/{report_id}/report_reaction_contacts
     * @secure
     */
    reportReactionContacts: (
      reportId: number,
      data: { message: string },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/reports/${reportId}/report_reaction_contacts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  stations = {
    /**
     * No description
     *
     * @tags stations
     * @name StationsList
     * @summary 駅名一覧
     * @request GET:/stations
     * @secure
     */
    stationsList: (
      query: { line_cd: number; page?: number; lat: number; lng: number },
      params: RequestParams = {}
    ) =>
      this.request<Stations, any>({
        path: `/stations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  lines = {
    /**
     * @description 指定した緯度経度から10km以内の駅レコードに紐づく路線を取得します。
     *
     * @tags lines
     * @name LinesList
     * @summary 路線一覧を取得
     * @request GET:/lines
     * @secure
     */
    linesList: (
      query: { lat: number; lng: number },
      params: RequestParams = {}
    ) =>
      this.request<Lines, any>({
        path: `/lines`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  buildings = {
    /**
     * No description
     *
     * @tags buildings
     * @name BuildingsList
     * @summary 指定した文字列を名称に含むマンションの一覧
     * @request GET:/db/buildings
     * @secure
     */
    buildingsList: (
      query: { name: string; address: string },
      params: RequestParams = {}
    ) =>
      this.request<DBBuilding[], any>({
        path: `/db/buildings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags buildings
     * @name BuildingsDetail
     * @summary マンション一覧から選択された建物情報の詳細を返す
     * @request GET:/db/buildings/{building_id}
     * @secure
     */
    buildingsDetail: (buildingId: string, params: RequestParams = {}) =>
      this.request<DetailedDBBuilding, any>({
        path: `/db/buildings/${buildingId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  rooms = {
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsList
     * @summary 指定したマンションIDの部屋一覧
     * @request GET:/db/rooms
     * @secure
     */
    roomsList: (query: { building_id: number }, params: RequestParams = {}) =>
      this.request<DBRoom[], any>({
        path: `/db/rooms`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags rooms
     * @name RoomsDetail
     * @summary 部屋一覧から選択された部屋情報の詳細を返す
     * @request GET:/db/buildings/{building_id}/room
     * @secure
     */
    roomsDetail: (
      buildingId: number,
      query: { name: string; area_m2: number },
      params: RequestParams = {}
    ) =>
      this.request<DetailedDBRoom, any>({
        path: `/db/buildings/${buildingId}/room`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  dbUseDistricts = {
    /**
     * No description
     *
     * @tags db/use_districts
     * @name UseDistrictDetail
     * @summary 指定した緯度経度の近隣の用途地域情報を返却します。
     * @request GET:/db/use_districts
     * @secure
     */
    useDistrictDetail: (
      query: { lat: number; lng: number; distance?: number },
      params: RequestParams = {}
    ) =>
      this.request<DBUseDistrict[], any>({
        path: `/db/use_districts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  dbUrbanAreas = {
    /**
     * No description
     *
     * @tags db/urban_areas
     * @name UrbanAreaDetail
     * @summary 指定した緯度経度の近隣の都市地域データを返却します。
     * @request GET:/db/urban_areas
     * @secure
     */
    urbanAreaDetail: (
      query: { lat: number; lng: number; distance?: number },
      params: RequestParams = {}
    ) =>
      this.request<DBUrbanArea[], any>({
        path: `/db/urban_areas`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  presignedUrl = {
    /**
 * No description
 * 
 * @tags presignedUrl
 * @name PresignedUrl
 * @summary S3のプリサインURLを取得する。
事例の添付資料などのアップロード、ダウンロードに使用する。
 * @request GET:/presigned_url
 * @secure
 */
    presignedUrl: (query: { key: string }, params: RequestParams = {}) =>
      this.request<PresignedUrl, any>({
        path: `/presigned_url`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  postedLandPriceStories = {
    /**
     * No description
     *
     * @tags postedLandPriceStories
     * @name PostedLandPriceStoriesList
     * @summary 公示地価一覧（半径2キロ圏内）
     * @request GET:/reports/{report_id}/posted_land_price_stories
     * @secure
     */
    postedLandPriceStoriesList: (
      reportId: string,
      params: RequestParams = {}
    ) =>
      this.request<PostedLandPriceStories, any>({
        path: `/reports/${reportId}/posted_land_price_stories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postedLandPriceStories
     * @name PostedLandPriceUpdateStories
     * @summary 相場情報の事例一覧を更新する
     * @request PUT:/reports/{report_id}/market_analysis/posted_land_price_stories
     * @secure
     */
    postedLandPriceUpdateStories: (
      reportId: number,
      data: StoryIdList,
      params: RequestParams = {}
    ) =>
      this.request<PostedLandPriceStory, Error>({
        path: `/reports/${reportId}/market_analysis/posted_land_price_stories`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  sellStoriesStatistics = {
    /**
     * No description
     *
     * @tags sellStoriesStatistics
     * @name CondoSellStoriesStatisticDetail
     * @summary MS売買事例の統計量を返却する
     * @request GET:/reports/{report_id}/condo_sell_stories_statistic
     * @secure
     */
    condoSellStoriesStatisticDetail: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<CondoSellStoriesStatistic, any>({
        path: `/reports/${reportId}/condo_sell_stories_statistic`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sellStoriesStatistics
     * @name HouseSellStoriesStatisticDetail
     * @summary 戸建売買事例の統計量を返却する
     * @request GET:/reports/{report_id}/house_sell_stories_statistic
     * @secure
     */
    houseSellStoriesStatisticDetail: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<HouseSellStoriesStatistic, any>({
        path: `/reports/${reportId}/house_sell_stories_statistic`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sellStoriesStatistics
     * @name LandSellStoriesStatisticDetail
     * @summary 土地売買事例の統計量を返却する
     * @request GET:/reports/{report_id}/land_sell_stories_statistic
     * @secure
     */
    landSellStoriesStatisticDetail: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<LandSellStoriesStatistic, any>({
        path: `/reports/${reportId}/land_sell_stories_statistic`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  spots = {
    /**
     * No description
     *
     * @tags spots
     * @name SpotsList
     * @summary レポートに紐づく周辺施設一覧
     * @request GET:/reports/{report_id}/spots
     * @secure
     */
    spotsList: (reportId: number, params: RequestParams = {}) =>
      this.request<Spot[], any>({
        path: `/reports/${reportId}/spots`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  useDistricts = {
    /**
     * No description
     *
     * @tags useDistricts
     * @name UseDistrictList
     * @summary レポートに紐づく用途地域一覧
     * @request GET:/reports/{report_id}/use_districts
     * @secure
     */
    useDistrictList: (reportId: number, params: RequestParams = {}) =>
      this.request<UseDistrict[], any>({
        path: `/reports/${reportId}/use_districts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  urbanAreas = {
    /**
     * No description
     *
     * @tags urbanAreas
     * @name UrbanAreasList
     * @summary レポートに紐づく都市地域データ一覧
     * @request GET:/reports/{report_id}/urban_areas
     * @secure
     */
    urbanAreasList: (
      reportId: number,
      query?: { area_classification?: AreaClassification },
      params: RequestParams = {}
    ) =>
      this.request<UrbanArea[], any>({
        path: `/reports/${reportId}/urban_areas`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  crowdCities = {
    /**
     * No description
     *
     * @tags crowdCities
     * @name CrowdCitiesList
     * @summary レポートに紐づく密集市街地
     * @request GET:/reports/{report_id}/crowd_cities
     * @secure
     */
    crowdCitiesList: (reportId: number, params: RequestParams = {}) =>
      this.request<CrowdCity[], any>({
        path: `/reports/${reportId}/crowd_cities`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  elevationAndSlopeAngleFifthMeshes = {
    /**
     * No description
     *
     * @tags elevationAndSlopeAngleFifthMeshes
     * @name ElevationAndSlopeAngleFifthMeshesList
     * @summary 標高・傾斜度5次メッシュ情報一覧API
     * @request GET:/reports/{report_id}/elevation_and_slope_angle_fifth_meshes
     * @secure
     */
    elevationAndSlopeAngleFifthMeshesList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<ElevationAndSlopeAngleFifthMesh[], any>({
        path: `/reports/${reportId}/elevation_and_slope_angle_fifth_meshes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  lowPriceAreas = {
    /**
     * No description
     *
     * @tags lowPriceAreas
     * @name LowPriceAreasList
     * @summary 低位地帯データ情報一覧API
     * @request GET:/reports/{report_id}/low_price_areas
     * @secure
     */
    lowPriceAreasList: (reportId: number, params: RequestParams = {}) =>
      this.request<LowPriceArea[], any>({
        path: `/reports/${reportId}/low_price_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  sedimentDisasterHazardAreas = {
    /**
     * No description
     *
     * @tags sedimentDisasterHazardAreas
     * @name SedimentDisasterHazardAreasList
     * @summary レポートに紐づく危険箇所データ
     * @request GET:/reports/{report_id}/sediment_disaster_hazard_areas
     * @secure
     */
    sedimentDisasterHazardAreasList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<SedimentDisasterHazardArea, any>({
        path: `/reports/${reportId}/sediment_disaster_hazard_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  expectedTsunamiInundatedAreas = {
    /**
     * No description
     *
     * @tags expectedTsunamiInundatedAreas
     * @name ExpectedTsunamiInundatedAreasList
     * @summary 津波浸水想定データ一覧API
     * @request GET:/reports/{report_id}/expected_tsunami_inundated_areas
     * @secure
     */
    expectedTsunamiInundatedAreasList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<ExpectedTsunamiInundatedArea[], any>({
        path: `/reports/${reportId}/expected_tsunami_inundated_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  areasExpectedToBeFloodedByStormSurges = {
    /**
     * No description
     *
     * @tags areasExpectedToBeFloodedByStormSurges
     * @name AreasExpectedToBeFloodedByStormSurgesList
     * @summary 高潮浸水想定データ一覧API
     * @request GET:/reports/{report_id}/areas_expected_to_be_flooded_by_storm_surges
     * @secure
     */
    areasExpectedToBeFloodedByStormSurgesList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<AreasExpectedToBeFloodedByStormSurge[], any>({
        path: `/reports/${reportId}/areas_expected_to_be_flooded_by_storm_surges`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  inundationHazardousAreas = {
    /**
     * No description
     *
     * @tags inundationHazardousAreas
     * @name InundationHazardousAreasList
     * @summary 河川浸水想定データ一覧API
     * @request GET:/reports/{report_id}/inundation_hazardous_areas
     * @secure
     */
    inundationHazardousAreasList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<InundationHazardousArea[], any>({
        path: `/reports/${reportId}/inundation_hazardous_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  japanSeismicHazard = {
    /**
     * No description
     *
     * @tags japanSeismicHazard
     * @name JapanSeismicHazardFaultsList
     * @summary 国立研究開発法人防災科学技術研究所の活断層一覧API
     * @request GET:/reports/{report_id}/japan_seismic_hazard_faults
     * @secure
     */
    japanSeismicHazardFaultsList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<JapanSeismicHazardFault[], any>({
        path: `/reports/${reportId}/japan_seismic_hazard_faults`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags japanSeismicHazard
     * @name JapanSeismicHazardMeshesList
     * @summary 国立研究開発法人防災科学技術研究所のメッシュ一覧API
     * @request GET:/reports/{report_id}/japan_seismic_hazard_meshes
     * @secure
     */
    japanSeismicHazardMeshesList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<JapanSeismicHazardMesh[], any>({
        path: `/reports/${reportId}/japan_seismic_hazard_meshes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  condoSellStories = {
    /**
     * No description
     *
     * @tags condoSellStories
     * @name CondoSellStoriesList
     * @summary MS事例一覧API
     * @request GET:/reports/{report_id}/condo_sell_stories
     * @secure
     */
    condoSellStoriesList: (reportId: number, params: RequestParams = {}) =>
      this.request<CondoSellStory[], any>({
        path: `/reports/${reportId}/condo_sell_stories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  houseSellStories = {
    /**
     * No description
     *
     * @tags houseSellStories
     * @name HouseSellStoriesList
     * @summary 戸建事例一覧API
     * @request GET:/reports/{report_id}/house_sell_stories
     * @secure
     */
    houseSellStoriesList: (reportId: number, params: RequestParams = {}) =>
      this.request<HouseSellStory[], any>({
        path: `/reports/${reportId}/house_sell_stories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  landSellStories = {
    /**
     * No description
     *
     * @tags landSellStories
     * @name LandSellStoriesList
     * @summary 土地事例一覧API
     * @request GET:/reports/{report_id}/land_sell_stories
     * @secure
     */
    landSellStoriesList: (reportId: number, params: RequestParams = {}) =>
      this.request<LandSellStory[], any>({
        path: `/reports/${reportId}/land_sell_stories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  statisticAreas = {
    /**
     * No description
     *
     * @tags statisticAreas
     * @name CondoStatisticAreasList
     * @summary MS地域別の事例統計量の一覧API
     * @request GET:/reports/{report_id}/condo_statistic_areas
     * @secure
     */
    condoStatisticAreasList: (reportId: number, params: RequestParams = {}) =>
      this.request<CondoStatisticArea[], any>({
        path: `/reports/${reportId}/condo_statistic_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags statisticAreas
     * @name HouseStatisticAreasList
     * @summary 戸建の地域別の事例統計量の一覧API
     * @request GET:/reports/{report_id}/house_statistic_areas
     * @secure
     */
    houseStatisticAreasList: (reportId: number, params: RequestParams = {}) =>
      this.request<HouseStatisticArea[], any>({
        path: `/reports/${reportId}/house_statistic_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags statisticAreas
     * @name LandStatisticAreasList
     * @summary 土地の地域別の事例統計量の一覧API
     * @request GET:/reports/{report_id}/land_statistic_areas
     * @secure
     */
    landStatisticAreasList: (reportId: number, params: RequestParams = {}) =>
      this.request<LandStatisticArea[], any>({
        path: `/reports/${reportId}/land_statistic_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  heatMapEstimatePrices = {
    /**
     * No description
     *
     * @tags heatMapEstimatePrices
     * @name HeatMapEstimatePricesList
     * @summary MS推定値ポリゴン情報一覧API
     * @request GET:/reports/{report_id}/heat_map_estimate_prices
     * @secure
     */
    heatMapEstimatePricesList: (reportId: number, params: RequestParams = {}) =>
      this.request<HeatMapEstimatePrice[], any>({
        path: `/reports/${reportId}/heat_map_estimate_prices`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  rentHeatMapEstimatePrices = {
    /**
     * No description
     *
     * @tags rentHeatMapEstimatePrices
     * @name RentHeatMapEstimatePricesList
     * @summary MS賃料推定値ポリゴン情報一覧API
     * @request GET:/reports/{report_id}/rent_heat_map_estimate_prices
     * @secure
     */
    rentHeatMapEstimatePricesList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<RentHeatMapEstimatePrice[], any>({
        path: `/reports/${reportId}/rent_heat_map_estimate_prices`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  transportNodes = {
    /**
     * No description
     *
     * @tags transportNodes
     * @name TransportNodesList
     * @summary レポートに紐づく交通機関(駅・バス停)一覧
     * @request GET:/reports/{report_id}/transport_nodes
     * @secure
     */
    transportNodesList: (reportId: number, params: RequestParams = {}) =>
      this.request<TransportNode[], any>({
        path: `/reports/${reportId}/transport_nodes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  transportLinks = {
    /**
     * No description
     *
     * @tags transportLinks
     * @name TransportLinksList
     * @summary レポートに紐づく路線一覧
     * @request GET:/reports/{report_id}/transport_links
     * @secure
     */
    transportLinksList: (reportId: number, params: RequestParams = {}) =>
      this.request<TransportLink[], any>({
        path: `/reports/${reportId}/transport_links`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  reportAuthentication = {
    /**
     * No description
     *
     * @tags ReportAuthentication
     * @name Login
     * @summary 共有URL認証API
     * @request POST:/reports/{report_id}/shared_reports/{token}/log_in
     * @secure
     */
    login: (
      reportId: string,
      token: string,
      data: { password: string },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/reports/${reportId}/shared_reports/${token}/log_in`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportAuthentication
     * @name Create
     * @summary 共有URL作成API
     * @request POST:/reports/{report_id}/report_authentications
     * @secure
     */
    create: (
      reportId: string,
      data: { password: string },
      params: RequestParams = {}
    ) =>
      this.request<ReportAuthentication, any>({
        path: `/reports/${reportId}/report_authentications`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportAuthentication
     * @name Update
     * @summary 共有URLパスワード変更API
     * @request PATCH:/reports/{report_id}/report_authentications/{report_authentication_id}
     * @secure
     */
    update: (
      reportId: string,
      reportAuthenticationId: string,
      data: { password?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<ReportAuthentication, any>({
        path: `/reports/${reportId}/report_authentications/${reportAuthenticationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  heatMapIncomePerPerson = {
    /**
     * No description
     *
     * @tags heatMapIncomePerPerson
     * @name HeatMapIncomePerPersonList
     * @summary 年収マップ一覧API
     * @request GET:/reports/{report_id}/heat_map_income_per_people
     * @secure
     */
    heatMapIncomePerPersonList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<HeatMapIncomePerPerson[], any>({
        path: `/reports/${reportId}/heat_map_income_per_people`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  exitSimulation = {
    /**
     * No description
     *
     * @tags exitSimulation
     * @name ExitSimulationDetail
     * @summary レポートに紐づく手残りシミュレーション
     * @request GET:/reports/{report_id}/exit_simulation
     * @secure
     */
    exitSimulationDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<ExitSimulation | null, any>({
        path: `/reports/${reportId}/exit_simulation`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags exitSimulation
     * @name ExitSimulationItemUpdate
     * @summary レポートに紐づく手残りシミュレーションを更新
     * @request PUT:/reports/{report_id}/exit_simulation
     * @secure
     */
    exitSimulationItemUpdate: (
      reportId: number,
      data: ExitSimulation,
      params: RequestParams = {}
    ) =>
      this.request<ExitSimulation[], any>({
        path: `/reports/${reportId}/exit_simulation`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags exitSimulation
     * @name ExitSimulationItemDelete
     * @summary レポートに紐づく手残りシミュレーションを削除
     * @request DELETE:/reports/{report_id}/exit_simulation
     * @secure
     */
    exitSimulationItemDelete: (reportId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reports/${reportId}/exit_simulation`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  customOffer = {
    /**
     * No description
     *
     * @tags customOffer
     * @name CustomOfferDetail
     * @summary レポートに紐づく第三の価格
     * @request GET:/reports/{report_id}/custom_offer
     * @secure
     */
    customOfferDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<CustomOffer | null, any>({
        path: `/reports/${reportId}/custom_offer`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags customOffer
     * @name CustomOfferUpdate
     * @summary レポートに紐づく第三の価格を更新
     * @request PUT:/reports/{report_id}/custom_offer
     * @secure
     */
    customOfferUpdate: (
      reportId: number,
      data: CustomOffer,
      params: RequestParams = {}
    ) =>
      this.request<CustomOffer[], any>({
        path: `/reports/${reportId}/custom_offer`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags customOffer
     * @name CustomOfferDelete
     * @summary レポートに紐づく第三の価格を削除
     * @request DELETE:/reports/{report_id}/custom_offer
     * @secure
     */
    customOfferDelete: (reportId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reports/${reportId}/custom_offer`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  areaSummary = {
    /**
     * No description
     *
     * @tags areaSummary
     * @name AreaSummaryDetail
     * @summary 地域特性API
     * @request GET:/reports/{report_id}/area_summary
     * @secure
     */
    areaSummaryDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<AreaSummary, any>({
        path: `/reports/${reportId}/area_summary`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  contractorAnalysis = {
    /**
     * No description
     *
     * @tags contractorAnalysis
     * @name ContractorAnalysisDetail
     * @summary 成約者分析データ
     * @request GET:/reports/{report_id}/contractor_analysis
     * @secure
     */
    contractorAnalysisDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<ContractorAnalysis, any>({
        path: `/reports/${reportId}/contractor_analysis`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  schoolDistricts = {
    /**
 * No description
 * 
 * @tags schoolDistricts
 * @name SchoolDistrictsList
 * @summary レポートに紐づく学区一覧
レスポンスは配列形式ですが、基本的には1件のみ返却されます。
また学区が存在しない場合は空の配列が返却されます。
 * @request GET:/reports/{report_id}/school_districts
 * @secure
 */
    schoolDistrictsList: (reportId: number, params: RequestParams = {}) =>
      this.request<SchoolDistrict[], any>({
        path: `/reports/${reportId}/school_districts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  wait = {
    /**
     * No description
     *
     * @tags wait
     * @name Wait
     * @summary Wait処理用のエンドポイント
     * @request GET:/wait
     * @secure
     */
    wait: (params: RequestParams = {}) =>
      this.request<{ message?: string }, any>({
        path: `/wait`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  roadPrices = {
    /**
     * No description
     *
     * @tags roadPrices
     * @name RoadPricesCreate
     * @summary 路線価作成
     * @request POST:/reports/{report_id}/road_prices
     * @secure
     */
    roadPricesCreate: (
      reportId: number,
      data: RoadPrice,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/road_prices`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags roadPrices
     * @name RoadPricesDetail
     * @summary 路線価詳細
     * @request GET:/reports/{report_id}/road_prices/{road_price_id}
     * @secure
     */
    roadPricesDetail: (
      reportId: number,
      roadPriceId: number,
      params: RequestParams = {}
    ) =>
      this.request<RoadPrice, any>({
        path: `/reports/${reportId}/road_prices/${roadPriceId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags roadPrices
     * @name RoadPricesUpdate
     * @summary 路線価更新
     * @request PUT:/reports/{report_id}/road_prices/{road_price_id}
     * @secure
     */
    roadPricesUpdate: (
      reportId: number,
      roadPriceId: number,
      data: RoadPrice,
      params: RequestParams = {}
    ) =>
      this.request<RoadPrice, any>({
        path: `/reports/${reportId}/road_prices/${roadPriceId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags roadPrices
     * @name RoadPricesDelete
     * @summary 路線価削除
     * @request DELETE:/reports/{report_id}/road_prices/{road_price_id}
     * @secure
     */
    roadPricesDelete: (
      reportId: number,
      roadPriceId: number,
      params: RequestParams = {}
    ) =>
      this.request<RoadPrice, any>({
        path: `/reports/${reportId}/road_prices/${roadPriceId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  depreciationRateItems = {
    /**
     * No description
     *
     * @tags depreciationRateItems
     * @name DepreciationRateItemsCreate
     * @summary 減価率項目作成
     * @request POST:/reports/{report_id}/depreciation_rate_items
     * @secure
     */
    depreciationRateItemsCreate: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/depreciation_rate_items`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags depreciationRateItems
     * @name DepreciationRateItemsUpdate
     * @summary 減価率項目更新
     * @request PUT:/reports/{report_id}/depreciation_rate_items/{depreciation_rate_item_id}
     * @secure
     */
    depreciationRateItemsUpdate: (
      reportId: number,
      depreciationRateItemId: number,
      data: DepreciationRateItem,
      params: RequestParams = {}
    ) =>
      this.request<DepreciationRateItem, any>({
        path: `/reports/${reportId}/depreciation_rate_items/${depreciationRateItemId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  otherCorrectionItems = {
    /**
     * No description
     *
     * @tags otherCorrectionItems
     * @name OtherCorrectionItemsCreate
     * @summary その他補正の調整項目作成
     * @request POST:/reports/{report_id}/assessments/{assessment_category}/other_correction_items
     * @secure
     */
    otherCorrectionItemsCreate: (
      reportId: number,
      assessmentCategory: AssessmentCategory,
      data: OtherCorrectionItem,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/assessments/${assessmentCategory}/other_correction_items`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags otherCorrectionItems
     * @name OtherCorrectionItemsDetail
     * @summary その他補正の調整項目詳細
     * @request GET:/reports/{report_id}/assessments/{assessment_category}/other_correction_items/{other_correction_item_id}
     * @secure
     */
    otherCorrectionItemsDetail: (
      reportId: number,
      category: AssessmentCategory,
      otherCorrectionItemId: number,
      assessmentCategory: string,
      params: RequestParams = {}
    ) =>
      this.request<OtherCorrectionItem, any>({
        path: `/reports/${reportId}/assessments/${assessmentCategory}/other_correction_items/${otherCorrectionItemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags otherCorrectionItems
     * @name OtherCorrectionItemsUpdate
     * @summary その他補正の調整項目更新
     * @request PUT:/reports/{report_id}/assessments/{assessment_category}/other_correction_items/{other_correction_item_id}
     * @secure
     */
    otherCorrectionItemsUpdate: (
      reportId: number,
      assessmentCategory: AssessmentCategory,
      otherCorrectionItemId: number,
      data: OtherCorrectionItem,
      params: RequestParams = {}
    ) =>
      this.request<OtherCorrectionItem, any>({
        path: `/reports/${reportId}/assessments/${assessmentCategory}/other_correction_items/${otherCorrectionItemId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags otherCorrectionItems
     * @name OtherCorrectionItemsDelete
     * @summary その他補正の調整項目削除
     * @request DELETE:/reports/{report_id}/assessments/{assessment_category}/other_correction_items/{other_correction_item_id}
     * @secure
     */
    otherCorrectionItemsDelete: (
      reportId: number,
      assessmentCategory: AssessmentCategory,
      otherCorrectionItemId: number,
      params: RequestParams = {}
    ) =>
      this.request<OtherCorrectionItem, any>({
        path: `/reports/${reportId}/assessments/${assessmentCategory}/other_correction_items/${otherCorrectionItemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  offerPriceItems = {
    /**
     * No description
     *
     * @tags offerPriceItems
     * @name OfferPriceItemCreate
     * @summary 売出提案価格を調整するための項目を追加します
     * @request POST:/reports/{report_id}/offer_price_items
     * @secure
     */
    offerPriceItemCreate: (
      reportId: string,
      data: OfferPriceItem,
      params: RequestParams = {}
    ) =>
      this.request<
        SpeedModeLandReport | SpeedModeHouseReport | SpeedModeCondoReport,
        Error
      >({
        path: `/reports/${reportId}/offer_price_items`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offerPriceItems
     * @name OfferPriceItemsUpdate
     * @summary 売出提案価格更新
     * @request PUT:/reports/{report_id}/offer_price_items/{offer_price_item_id}
     * @secure
     */
    offerPriceItemsUpdate: (
      reportId: number,
      offerPriceItemId: number,
      data: OfferPriceItem,
      params: RequestParams = {}
    ) =>
      this.request<OfferPriceItem, any>({
        path: `/reports/${reportId}/offer_price_items/${offerPriceItemId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offerPriceItems
     * @name OfferPriceItemsDelete
     * @summary 売出提案価格削除
     * @request DELETE:/reports/{report_id}/offer_price_items/{offer_price_item_id}
     * @secure
     */
    offerPriceItemsDelete: (
      reportId: number,
      offerPriceItemId: number,
      params: RequestParams = {}
    ) =>
      this.request<OfferPriceItem, any>({
        path: `/reports/${reportId}/offer_price_items/${offerPriceItemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  areaInformationReports = {
    /**
     * No description
     *
     * @tags areaInformationReports
     * @name AreaInformationReportCreate
     * @summary エリアレポートの作成
     * @request POST:/area_information_reports
     * @secure
     */
    areaInformationReportCreate: (
      data: {
        id: number
        company_id: number
        created_at: string
        updated_at: string
        created_year: number
        created_month: number
        initialized: boolean
        printable: boolean
        print_setting: PrintSetting
        company: Company
      },
      params: RequestParams = {}
    ) =>
      this.request<any, Error>({
        path: `/area_information_reports`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  form = {
    /**
     * No description
     *
     * @tags form
     * @name FormOfficesList
     * @summary 店舗一覧
     * @request GET:/form/offices
     * @secure
     */
    formOfficesList: (params: RequestParams = {}) =>
      this.request<{ id?: number; name?: string }[], any>({
        path: `/form/offices`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags form
     * @name FormUsersList
     * @summary 担当者一覧
     * @request GET:/form/users
     * @secure
     */
    formUsersList: (
      query?: { office_id?: number },
      params: RequestParams = {}
    ) =>
      this.request<{ id?: number; name?: string }[], any>({
        path: `/form/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  hazardInformation = {
    /**
     * No description
     *
     * @tags hazardInformation
     * @name HazardInformationDetail
     * @summary ハザード情報返却API
     * @request GET:/reports/{report_id}/hazard_information
     * @secure
     */
    hazardInformationDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<
        {
          basic_information: BasicInformation[]
          risk_information: RiskInformation[]
        },
        any
      >({
        path: `/reports/${reportId}/hazard_information`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  announcements = {
    /**
     * No description
     *
     * @tags announcements
     * @name AnnouncementsList
     * @summary お知らせ一覧
     * @request GET:/announcements
     * @secure
     */
    announcementsList: (
      query?: { page?: number },
      params: RequestParams = {}
    ) =>
      this.request<
        { announcements: Announcement[]; total_pages?: number },
        any
      >({
        path: `/announcements`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  howma = {
    /**
 * No description
 * 
 * @tags howma
 * @name HowmaBaRequestsList
 * @summary HowMaの一括査定リクエストの一覧です。

HowMa提供のGraphQLのエンドポイントに接続してレスポンスを返却します。
 * @request GET:/howma/ba_requests
 * @secure
 */
    howmaBaRequestsList: (
      query?: { assigned?: string },
      params: RequestParams = {}
    ) =>
      this.request<{ ba_requests: HowmaBaRequest[]; total_count: number }, any>(
        {
          path: `/howma/ba_requests`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        }
      ),

    /**
 * No description
 * 
 * @tags howma
 * @name HowmaBaRequestDetail
 * @summary HowMaの一括査定リクエストの詳細情報を取得します。

HowMa提供のGraphQLのエンドポイントに接続してレスポンスを返却します。
 * @request GET:/howma/ba_requests/{ba_request_id}
 * @secure
 */
    howmaBaRequestDetail: (baRequestId?: string, params: RequestParams = {}) =>
      this.request<HowmaBaRequest, any>({
        path: `/howma/ba_requests/${baRequestId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description HowMaの認証用トークンを取得します。 このトークンを下記のように利用してアクセスすると、HowMaに認証された状態でアクセスできます。 https://www.how-ma.com/business/ba_requests?ml_token=****
     *
     * @tags howma
     * @name HowmaMlTokenCreate
     * @summary HowMaの認証用トークン取得
     * @request POST:/howma/ml_token
     * @secure
     */
    howmaMlTokenCreate: (params: RequestParams = {}) =>
      this.request<{ ml_token: string }, any>({
        path: `/howma/ml_token`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  jobDispatcher = {
    /**
     * @description 非同期処理が行われてデータベースに保存されてるかを確認します。
     *
     * @tags jobDispatcher
     * @name JobDispatcherIndex
     * @summary 非同期実行確認
     * @request GET:/job_dispatcher
     * @secure
     */
    jobDispatcherIndex: (
      query: { report_id: number },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          spot_saved: boolean
          area_disaster_prevention_information_saved: boolean
          transport_link_saved: boolean
          transport_node_saved: boolean
        },
        any
      >({
        path: `/job_dispatcher`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description POSTするとリクエストに応じて周辺施設かハザード情報の非同期処理が実行されます。
     *
     * @tags jobDispatcher
     * @name JobDispatcherCreate
     * @summary 各種データ作成
     * @request POST:/job_dispatcher
     * @secure
     */
    jobDispatcherCreate: (
      data: { report_id: number; category: JobDispatcherCategory },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/job_dispatcher`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  geocodeLocation = {
    /**
     * @description 住所をパラメータに緯度経度を返却します。 ジオコーディング処理はGoogleのAPIを使っています。 ジオコーディング結果はDBに保存して再利用しています。
     *
     * @tags geocodeLocation
     * @name GeocodeLocation
     * @summary 位置情報取得
     * @request GET:/geocode
     * @secure
     */
    geocodeLocation: (
      query?: { address?: string },
      params: RequestParams = {}
    ) =>
      this.request<{ location: { lat: number; lng: number } }, any>({
        path: `/geocode`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  userReportTitleDefaultSetting = {
    /**
     * @description 指定した担当者のデフォルトタイトルの設定を取得します。
     *
     * @tags userReportTitleDefaultSetting
     * @name UserReportTitleDefaultSettingDetail
     * @summary 担当者デフォルトタイトル設定取得
     * @request GET:/users/{user_id}/user_report_title_default_setting
     * @secure
     */
    userReportTitleDefaultSettingDetail: (
      userId: number,
      params: RequestParams = {}
    ) =>
      this.request<UserReportTitleDefaultSetting, any>({
        path: `/users/${userId}/user_report_title_default_setting`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した担当者のデフォルトタイトルの設定を保存します。
     *
     * @tags userReportTitleDefaultSetting
     * @name UserReportTitleDefaultSettingCreate
     * @summary 担当者デフォルトタイトル設定作成
     * @request PATCH:/users/{user_id}/user_report_title_default_setting
     * @secure
     */
    userReportTitleDefaultSettingCreate: (
      userId: number,
      data: UserReportTitleDefaultSetting,
      params: RequestParams = {}
    ) =>
      this.request<UserReportTitleDefaultSetting, any>({
        path: `/users/${userId}/user_report_title_default_setting`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  userOfferCommentDefaultSetting = {
    /**
     * @description 指定した担当者のデフォルトタイトルの設定を取得します。
     *
     * @tags UserOfferCommentDefaultSetting
     * @name UserOfferCommentDefaultSettingDetail
     * @summary 担当者デフォルトタイトル設定取得
     * @request GET:/users/{user_id}/user_offer_comment_default_setting
     * @secure
     */
    userOfferCommentDefaultSettingDetail: (
      userId: number,
      params: RequestParams = {}
    ) =>
      this.request<UserOfferCommentDefaultSetting, any>({
        path: `/users/${userId}/user_offer_comment_default_setting`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した担当者のデフォルトタイトルの設定を保存します。
     *
     * @tags UserOfferCommentDefaultSetting
     * @name UserOfferCommentDefaultSettingCreate
     * @summary 担当者デフォルトタイトル設定作成
     * @request PATCH:/users/{user_id}/user_offer_comment_default_setting
     * @secure
     */
    userOfferCommentDefaultSettingCreate: (
      userId: number,
      data: UserOfferCommentDefaultSetting,
      params: RequestParams = {}
    ) =>
      this.request<UserOfferCommentDefaultSetting, any>({
        path: `/users/${userId}/user_offer_comment_default_setting`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  userReportDesignDefaultSetting = {
    /**
     * @description 指定した担当者の表紙デザインデフォルト設定を更新します。
     *
     * @tags UserReportDesignDefaultSetting
     * @name UserReportDesignDefaultSettingUpdate
     * @summary 担当者表紙デザインデフォルト設定
     * @request PATCH:/users/{user_id}/user_report_design_default_setting
     * @secure
     */
    userReportDesignDefaultSettingUpdate: (
      userId: number,
      data: { default_design_type?: string; default_color?: string },
      params: RequestParams = {}
    ) =>
      this.request<User, any>({
        path: `/users/${userId}/user_report_design_default_setting`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  userExitSimulationDefaultSetting = {
    /**
     * @description 指定した担当者のデフォルト諸費用の設定を取得します。
     *
     * @tags UserExitSimulationDefaultSetting
     * @name UserExitSimulationDefaultSettingDetail
     * @summary 担当者デフォルト諸費用設定取得
     * @request GET:/users/{user_id}/user_exit_simulation_default_setting
     * @secure
     */
    userExitSimulationDefaultSettingDetail: (
      userId: number,
      params: RequestParams = {}
    ) =>
      this.request<UserExitSimulationDefaultSetting, any>({
        path: `/users/${userId}/user_exit_simulation_default_setting`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した担当者のデフォルト諸費用の設定を保存します。
     *
     * @tags UserExitSimulationDefaultSetting
     * @name UserExitSimulationDefaultSettingCreate
     * @summary 担当者デフォルト諸費用設定作成
     * @request PATCH:/users/{user_id}/user_exit_simulation_default_setting
     * @secure
     */
    userExitSimulationDefaultSettingCreate: (
      userId: number,
      data: UserExitSimulationDefaultSetting,
      params: RequestParams = {}
    ) =>
      this.request<UserExitSimulationDefaultSetting, any>({
        path: `/users/${userId}/user_exit_simulation_default_setting`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  userOfferPriceItemDefaultSettings = {
    /**
     * No description
     *
     * @tags userOfferPriceItemDefaultSettings
     * @name UserOfferPriceItemDefaultSettingsUpdate
     * @summary ユーザー売出提案価格デフォルト設定更新
     * @request PUT:/users/{user_id}/user_offer_price_item_default_settings/{user_offer_price_item_default_setting_id}
     * @secure
     */
    userOfferPriceItemDefaultSettingsUpdate: (
      userId: number,
      userOfferPriceItemDefaultSettingId: number,
      data: UserOfferPriceItemDefaultSetting,
      params: RequestParams = {}
    ) =>
      this.request<UserOfferPriceItemDefaultSetting, any>({
        path: `/users/${userId}/user_offer_price_item_default_settings/${userOfferPriceItemDefaultSettingId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags userOfferPriceItemDefaultSettings
     * @name UserOfferPriceItemDefaultSettingsDelete
     * @summary ユーザー売出提案価格デフォルト設定削除
     * @request DELETE:/users/{user_id}/user_offer_price_item_default_settings/{user_offer_price_item_default_setting_id}
     * @secure
     */
    userOfferPriceItemDefaultSettingsDelete: (
      userId: number,
      userOfferPriceItemDefaultSettingId: number,
      params: RequestParams = {}
    ) =>
      this.request<UserOfferPriceItemDefaultSetting, any>({
        path: `/users/${userId}/user_offer_price_item_default_settings/${userOfferPriceItemDefaultSettingId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags userOfferPriceItemDefaultSettings
     * @name UserOfferPriceItemDefaultSettingsList
     * @summary ユーザーの売出提案価格デフォルト設定を一覧で取得します
     * @request GET:/users/{user_id}/user_offer_price_item_default_settings
     * @secure
     */
    userOfferPriceItemDefaultSettingsList: (
      userId: number,
      params: RequestParams = {}
    ) =>
      this.request<UserOfferPriceItemDefaultSetting[], Error>({
        path: `/users/${userId}/user_offer_price_item_default_settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags userOfferPriceItemDefaultSettings
     * @name UserOfferPriceItemDefaultSettingsCreate
     * @summary ユーザーの売出提案価格デフォルト設定を追加します
     * @request POST:/users/{user_id}/user_offer_price_item_default_settings
     * @secure
     */
    userOfferPriceItemDefaultSettingsCreate: (
      userId: number,
      data: UserOfferPriceItemDefaultSetting,
      params: RequestParams = {}
    ) =>
      this.request<UserOfferPriceItemDefaultSetting, Error>({
        path: `/users/${userId}/user_offer_price_item_default_settings`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  taxBaseLandValues = {
    /**
     * No description
     *
     * @tags taxBaseLandValues
     * @name TaxBaseLandValuesCreate
     * @summary 路線価作成
     * @request POST:/assessments/{assessment_id}/tax_base_land_values
     * @secure
     */
    taxBaseLandValuesCreate: (
      assessmentId: number,
      data: TaxBaseLandValue,
      params: RequestParams = {}
    ) =>
      this.request<TaxBaseLandValue, any>({
        path: `/assessments/${assessmentId}/tax_base_land_values`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags taxBaseLandValues
     * @name TaxBaseLandValuesUpdate
     * @summary 路線価更新
     * @request PUT:/assessments/{assessment_id}/tax_base_land_values/{tax_base_land_value_id}
     * @secure
     */
    taxBaseLandValuesUpdate: (
      assessmentId: number,
      id: number,
      taxBaseLandValueId: string,
      data: TaxBaseLandValue,
      params: RequestParams = {}
    ) =>
      this.request<TaxBaseLandValue, any>({
        path: `/assessments/${assessmentId}/tax_base_land_values/${taxBaseLandValueId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
